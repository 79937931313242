import {mfetch} from '../connect';
import {clients, reducers} from '../types';
import {Loading} from './admin';

/*=============BASIC===============*/
export function ChangeMode(mode){
  return {
    type:reducers.EVENT_CHANGE_MODE,
    mode
  }
}

export function Reset(){
  return {
    type:reducers.EVENT_RESET
  }
}

/*=============EVENT CRUD===============*/
export function ReadEvent(event){
  return {
    type:reducers.EVENT_READ,
    event
  }
}


function FinishEventUpdate(event){
  return {
    type:reducers.EVENT_UPDATE,
    event
  }
}

export function UpdateEvent(event){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"update", key:"event", data:event}})
    dispatch(FinishEventUpdate(event));
    dispatch(ChangeMode(1));
    dispatch(Loading(false));
  }
}

export function ReadAllEventData(id){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"event_read_all", data:{events_id:id, eid:id, limit:null}}});
    dispatch(FinishReadAllEventData(json));
    dispatch(Loading(false));
  }
}

function FinishReadAllEventData(all){
  return {
    type:reducers.EVENT_READ_ALL,
    all
  }
}
/*=============SURVEY===============*/
function FinishSurveyInsert(survey){
  return {
    type:reducers.EVENT_SURVEY_INSERT,
    load:false,
    survey
  }
}

export function InsertSurvey(survey){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"create", key:"survey", data:survey}}); 
    let obj = {
      id:json.data[0].id,
      title:survey.title,
      description:survey.description
    }
    dispatch(Loading(false));
  }
}

export function FinishSurveyRead(surveys){
  return {
    type:reducers.EVENT_SURVEYS_READ,
    surveys
  }
}

export function ReadSurveys(events_id){
  return async (dispatch)=>{
    dispatch(Loading(false));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"surveys", data:{events_id:events_id}}});
    dispatch(FinishSurveyRead(json.data));
    dispatch(Loading(true));
  }
}

export function UpdateSurveys(surveys){
  return {
    type:reducers.EVENT_UPDATE_SURVEYS,
    surveys
  }
}

/*=============PRIZE===============*/
function FinishPrizeInsert(prize){
  return {
    type:reducers.EVENT_PRIZE_INSERT,
    prize
  }
}

export function InsertPrize(prize){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"create", key:"prizes", data:prize}});
    let obj = {
      id:json.data[0].id,
      name:prize.name,
      pts:prize.pts
    }
    dispatch(FinishPrizeInsert(obj));
    dispatch(Loading(false));
  }
}

export function FinishPrizeRead(prizes){
  return {
    type:reducers.EVENT_PRIZES_READ,
    prizes
  }
}

export function ReadPrizes(events_id){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"prize",data:{events_id:events_id}}});
    dispatch(FinishPrizeRead(json.data));
    dispatch(Loading(false));
  }
}

export function UpdatePrizes(prizes){
  return {
    type:reducers.EVENT_PRIZES_UPDATE,
    load:false,
    prizes
  }
}
/*=============USERS===============*/
export function FinishUsersRead(users){
  return {
    type:reducers.EVENT_USERS_READ,
    users
  }
}

export function ReadUsers(events_id, limit, noload){
  limit = (limit)?limit:null;
  return async (dispatch)=>{
    if(!noload){(Loading(true))};
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"users_events", data:{eid:events_id, limit:limit}}});
    if(json){
      dispatch(FinishUsersRead(json.data));
    }
    if(!noload){dispatch(Loading(false))};
  }
}

/*=============PRIZE USERS (WINNER)===============*/
export function PrizeUser(pid, uid){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"create", key:"prizes_users", data:{prizes_id:pid, users_id:uid}}});
    dispatch(Loading(false))
  }
}

/*===============SIGNINS=======================*/


function FinishReadSignins(signins){
  return {
    type:reducers.EVENT_SIGNINS_READ,
    signins
  }
}

export function ReadSignins(eid, limit){
  limit = (limit)?limit:null;
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"users_signin", data:{eid:eid, limit:limit}}})
    //console.log(json);
    dispatch(FinishReadSignins(json.data));
    dispatch(Loading(false));
  }
}

/*===============PRIZESUSERS=======================*/
export function FinishReadPrizesUsers(redeems){
  return {
    type:reducers.EVENT_PRIZESUSERS_READ,
    redeems
  }
}

export function ReadPrizesUsers(eid, limit){
  limit = (limit)?limit:null;
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"prizes_users", data:{events_id:eid, limit:limit}}});
    dispatch(FinishReadPrizesUsers(json.data));
    dispatch(Loading(false));
  }
}

export function UpdatePrizesUsers(prizeuser, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"update", key:"prizes_users",data:prizeuser}});
    if(typeof callback === 'function'){
      callback(json);
    }
    //dispatch(ReadPrizesUsers(prizeuser.));
    dispatch(Loading(false));
  }
}

/*===============MESSAGES=======================*/
export function FinishReadMessages(messages){
  return {
    type:reducers.EVENT_MESSAGES_READ,
    messages
  }
}

export function FinishReadFeedbacks(sends, receives){
  return {
    type:reducers.EVENT_FEEDBACKS_READ,
    sends,
    receives
  }
}

export function ReadMessages(eid, type){
  type = (type) ? type : 0;
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"sms_send", data:{events_id:eid, type:type}}});
    dispatch(FinishReadMessages(json.data));
    dispatch(Loading(false));
  }
}

export function ReadReceives(id, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"sms_receive", data:{sms_send_id:id}}});
    //console.log(json);
    if(typeof callback === 'function'){
      callback(json.data);
    }
    dispatch(Loading(false));
  }
}

export function ReadReceivesEvents(data, callback){
  var obj = {};
  if(data && typeof data === "object"){
    obj = data;
  } else {
    obj = {events_id:data};
  }
  return async (dispatch)=>{
    if(data.load){dispatch(Loading(true));}
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"sms_receive", data:obj}});
      //console.log(json);
    if(!json.status){
      return false;
    }

    if(json.data.send && json.data.receive){
      dispatch(FinishReadFeedbacks(json.data.send.data, json.data.receive.data));
    } else {
      dispatch(FinishReadFeedbacks([], json.data));
    }

    if(typeof callback === 'function'){
      callback(json.data);
    }
    dispatch(Loading(false));
  }
}

export function ReadReceivesEvents2(data, callback){
  var obj = {};
  if(data && typeof data === "object"){
    obj = data;
  } else {
    obj = {events_id:data};
  }
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"sms_receive", data:data}});
    //console.log(json);
    dispatch(Loading(false));
    if(!json.status){
      return false;
    }

    dispatch(FinishReadFeedbacks(json.data.send.data, json.data.receive.data));
    if(typeof callback === 'function'){
      callback(json.data);
    }
  }
}
/*=================================MESSAGES END==================================*/

/*=================================TEMPLATES==================================*/  
export function InsertTemplates(template){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"create", key:"sms_templates",data:template}});
    //console.log(json);
    dispatch(Loading(false));
    if(!json.status){
      return false;
    }

    //dispatch(FinishTemplatesInsert(json.data));
    dispatch(ReadTemplates(template.events_id));
  }
}

export function ReadTemplates(id, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"sms_templates", data:{events_id:id}}});
    //console.log(json);
    dispatch(Loading(false));
    if(!json.status){
      return false;
    }

    dispatch(FinishReadTemplates(json.data));
    if(typeof callback === 'function'){
      callback(json.data);
    }
  }
}

export function UpdateTemplates(template, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"update", key:"sms_templates", data:template}});
    dispatch(Loading(false));
    //console.log(json);
    if(!json.status){
      return false;
    }
    if(typeof callback === 'function'){
      callback(json.data);
    }
  }
}

export function DeleteTemplates(template, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"delete", key:"sms_templates", data:template}});
    //console.log(json);
    dispatch(Loading(false));
    if(!json.status){
      return false;
    }
    if(typeof callback === 'function'){
      callback(json.data);
    }
  }
}

function FinishReadTemplates(templates){
  return {
    type:reducers.EVENT_TEMPLATES_READ,
    templates,
  }
}
/*=================================TEMPLATES END==================================*/  


/*===============EVENTS PROMO=======================*/
export function InsertEventsPromo(promo, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"create", key:"events_promo", data:promo}});
    //console.log(json);
    dispatch(Loading(false));  
    if(typeof callback === "function"){
      callback(json.status);
    }

    if(!json.status){
      return false;
    }
    dispatch(ReadEventsPromo(promo.events_id));
  }
}

export function ReadEventsPromo(eid, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"events_promo", data:{events_id:eid}}});
    //console.log(json);
    dispatch(FinishReadEventsPromo(json.data));
    dispatch(Loading(false));
    if(typeof callback === 'function'){
      callback(json.data);
    }
  }
}

export function UpdateEventsPromo(promo, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"update", key:"events_promo", data:promo}});
    //console.log(json, promo);
    if(!json.status){
      return false;
    }

    dispatch(Loading(false));
    if(typeof callback === 'function'){
      callback(json.data);
    }
  }
}

export function DeleteEventsPromo(promo, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"delete", key:"events_promo", data:promo}});
    //console.log(json);
    dispatch(Loading(false));
    if(!json.status){
      return false;
    }
    if(typeof callback === 'function'){
      callback(json.data);
    }
  }
}

export function FinishReadEventsPromo(promo){
  return {
    type:reducers.EVENT_EVENTS_PROMO_READ,
    promos:promo
  }
}

/*===============EVENTS GROUPS=======================*/
export function InsertGroup(group, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"create", key:"events_group", data:group}})
    //console.log(json);
    dispatch(Loading(false));
    if(typeof callback === "function"){
      callback(json);
    }
    if(!json.status){
      return false;
    }

    var obj = json.data;
    dispatch(ReadGroups(group.events_id));
  }
}

export function UpdateGroup(group, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"update", key:"events_group", data:group}});
    //console.log(json);
    dispatch(Loading(false));
    if(typeof callback === "function"){
      callback(json);
    }
    if(!json.status){
      return false;
    }

    var obj = json.data;
    dispatch(ReadGroups(group.events_id));
  }
}


export function ReadGroups(events_id){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"events_group", data:{events_id:events_id}}});
    //console.log("groups", json);
    dispatch(Loading(false));
    if(json && !json.status){
      return false;
    }

    var group = json.data;
    var groupusers = json.groupusers;
    //console.log(obj);
    dispatch(RefreshGroup(group, groupusers));
  }
}

export function InsertGroupUsers(gu, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"create", key:"events_group_users", data:gu}});
    //console.log(json);
    dispatch(Loading(false));
    if(typeof callback === "function"){
      callback(json);
    }
    if(!json.status){
      return false;
    }
    return json;
    //var obj = json.data;
  }
}

export function DeleteGroupUsers(gu, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"delete", key:"events_group_users", data:gu}});
    //console.log(json);
    dispatch(Loading(false));
    if(typeof callback === "function"){
      callback(json);
    }
    if(!json.status){
      return false;
    }

    var obj = json.data;
  }
}

export function RefreshGroup(groups, groupusers){
  return {
    type:reducers.EVENT_GROUPS_READ,
    groups,
    groupusers
  }
}
/*===============EVENTS GROUPS END=======================*/

/*===============EVENTS ADMIN=======================*/
export function InsertEventsAdmin(data, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"create", key:"events_admin", data:data}});
    //console.log(json);
    dispatch(Loading(false));
    if(typeof callback === "function"){
      callback(json);
    }
    if(!json.status){
      return false;
    }

    dispatch(ReadEventsAdmin(data.events_id));
  }
}

function FinishReadEventsAdmin(events_admin){
  return {
    type:reducers.EVENT_EA_READ,
    events_admin
  }
}

export function ReadEventsAdmin(events_id, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"events_admin", data:{events_id:events_id}}});
    //console.log(json);
    dispatch(Loading(false));
    if(typeof callback === "function"){
      callback(json);
    }
    
    if(!json.status){
      return false;
    }
    
    dispatch(FinishReadEventsAdmin(json.data));
  }
}

export function UpdateEventsAdmin(data, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"update", key:"events_admin", data:data}});
    //console.log(json);
    dispatch(Loading(false));
    if(typeof callback === "function"){
      callback(json);
    }
    
    if(!json.status){
      return false;
    }
    
    dispatch(ReadEventsAdmin(data.events_id));
  }
}

/*===============EVENTS ADMIN END=======================*/
