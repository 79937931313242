import React, { Component, useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {} from "../actions/admin";
import {Loader} from 'semantic-ui-react';
import Admin from './Admin';
import EventsAdmin from './EventsAdmin';
import Login from './Login';
import SMS from '../comps/SMS/SMS';

class Main extends Component {
  constructor(props){
    super(props);
  }
  
  componentDidMount=()=>{
    
  }
  
 
  render(){
    var comp = <Login />;
    switch(this.props.mode){
      case 1:
        comp = <Login />;
        break
        
      case 2:
        comp = <Admin />
        break;
        
      case 3:
        comp = <EventsAdmin />
        break;
        
    }
    
    return (
      <div>
        {(this.props.smsmode === 2) ? <SMS /> : null}
        {comp}
        {(this.props.load === true) ?
          <div className="loadScreen">
            <div className="loadIcon">
              <Loader active inverted />
            </div>
          </div>: null}
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    mode:state.Admin.mode,
    load:state.Admin.load,
    smsmode:state.SMS.mode
  };
}

export default connect(mapStateToProps)(Main);