import {mfetch} from '../connect';
import {clients, reducers} from '../types';
import {Loading} from './admin';

//export function FinishUsersRead(users){
//export function RefreshGroup(groups, groupusers){
//export function FinishReadFeedbacks(sends, receives){
//export function ReadEvent(event){
//export function FinishSurveyRead(surveys){
//export function FinishPrizeRead(prizes){
//function FinishReadEventsPromo(promo){
import {ReadEvent, FinishUsersRead, RefreshGroup, FinishReadFeedbacks, FinishSurveyRead, FinishPrizeRead, FinishReadEventsPromo} from './events';

export function ReadEventsAdmin(ea){
  return {
    type:reducers.EVENTS_ADMIN_READ,
    ea
  }
}

export function ChangeMode(mode){
  return {
    type:reducers.EVENTS_ADMIN_MODE,
    mode
  }
}

export function ReadAll(){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.EVENTS_ADMIN_PATH, data:{key:"read_all", data:{}}});
    var events = json.events.data[0]
    dispatch(ReadEvent(events));
    dispatch(FinishUsersRead(json.ue.data));
    dispatch(RefreshGroup(json.groups.data, json.groups.groupusers))
    dispatch(FinishReadFeedbacks(json.sms.data.send.data, json.sms.data.receive.data));
    dispatch(FinishSurveyRead(json.surveys.data));
    dispatch(FinishPrizeRead(json.prizes.data));
    dispatch(FinishReadEventsPromo(json.promos.data));
    //dispatch a tons here to each actions
    
    //dispatch(ChangeMode(2));
    dispatch(Loading(false));
  }
}

export function UpdateEA(ea){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.EVENTS_ADMIN_PATH, data:{key:"update_ea", data:ea}});
    if(json.status && json.data && json.data.length > 0){
      dispatch(ReadEventsAdmin(json.data[0]));
    }
    dispatch(Loading(false));
  }
}