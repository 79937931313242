import React, { Component } from 'react';
import {connect} from 'react-redux';
import {ReadPrizesUsers, UpdatePrizesUsers, FinishReadPrizesUsers} from "../../actions/events";
//import {ReadSurvey} from "../../actions/surveys";
import {Card, Icon, Button, Form, Label, Divider, Message} from 'semantic-ui-react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const RedeemCard = (props)=>{
  let obj = props.obj,
      //date = new Date(obj.time).toLocaleString("en-US", {timeZone: "Canada/Pacific"}),
      date = new Date(obj.time).toLocaleString("en-US"),
      //exp = new Date(obj.expire_time).toLocaleString("en-US", {timeZone: "Canada/Pacific"});
      exp = new Date(obj.expire_time).toLocaleString("en-US");
  
  var but = null;
  if(obj.redeemed === 0){
    but = (
      <Button compact color="teal" icon labelPosition='left' onClick={props.onClick}>
        <Icon name="check" />
        Redeem it!
      </Button>
    );
  } else if(obj.redeemed === 1) {
    but = (
      <Button compact color="red" icon labelPosition='left' onClick={props.onClick}>
        <Icon name="close" />
        Undo Redeem
      </Button>
    );
  } else if(obj.redeemed === 2) {
    but = (
      <Button disabled compact color="red" icon labelPosition='left'>
        <Icon name="trash" />
        Trashed
      </Button>
    );
  }

  return (
    <Card>
      <Card.Header>
        {
        //(obj.img && obj.img === "") ? null : <div className="preview" style={{backgroundImage:"url("+obj.img+")"}}/>
        }

        {(obj.redeemed===0) ?
          <Label attached="top right" color="yellow">
            Pending...
          </Label> : null
        }

        {(obj.redeemed===1) ?
          <Label attached="top right" color="teal">
            Redeemed!
          </Label> : null
        }

        {(obj.redeemed===2) ?
          <Label attached="top right" color="red">
            Trashed
          </Label> : null
        }

        {(obj.status===1) ?
          <Label attached="top left" color="violet">
            Won!
          </Label> : null
        }

        {(obj.status===2) ?
          <Label attached="top left" color="olive">
            Lost...
          </Label> : null
        }
      </Card.Header>
      <Card.Content>
        <Card.Description>
          <Divider hidden />
          <h3>{obj.name}</h3>
          <Label color="orange" size="small">{obj.pts} pts</Label>
          <Label color="yellow" size="small">
            {obj.hash}
          </Label>
          <Divider hidden />
          <Message info size="mini">
            <Message.Header>{obj.mobile}</Message.Header>
            <Message.List>
              <Message.Item><Icon name='gift' /> {date}</Message.Item>
              <Message.Item><Icon name='clock' /> {exp}</Message.Item>
            </Message.List>
          </Message>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        {but}
      </Card.Content>
    </Card>
  )
}

class AllRedeems extends Component {
  constructor(props){
    super(props);
    this.state = {
      sfilter:null,
      efilter:null,
      showredeems:true,
      showwons:true,
      showlosts:true,
      showpromos:true
    }
    
    if(this.props.filter && this.props.redeems.length>0){
      
      let sdate = new Date(this.props.redeems[0].time),
          edate = new Date();
      
      sdate.setHours(0,0,0);
      edate.setHours(23,59,59);
      this.state = {
        sfilter:sdate,
        efilter:edate,
        showredeems:true,
        showwons:true,
        showlosts:true,
        showpromos:true
      }
    }
  }
  
  toggleChart=(tog)=>{
    switch(tog){
      case 2:
        this.setState({
          showredeems:!this.state.showredeems
        })
        break;
      case 3:
        this.setState({
          showpromos:!this.state.showpromos
        })
        break;
      case 4:
        this.setState({
          showwons:!this.state.showwons
        })
        break;
      case 5:
        this.setState({
          showlosts:!this.state.showlosts
        })
        break;
      default:
        break;
    }
  }
  
  handleDate=(i, evt, data)=>{
    //
    var sdate = this.state.sfilter,
        edate = this.state.efilter;
    if(i===1){
      sdate = new Date(data.value);
      sdate.setTime(sdate.getTime() + sdate.getTimezoneOffset()*60*1000);
    } else {
      edate = new Date(data.value);
      edate.setTime(edate.getTime() + edate.getTimezoneOffset()*60*1000);
    }
    
    this.setState({
      sfilter:sdate,
      efilter:edate
    })
  }
  
  componentWillMount(){
    if(this.props.nofetch && this.props.nofetch === true){
      return false;
    }
    this.props.dispatch(ReadPrizesUsers(this.props.events_id));
  }
  
  redeem=(obj, i, evt)=>{
    if(obj.redeemed === 0){
      obj.redeemed = 1;
    } else {
      obj.redeemed = 0;
    }
    
    var tobj = {
      id:obj.id,
      redeemed:obj.redeemed
    }
    this.props.dispatch(UpdatePrizesUsers(tobj, (json)=>{
      
      var redeems = this.props.redeems;
      redeems[i] = obj;
      this.props.dispatch(FinishReadPrizesUsers(redeems));
    }));
  }
  view=(obj)=>{
    //this.props.dispatch(ReadSurvey(obj));
    //this.props.dispatch(ChangePage(4));
  }
  
  render() {
    //
    let arr = (this.props.redeems)?this.props.redeems:[];
    var sdate = null,
        edate = null,
        totalNum = null;
    if(this.props.filter && arr.length > 0){
      let csdate = this.state.sfilter,
          cedate = this.state.efilter;
      
      //FOR RECHART============================
      var chartData = {},
          totTime = 0,
          totType = 0,
          interval = 0,
          //dateopts = {timeZone: "Canada/Pacific"};
          dateopts = {};
      
      if(!csdate || !cedate){
        csdate = new Date(this.props.redeems[0].time);
        cedate = new Date();
        csdate.setHours(0,0,0);
        cedate.setHours(23,59,59);
      }
      
      totTime = cedate.getTime() - csdate.getTime();
      
      switch (true){
        case (totTime <= 60*60*24*1000):
          
          //separate by 24
          //dateopts = {timeZone: "Canada/Pacific", hour: '2-digit'};
          dateopts = {hour: '2-digit'};
          interval = 1;
          totType = 1;
          break;
        case (totTime <= 60*60*24*1000*14):
          
          //dateopts = {timeZone: "Canada/Pacific", month:"short", day:"2-digit"};
          dateopts = {month:"short", day:"2-digit"};
          //separate by 14
          totType = 2;
          break;
        case (totTime <= 60*60*24*1000*31):
          
          //separate by 31
          //dateopts = {timeZone: "Canada/Pacific", month:"short", day:"2-digit"};
          dateopts = {month:"short", day:"2-digit"};
          interval = 1;
          totType = 2;
          break;
        case (totTime <= 60*60*24*1000*31*3):
          
          //separate by 93
          //dateopts = {timeZone: "Canada/Pacific", month:"short", day:"2-digit"};
          dateopts = {month:"short", day:"2-digit"};
          interval = 4;
          totType = 2;
          break;
        case (totTime <= 60*60*24*1000*31*12):
          
          //dateopts = {timeZone: "Canada/Pacific", year:"numeric", month:"short"};
          dateopts = {year:"numeric", month:"short"};
          totType = 3;
          break;
          
        default:
          break;
      }
      
      var tDate = new Date(csdate),
          tInd = 0,
          totalNum = {
            redeems:0,
            promos:0,
            wons:0,
            losts:0
          };
      while (tDate < cedate){
        chartData[tDate.toLocaleString("en-US", dateopts)] = {
          redeems:0,
          promos:0,
          wons:0,
          losts:0
        };
        //chartData[tDate.toLocaleString("en-US", dateopts)] = 0;
        switch(totType){
          case 1:
            tDate.setHours(tDate.getHours()+1);
            break;
          case 2:
            tDate.setDate(tDate.getDate()+1);
            break;
          case 3:
            tDate.setMonth(tDate.getMonth()+1);
            break;
          default:
            break;
        }
        tInd++;
        if(tInd > 10000){
          break;
        }
      }
      
      arr = arr.filter((obj)=>{
        var date = new Date(obj.time);
        var tempdate = date.toLocaleString("en-US", dateopts);
        if((date >= csdate && date <= cedate) && (chartData[tempdate] && (chartData[tempdate].redeems || chartData[tempdate].redeems === 0))){
          //chartData[tempdate]++;
          if(obj.type === 2){
            chartData[tempdate].promos++;
            totalNum.promos++;
          } else if(obj.status === 2 && obj.redeemed === 1){
            chartData[tempdate].losts++;
            totalNum.losts++;
          } else if(obj.status === 1 && (obj.redeemed === 0 || obj.redeemed === 1)){
            chartData[tempdate].wons++;
            totalNum.wons++;
          } else {
            chartData[tempdate].redeems++;
            totalNum.redeems++;
          }
        }
        return (date >= csdate && date <= cedate);
      });
      
      var chartArr = [],
          cheight = 5,
          lengthobj;
    
      for(var i in chartData){
        var tobj = chartData[i];
        var obj = {
          name:i,
          redeems:tobj.redeems,
          promos:tobj.promos,
          wons:tobj.wons,
          losts:tobj.losts
        };
        
        if(obj.redeems > cheight){
          cheight = obj.redeems;
        }
        
        if(obj.wons > cheight){
          cheight = obj.wons;
        }
        
        if(obj.losts > cheight){
          cheight = obj.losts;
        }
        
        if(obj.promos > cheight){
          cheight = obj.promos;
        }

        chartArr.push(obj);
      };
      
      //FOR FILTER INPUT======================
      //sdate = csdate.toLocaleDateString("en-US", {timeZone: "Canada/Pacific", year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
      sdate = csdate.toLocaleDateString("en-US", {year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
      //edate = cedate.toLocaleDateString("en-US", {timeZone: "Canada/Pacific", year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
      edate = cedate.toLocaleDateString("en-US", {year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
      
      //sdate = new Date(sdate).toString("yyyy-MM-dd");
      sdate = sdate[2]+"-"+sdate[0]+"-"+sdate[1];
      edate = edate[2]+"-"+edate[0]+"-"+edate[1];
      //
    }
    
    var narr = [];
    for (var i = 0; i<arr.length; i++){
      var obj = arr[i],
          cond = {
            ispromo:(obj.type === 2),
            islost:(obj.status === 2 && obj.redeemed === 1),
            iswon:(obj.status === 1 && (obj.redeemed === 0 || obj.redeemed === 1)),
            isredeem:(obj.type < 2 && obj.status === 0)
          };
      //console.log(obj, cond);
      if(!this.state.showpromos && cond.ispromo){
        continue;
      }
      
      if(!this.state.showlosts && cond.islost){
        continue;
      }
      
      if(!this.state.showwons && cond.iswon){
        continue;
      } 
      
      if(!this.state.showredeems && cond.isredeem){
        continue;
      }
      
      narr.push(obj);
    }
    
    arr = narr;
    if(this.props.limit){
      arr = narr.slice(0, this.props.limit);
    }
    
    let AllRedeems = arr.map((obj,i)=>{
      return (
        <RedeemCard key={i} obj={obj} onClick={this.redeem.bind(this, obj, i)} />
      )
    });
    
    return (
      <div>
        {(sdate && edate) ?
          <Form>
            <Form.Group inline>
              <Label size="huge" pointing="right" color="teal">
                <Icon name='gift' /> {arr.length}
              </Label>
              <Form.Input type="date" label="From" max={edate} onChange={this.handleDate.bind(this, 1)} value={sdate} />
              <Form.Input type="date" label="To" min={sdate} onChange={this.handleDate.bind(this, 2)} value={edate} />
            </Form.Group>
          </Form>
          : null
        }
        {(sdate && edate) ?
          <ResponsiveContainer width='100%' aspect={4.0/2.0}>
            <AreaChart data={chartArr}>
              {(this.state.showredeems) ? <Area type='monotone' dataKey='redeems' stackId="2" stroke='#00B5AD' fill='#00B5AD' /> : null}
              {(this.state.showpromos) ? <Area type='monotone' dataKey='promos' stackId="3" stroke='#FBBD08' fill='#FBBD08' /> : null}
              {(this.state.showwons) ? <Area type='monotone' dataKey='wons' stackId="4" stroke='#6435c9' fill='#6435c9' /> : null}
              {(this.state.showlosts) ? <Area type='monotone' dataKey='losts' stackId="5" stroke='#b5cc18' fill='#b5cc18' /> : null}
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" interval={interval}/>
              <YAxis type="number" domain={[0, cheight]} interval={0} />
              <Tooltip />
            </AreaChart>
          </ResponsiveContainer>
          : null
        }
        <Divider hidden />
        <Divider hidden />
        <Label color={this.state.showredeems ? "teal" : "grey"} size="medium" onClick={this.toggleChart.bind(this,2)}>
          <Icon name='gift' /> {(totalNum) ? totalNum.redeems : null}
        </Label>
        <Label color={this.state.showpromos ? "yellow" : "grey"} size="medium" onClick={this.toggleChart.bind(this,3)}>
          <Icon name='gift' /> {(totalNum) ? totalNum.promos: null}
        </Label>
        <Label color={this.state.showwons ? "purple" : "grey"} size="medium" onClick={this.toggleChart.bind(this,4)}>
          <Icon name='gift' /> {(totalNum) ? totalNum.wons : null}
        </Label>
        <Label color={this.state.showlosts ? "olive" : "grey"} size="medium" onClick={this.toggleChart.bind(this,5)}>
          <Icon name='gift' /> {(totalNum) ? totalNum.losts : null}
        </Label>
        <Divider hidden/>
        <Card.Group itemsPerRow={4} stackable>{AllRedeems}</Card.Group>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    redeems: state.Events.redeems,
    events_id: state.Events.id
  };
}

export default connect(mapStateToProps)(AllRedeems);