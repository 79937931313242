import React, { Component } from 'react';
import {connect} from 'react-redux';
import {ReadUsers, InsertGroup, ReadGroups, UpdateGroup, InsertGroupUsers, DeleteGroupUsers} from "../../actions/events";
import {UpdateUsersEvents, InsertUser, InsertUsersEvents} from "../../actions/customers";
//import {ReadSurvey} from "../../actions/surveys";
import {ChangeSMSMode} from "../../actions/sms";
import {Feed, Icon, Button, Form, Divider, Label, Popup, Checkbox} from 'semantic-ui-react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import {exportCSVFile, months, days, csvJSON} from '../../func/csv';

const NEW_USER = 1,
      NEW_GROUP = 2;

const UsersCard = (props)=>{
  let obj = props.obj,
      //date = new Date(obj.time).toLocaleString("en-US", {timeZone: "Canada/Pacific"}),
      date = new Date(obj.time).toLocaleString("en-US"),
      totalpts = (obj.totalpts)?parseInt(obj.totalpts, 10):0,
      redeempts = (obj.redeempts)?parseInt(obj.redeempts, 10):0,
      optionspts = (obj.optionspts)?parseInt(obj.optionspts, 10):0;
  
  var items = <span>{obj.mobile} - {obj.fullname}</span>;
  
  if(props.isEdit) {
    console.log(obj);
    items = (
      <Form>
        <Form.Group inline>
            <Form.Input disabled defaultValue={obj.mobile} placeholder="7781234567" />
            <Form.Input defaultValue={obj.fullname} placeholder="Bob Jones" onChange={props.handleEditObj.bind(this, "fullname")}/>
            <Form.Input defaultValue={obj.email} placeholder="bobjones@email.com" onChange={props.handleEditObj.bind(this, "email")}/>
            <Button onClick={props.handleUpdate}>Update</Button>
        </Form.Group>
        <Form.TextArea defaultValue={obj.notes} placeholder="Notes for Bob Jones" onChange={props.handleEditObj.bind(this, "notes")}/>
        <Divider hidden />
      </Form>
    );
  }
  
  var AllGroups = null;
  try {
    if(props.filter && props.groupusers.byusers[obj.id] && props.groups && props.groups.length > 0){
      var fgroups = props.groups.filter((gobj,i)=>{
        return (props.groupusers.byusers[obj.id].indexOf(gobj.id) !== -1);
      })
      AllGroups = fgroups.map((gobj, i)=>{
        return (
          <Label color="yellow" key={i} onClick={()=>{}}>
            {gobj.title}
          </Label>
        )
      });
    }
  } catch (err){
    
  }
  
  var trigger = <Feed.User>
        {(obj.optin === 1 && !props.isEdit)?<Icon name='check' color="green" />:null}
        {items}
      </Feed.User>;
  return (
    <Feed.Event>
      <Feed.Content>
        <Feed.Summary>
          {(props.filter) ? 
          <span>
            <Button icon size="tiny" color="blue" onClick={props.sms} style={{float:"right"}}>
              <Icon name='talk' />
            </Button>
            <Button inverted icon size="tiny" color="blue" onClick={props.handleOpenEdit} style={{float:"right"}}>
              <Icon name='edit' />
            </Button>
            {(props.groupedit !== null && (!props.groupusers.byusers[obj.id] || (props.groupusers.byusers[obj.id] && props.groupusers.byusers[obj.id].indexOf(props.groupedit.id) === -1))) ?
              <Button icon labelPosition="left" size="tiny" color="yellow" onClick={props.handleJoinGroup} style={{float:"right"}}>
                <Icon name='add' /> Join {props.groupedit.title}
              </Button> : null}
          </span> : null}
          <Popup trigger={trigger}>
            <Popup.Content>
              <Icon name='gift' />{totalpts} + {optionspts} - {redeempts} = {totalpts+optionspts-redeempts}
            </Popup.Content>
          </Popup>
          {(!props.isEdit) ? <Feed.Date>{date}</Feed.Date> :null}
          <div>
            {AllGroups}
          </div>
          <Divider />
        </Feed.Summary>
      </Feed.Content>
    </Feed.Event>
  )
}

class AllUsers extends Component {
  
  constructor(props){
    super(props);
    
    this.state = {
      sfilter:null,
      efilter:null,
      editid:null,
      groupedit:null,
      showgraph:false,
      showFilter:false,
      all:true,
      word:"",
      pagelimitstart:0,
      inc:25,
    }
    this.wordTimer = null;
    if(this.props.filter && this.props.users && this.props.users.length>0){
      let sdate = new Date(this.props.users[0].time),
          edate = new Date();
      
      sdate.setHours(0,0,0);
      edate.setHours(23,59,59);
      this.state = {
        sfilter:sdate,
        efilter:edate,
        editid:null,
        groupedit:null,
        showgraph:false,
        showadd:false,
        showFilter:false,
        all:true,
        word:"",
        pagelimitstart:0,
        inc:25
      }
    }
    this.resetVars();
  }
  
  resetVars=()=>{
    this.insert = {
      mobile:null,
      fullname:null,
      email:null,
      title:null,
      description:null,
      notes:null
    }
    this.edit = {
      fullname:null,
      email:null,
      users_events_id:null,
      group_id:null,
      notes:null
    }
  }
  
  componentWillMount(){
    if(this.props.nofetch && this.props.nofetch === true){
      return false;
    }
    this.props.dispatch(ReadUsers(this.props.events_id));
    if(this.props.filter){
      this.props.dispatch(ReadGroups(this.props.events_id));
    }
  }
  
  handleOpenEdit=(obj)=>{
    if(obj && this.state.editid !== obj.id){
      this.edit.fullname = obj.fullname;
      this.edit.email = obj.email;
      this.setState({
        editid:obj.id
      });
    } else {
      this.setState({
        editid:null
      });
    }
  }
  
  handleEditObj=(prop, ev, data)=>{
    this.edit[prop] = data.value;
  }
  
  handleUpdate=(type)=>{
    //console.log(this.edit);
    
    if(type === NEW_USER){
      this.props.dispatch(UpdateUsersEvents({
        id:this.state.editid,
        fullname:this.edit.fullname,
        email:this.edit.email,
        notes:this.edit.notes
      }, ()=>{
        this.resetVars();
        this.setState({
          editid:null
        });
        this.props.dispatch(ReadUsers(this.props.events_id));
      }))
    }
    if(type === NEW_GROUP){
      this.props.dispatch(UpdateGroup(this.state.groupedit));
    }
  }
  
  handleInsertObj=(prop, ev, data)=>{
    this.insert[prop] = data.value;
  }
  
  //insert a new user or group
  handleInsert=async (type)=>{
    
    if(type === NEW_USER){
      var udata = await this.props.dispatch(InsertUser(this.insert.mobile));
      await this.props.dispatch(InsertUsersEvents({
          eid:this.props.events_id,
          uid:udata.id,
          fullname:this.insert.fullname,
          email:this.insert.email,
          notes:this.insert.notes
      }));
      this.resetVars();
      this.setState({
        showadd:false
      });
      await this.props.dispatch(ReadUsers(this.props.events_id));
    }
    
    if(type === NEW_GROUP){
      this.props.dispatch(InsertGroup({
        events_id:this.props.events_id,
        title:this.insert.title,
        description:this.insert.description
      }, (data)=>{
        this.resetVars();
        this.setState({
          showadd:false
        });
      }))
    }
  }
  
  handleJoinGroup=async (obj)=>{
    //console.log(obj);
    var data = await this.props.dispatch(InsertGroupUsers({
      users_events_id:obj.id,
      events_group_id:this.state.groupedit.id
    }));
    await this.props.dispatch(ReadGroups(this.props.events_id));
  }
  
  handleJoinGroupAll=async (arr)=>{
    //console.log(obj);
    if(arr.length>0){
      for(var i = 0; i<arr.length; i++){
        var obj = arr[i];
        if(!this.props.groupusers.byusers[obj.id] || (this.props.groupusers.byusers[obj.id] && this.props.groupusers.byusers[obj.id].indexOf(this.state.groupedit.id) === -1)){
          //console.log("join");
          await this.handleJoinGroup(obj);
        } else {
          console.log("already in group");
        }
      }
    }
  }
  
  handleRemoveGroupUser=(euid, gid)=>{
    this.props.dispatch(DeleteGroupUsers({
      users_events_id:euid,
      events_group_id:gid
    },(data)=>{
      this.props.dispatch(ReadGroups(this.props.events_id));
    }))
  }
  
  handleDate=(i, evt, data)=>{
    
    var sdate = this.state.sfilter,
        edate = this.state.efilter;
    if(i===1){
      sdate = new Date(data.value);
      sdate.setTime(sdate.getTime() + sdate.getTimezoneOffset()*60*1000);
    } else {
      edate = new Date(data.value);
      edate.setTime(edate.getTime() + edate.getTimezoneOffset()*60*1000);
    }
    
    this.setState({
      sfilter:sdate,
      efilter:edate,
      all:false
    })
  }

  startSMS=(obj)=>{
    //
    this.props.dispatch(ChangeSMSMode({
      mode:2,
      type:4,
      users_id:[obj.id],
      mobiles:[obj.mobile],
      blasts:0,
      events_id:this.props.events_id,
      from:this.props.mobile
    }));
  }
  
  view=(obj)=>{
    //this.props.dispatch(ReadSurvey(obj));
    //this.props.dispatch(ChangePage(4));
  }
  
  exportCSV=(arr)=>{
    var headers = {
      number: 'Phone Numbers'.replace(/,/g, ''), // remove commas to avoid errors
      fullname: "Full Name",
      email: "Email",
      time: "Joined Time",
      year: "Year",
      month: "Month",
      date: "Date",
      day: "Day",
      hours: "Hours",
      minutes: "Minutes",
      seconds: "Seconds"
    };
    
    //console.log(arr);
    var items = arr.map((obj,i)=>{
      var dobj = new Date(obj.time),
          time = dobj.toLocaleString("en-US").replace(/,/g, ''),
          year = dobj.getFullYear(),
          month = months[dobj.getMonth()],
          date = dobj.getDate(),
          day = days[dobj.getDay()],
          hours = dobj.getHours(),
          minutes = dobj.getMinutes(),
          seconds = dobj.getSeconds();
      
      return {
        number:obj.mobile.replace(/,/g, ''),
        fullname:obj.fullname,
        email:obj.email,
        time,
        year,
        month,
        date,
        day,
        hours,
        minutes,
        seconds
      }
    });

    var fileTitle = 'customers_'+this.state.sfilter+"_to_"+this.state.efilter; // or 'my-unique-title'

    exportCSVFile(headers, items, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }
  
  handleCSVImport=async (type, file)=>{
    //console.log(this.csvtext);
    var json = csvJSON(this.csvtext);
    console.log(json);
    for (var i = 0; i<json.length; i++){
      if(json[i].mobile && json[i].mobile[0] === "1"){
        json[i].mobile = json[i].mobile.substring(1,json[i].mobile.length);
      }
      this.insert = {
        fullname:json[i].fullname,
        mobile:json[i].mobile,
        email:json[i].email,
        notes:""
      }
      //console.log(this.insert);
      await this.handleInsert(NEW_USER);
      
    }
  }
  
  renderEventsGroupForm=()=>{
    return (
      <div style={{padding:10, backgroundColor:"#FFF", marginTop:10, borderRadius:5}}>
        <Form>
            <Form.Input fluid onChange={this.handleInsertObj.bind(this, "title")} placeholder="Volunteers" label="Group Name" />
            <Form.Input fluid onChange={this.handleInsertObj.bind(this, "description")} placeholder="people who are volunteering in this event" label="Group Description"/>
        </Form>
        <Divider hidden />
        <Button icon labelPosition="left" onClick={this.handleInsert.bind(this, NEW_GROUP)}><Icon name="add" />Create Group</Button>
      </div>
    )
  }
  
  renderGroups=()=>{
    var AllGroups = null;
    if(this.props.groups && this.props.groups.length > 0){
      AllGroups = this.props.groups.map((obj, i)=>{
        var gobj = Object.assign({}, obj);
        return (
          <Label className="boomTags" color={(this.state.groupedit && this.state.groupedit.id === gobj.id)?"yellow":null} key={i} onClick={()=>{
              this.setState({groupedit:(this.state.groupedit && this.state.groupedit.id === gobj.id)?null:gobj});
            }}>
            {obj.title} ({(this.props.groupusers.bygroups[obj.id])?this.props.groupusers.bygroups[obj.id].length:0})
          </Label>
        )
      });
    } else {
      return null;
    }
    return (
      <div>
        <div>GROUPS</div>
        <div style={{padding:10, backgroundColor:"#FFF", marginTop:10, borderRadius:5}}>
          {AllGroups}
        </div>
        {this.renderGroupEdits(this.state.groupedit)}
      </div>
    )
  }
  
  renderGroupEdits=(gobj)=>{
    //console.log(gobj);
    if(gobj === null){
      return null;
    }
    
    
    var AllUsers = null;
    if(this.props.groupusers.bygroups[gobj.id]){
      //
      var fuser = this.props.users.filter((uobj, id)=>{
        return (this.props.groupusers.bygroups[gobj.id].indexOf(uobj.id) !== -1);
      })
      
      AllUsers = fuser.map((uobj, id)=>{
        return (
          <Label key={id} size="small" className="groupNumbers">
            {uobj.mobile} - {uobj.fullname}<Icon name="close" circular inverted color="red" onClick={this.handleRemoveGroupUser.bind(this, uobj.id, gobj.id)}/>
          </Label>
        )
      })
    }
    return (
      <div style={{padding:10, backgroundColor:"#FFF", marginTop:10, borderRadius:5}}>
        <Form>
            <Form.Input fluid onChange={(ev, data)=>{
              gobj.title = data.value;
              this.setState({
                groupedit:gobj
              })
            }} placeholder="Volunteers" label="Group Name" value={gobj.title}/>
            <Form.Input fluid onChange={(ev, data)=>{
              gobj.description = data.value;
              this.setState({
                groupedit:gobj
              })
            }} placeholder="people who are volunteering in this event" label="Group Description" value={gobj.description}/>
        </Form>
        <Divider hidden />
        <div className="groupNumbersCont">
          {AllUsers}
        </div>
        <Divider hidden />
        <Button icon labelPosition="left" onClick={this.handleUpdate.bind(this, NEW_GROUP)}><Icon name="edit" />Update Group</Button>
      </div>
    )
  }
  
  renderNewUserForm=()=>{
    return (
      <div style={{padding:10, backgroundColor:"#FFF", marginTop:10, borderRadius:5}}>
        <Form>
            <Form.Input fluid onChange={this.handleInsertObj.bind(this, "mobile")} placeholder="7781234567" label="Phone Number" />
            <Form.Input fluid onChange={this.handleInsertObj.bind(this, "fullname")} placeholder="Bob Jones" label="Name"/>
            <Form.Input fluid onChange={this.handleInsertObj.bind(this, "email")} placeholder="bobjones@email.com" label="Email" />
            <Form.TextArea placeholder="Notes for Bob Jones" onChange={this.handleInsertObj.bind(this, "notes")}></Form.TextArea>
        </Form>
        <Divider hidden />
        <Button icon labelPosition="left" onClick={this.handleInsert.bind(this, NEW_USER)}><Icon name="add" />Add User</Button>
      </div>
    )
  }
  
  handlePaginate=(page)=>{
    this.setState({pagelimitstart:page*this.state.inc});
  }
  
  render() {
    //
    let arr = (this.props.users) ? this.props.users : [];
    var sdate = null,
        edate = null,
        chartArr = [],
        interval = 0,
        cheight = 5;
    if(this.props.filter && arr.length > 0){
      let csdate = this.state.sfilter,
          cedate = this.state.efilter;
    
      //FOR RECHART============================
      var chartData = {},
          totTime = 0,
          totType = 0,
          //dateopts = {timeZone: "Canada/Pacific"};
          dateopts = {};
      
      if(this.state.word !== ""){
        arr = arr.filter((obj)=>{
        //console.log(obj);
          if(isNaN(this.state.word[0])){
            return (obj.fullname && obj.fullname.indexOf(this.state.word) !== -1);
          } else {
            return (obj.mobile.indexOf(this.state.word) !== -1);
          }
        });
      }
      
      if(arr.length > 0){
        if(this.state.all || this.state.word !== ""){
          //console.log(arr[arr.length-1].time);
          csdate = new Date(arr[arr.length-1].time);
          cedate = new Date();
          csdate.setHours(0,0,0);
          cedate.setHours(23,59,59);
        } else if(!csdate || !cedate){
          csdate = new Date(arr[0].time);
          cedate = new Date();
          csdate.setHours(0,0,0);
          cedate.setHours(23,59,59);
        }

        totTime = cedate.getTime() - csdate.getTime();

        switch (true){
          case (totTime <= 60*60*24*1000):
            //separate by 24
            //dateopts = {timeZone: "Canada/Pacific", hour: '2-digit'};
            dateopts = {hour: '2-digit'};
            interval = 1;
            totType = 1;
            break;
          case (totTime <= 60*60*24*1000*14):
            //dateopts = {timeZone: "Canada/Pacific", month:"short", day:"2-digit"};
            dateopts = {month:"short", day:"2-digit"};
            //separate by 14
            totType = 2;
            break;
          case (totTime <= 60*60*24*1000*31):
            //separate by 31
            //dateopts = {timeZone: "Canada/Pacific", month:"short", day:"2-digit"};
            dateopts = {month:"short", day:"2-digit"};
            interval = 1;
            totType = 2;
            break;
          case (totTime <= 60*60*24*1000*31*3):
            //separate by 93
            //dateopts = {timeZone: "Canada/Pacific", month:"short", day:"2-digit"};
            dateopts = {month:"short", day:"2-digit"};
            interval = 4;
            totType = 2;
            break;
          case (totTime <= 60*60*24*1000*31*12):
            //dateopts = {timeZone: "Canada/Pacific", year:"numeric", month:"short"};
            dateopts = {year:"numeric", month:"short"};
            totType = 3;
            break;
          default:
            break;
        }

        var tDate = new Date(csdate),
            tInd = 0;
        while (tDate < cedate){
          chartData[tDate.toLocaleString("en-US", dateopts)] = 0;
          switch(totType){
            case 1:
              tDate.setHours(tDate.getHours()+1);
              break;
            case 2:
              tDate.setDate(tDate.getDate()+1);
              break;
            case 3:
              tDate.setMonth(tDate.getMonth()+1);
              break;
            default:
              break;
          }
          tInd++;
          if(tInd > 10000){
            break;
          }
        }

        arr = arr.filter((obj)=>{
          var date = new Date(obj.time);
          var tempdate = date.toLocaleString("en-US", dateopts);
          if((date >= csdate && date <= cedate) && (chartData[tempdate] || chartData[tempdate]===0)){
            chartData[tempdate]++;
          }
          return (date >= csdate && date <= cedate);
        });

        for(var i in chartData){
          var tobj = chartData[i];
          var obj = {
            name:i,
            users:tobj
          };

          if(obj.users > cheight){
            cheight = obj.users;
          }

          chartArr.push(obj);
        };

        //FOR FILTER INPUT======================
        //sdate = csdate.toLocaleDateString("en-US", {timeZone: "Canada/Pacific", year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
        //edate = cedate.toLocaleDateString("en-US", {timeZone: "Canada/Pacific", year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
        sdate = csdate.toLocaleDateString("en-US", {year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
        edate = cedate.toLocaleDateString("en-US", {year:"numeric", month:"2-digit", day:"2-digit"}).split("/");

        //sdate = new Date(sdate).toString("yyyy-MM-dd");
        sdate = sdate[2]+"-"+sdate[0]+"-"+sdate[1];
        edate = edate[2]+"-"+edate[0]+"-"+edate[1];
      }
    }
    if(this.props.limit){
      arr = arr.slice(0, this.props.limit);
    }
    
    var newarr = arr,
        paginate = [];
    if(newarr.length > this.state.inc){
      newarr = newarr.slice(this.state.pagelimitstart, this.state.pagelimitstart+this.state.inc);
      var lastpage = Math.floor(arr.length/this.state.inc),
          curpage = (this.state.pagelimitstart+this.state.inc)/this.state.inc;
      paginate.push(<a key={"pagefirst"} className="paginateitem" onClick={this.handlePaginate.bind(this, 0)}>first...</a>);
      //console.log(arr.length, this.state.inc, lastpage);
      for(var i = curpage-5; i<curpage+4; i++){
        if(i > lastpage){
          break;
        }
        if(i < 0){
          continue;
        }
        paginate.push(<a key={"page"+i} className="paginateitem" style={{color:(curpage===i+1)?"#333333":"#AAAAAA"}} onClick={this.handlePaginate.bind(this, i)}>{i+1}</a>)
      }
      
      paginate.push(<a key={"pagelast"} className="paginateitem" onClick={this.handlePaginate.bind(this, lastpage)}>...last</a>);
    }

    let AllUsers = newarr.map((obj,i)=>{
      var isEdit = false;
      if(this.state.editid === obj.id){
        isEdit = true;
      }
      return (
        <UsersCard 
          key={i} 
          obj={obj}
          filter={this.props.filter}
          isEdit={isEdit}
          handleOpenEdit={this.handleOpenEdit.bind(this, obj)}
          handleEditObj={this.handleEditObj}
          handleUpdate={this.handleUpdate.bind(this, NEW_USER)}
          onClick={this.view.bind(this, obj)}
          groupedit={this.state.groupedit}
          groupusers={this.props.groupusers}
          groups={this.props.groups}
          handleJoinGroup={this.handleJoinGroup.bind(this, obj)}
          sms={this.startSMS.bind(this, obj)} />
      )
    });
    
    return (
      <div>
        {(this.props.filter) ?
          <Form className="boomFilter">
            <Form.Group inline>
              <Label size="huge" pointing="right" color="blue">
                <Icon name='users' /> {arr.length}
              </Label>
              <Button
                icon
                labelPosition="left"
                onClick={this.exportCSV.bind(this, arr)}
                >
                <Icon name="download" />
                CSV
              </Button>
              <Button
                style={{marginLeft:10}}
                icon
                color={(this.state.showFilter)?"violet":"grey"}
                onClick={()=>{this.setState({showFilter:!this.state.showFilter})}}
                >
                <Icon name="settings" />
              </Button>
            </Form.Group>
            {(this.state.showFilter) ?
            <span>
              <Form.Group inline>
                {(edate && sdate) ? <Form.Input className="boomFilterInput" type="date" max={edate} value={sdate} label="From" onChange={this.handleDate.bind(this, 1)}  /> : null}
                {(edate && sdate) ? <Form.Input className="boomFilterInput" type="date" min={sdate} value={edate} label="To" onChange={this.handleDate.bind(this, 2)} /> : null}
              </Form.Group>
              <Form.Group inline>
                <Form.Input className="boomFilterInput" placeholder="Filter By Phone / Name" onChange={(ev, data)=>{
                    if(this.wordTimer !== null){
                      clearTimeout(this.wordTimer);
                      this.wordTimer = null;
                    }

                    this.wordTimer = setTimeout(()=>{
                      this.setState({word:data.value})
                    }, 500);
                  }} />
                <Checkbox 
                  className="boomFilterCheckbox"
                  toggle
                  label="All Users"
                  checked={this.state.all}
                  onChange={(ev,data) => {this.setState({all: data.checked})}}
                />
                <Checkbox 
                  className="boomFilterCheckbox"
                  toggle
                  label="Show Graph"
                  checked={this.state.showgraph}
                  onChange={(ev,data) => {this.setState({showgraph: data.checked})}}
                />
              </Form.Group>
              <Form.Group inline>
                <Checkbox 
                  className="boomFilterCheckbox"
                  toggle
                  label="New User"
                  checked={this.state.showadd === NEW_USER}
                  onChange={(ev,data) => {this.setState({showadd: (this.state.showadd === NEW_USER) ? false : NEW_USER})}}
                />

                <Checkbox 
                  className="boomFilterCheckbox"
                  toggle
                  label="New Group"
                  checked={this.state.showadd === NEW_GROUP}
                  onChange={(ev,data) => {this.setState({showadd: (this.state.showadd === NEW_GROUP) ? false : NEW_GROUP})}}
                />
              </Form.Group>
              <Form.Group inline>
                <Form.TextArea onChange={(ev,{value} )=>{this.csvtext = value}} />
                <Button onClick={this.handleCSVImport}>Import CSV</Button>
              </Form.Group>
            </span> : null}
          </Form>
          : null
        }
        {(this.props.filter && this.state.showgraph) ?
          <ResponsiveContainer width='100%' aspect={4.0/2.0}>
            <AreaChart data={chartArr}>
              <Area type='monotone' dataKey='users' stackId="3" stroke='#2185D0' fill='#2185D0' />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" interval={interval}/>
              <YAxis type="number" domain={[0, cheight]} interval={0} />
              <Tooltip />
            </AreaChart>
          </ResponsiveContainer>
          : null
        }
        
        {(this.props.filter && this.state.showadd === NEW_USER) ? this.renderNewUserForm() : null}
        
        {(this.props.filter && this.state.showadd === NEW_GROUP) ? this.renderEventsGroupForm():null}
        
        <Divider hidden />
        {(this.props.filter)?this.renderGroups():null}
        <Divider hidden />
        {paginate}
        {(this.state.groupedit !== null) ?
          <Button icon labelPosition="left" size="tiny" color="yellow" onClick={this.handleJoinGroupAll.bind(this, newarr)} style={{float:"right"}}>
            <Icon name='add' /> Join {this.state.groupedit.title}
          </Button> : null}
        <Divider hidden />
        <Feed>{AllUsers}</Feed>
        {paginate}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    users: state.Events.users,
    events_id: state.Events.id,
    mobile:state.Events.mobile,
    usersByMobile:state.Events.usersByMobile,
    groups:state.Events.groups,
    groupusers:state.Events.groupusers
  };
}

export default connect(mapStateToProps)(AllUsers);