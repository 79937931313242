import Clients from "./Clients";
import Events from "./Events";
import EventsAdmin from "./EventsAdmin";
import Dashboard from "./Dashboard";
import Customers from "./Customers";
import Admin from "./Admin";
import Surveys from "./Surveys";
import Prizes from "./Prizes";
import SMS from "./SMS";
import Menu from "./Menu";

const reducers = {
  persistable: {
    Clients,
    Admin,
    Dashboard,
    Events,
    EventsAdmin,
    Surveys,
    Customers,
    Prizes,
    Menu
  },
  forgettable: {
    SMS
  }
}


export default reducers;