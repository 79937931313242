import React, { Component, useEffect, useState } from 'react';
import {connect} from 'react-redux';
import {ReadMessages, ReadReceives, FinishReadMessages, ReadGroups} from "../../actions/events";
//import {ReadSurvey} from "../../actions/surveys";
import {ChangeSMSMode, InsertSMSSend, UpdateSMSSend} from "../../actions/sms";
import {Message, Icon, Label, Form, Divider, Button, Checkbox, Search, Dropdown} from 'semantic-ui-react'

const NumberDropdown = (props) => {
  //console.log(props.numbers);
  return (
    <Dropdown
      placeholder={props.numbers[0].text || ""}
      fluid
      selection
      search
      options={props.numbers}
      onChange={props.handleFrom}
    />
  );
}

const PrepareForm = (props)=>{
  var timer = null;
  var [mobiles, setMobiles] = useState({nums:[]});
  const [sms, setSMS] = useState("");
  const [newmobile, setNewMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({results:[]});
  useEffect(()=>{
    //console.log(props.mobiles);
    setMobiles({nums:props.mobiles})
  }, [props.mobiles]);
  
  const handleSearch = (e, { value }) =>{
    setLoading(true);
    setNewMobile(value);
    if(timer !== null){
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      var results = props.users.filter((obj)=>{
        return ((obj.mobile && obj.mobile.indexOf(value) !== -1) || (obj.fullname && obj.fullname.indexOf(value) !== -1) || (obj.email && obj.email.indexOf(value) !== -1));
      });
      //console.log(results);
      setSearch({results:results});
      setLoading(false);
      timer = null;
    }, 300);
  }
  
  const handleResult = (e, { result }) => {
    mobiles.nums.push(result.title);
    setMobiles({nums:mobiles.nums});
  }
  
  //console.log(search);
  var searchresults = [];
  if(search.results){
    searchresults = search.results.map((obj,i)=>{
      return {
        title:obj.mobile,
        description:obj.fullname+" | "+obj.email
      }
    });
  }
  
  
  return (
    <div className="blastsPrepare">
      <div>Prepare SMS Blasts</div>
      <Search
        loading={loading}
        onResultSelect={handleResult}
        onSearchChange={handleSearch}
        results={searchresults}
        placeholder="search..."
        value={newmobile}
      />
      <Divider hidden />
      <div className="blastsAllMobiles">
        {mobiles.nums.map((obj,i)=>{
          return <Label className="blastsLabel" key={i} onClick={()=>{
              mobiles.nums.splice(i, 1);
              setMobiles({nums:mobiles.nums})
            }}>
            <div><Icon name="close" color="red" className="blastsLabelClose" /></div>
            <div>{obj}<br/>{(props.usersByMobile[obj].fullname) ? " - "+props.usersByMobile[obj].fullname : null}</div>
          </Label>
        })}
      </div>
      <Label>SMS Message</Label>
      <textarea placeholder="SMS Blast message" onChange={(ev, data)=>{setSMS(ev.target.value)}}></textarea>
      <Divider hidden />
      <Button onClick={()=>{props.handlePrepare(mobiles.nums, sms)}}>Prepare Blast</Button>
    </div>
  )
}

const BlastCard = (props)=>{
  let obj = props.obj,
      //date = new Date(obj.time).toLocaleString("en-US", {timeZone: "Canada/Pacific"}),
      date = new Date(obj.time).toLocaleString("en-US"),
      receives = null;
  
  if(obj.receives){
    //alert("YAY");
    receives = obj.receives.map((obj2, i)=>{
      //var rdate = new Date(obj2.time).toLocaleString("en-US", {timeZone: "Canada/Pacific"});
      var rdate = new Date(obj2.time).toLocaleString("en-US");
      return (
        <Message key={i}>
          <Message.Content>
            <Label color="blue">
              {obj2.mobile}
              {(props.usersByMobile[obj2.mobile])?" - "+props.usersByMobile[obj2.mobile].fullname:null}
            </Label> <span className='msghead'>{obj2.message}</span> - <span className='msgdate'>{rdate}</span>
            {/*<Button icon size="tiny" color="blue" onClick={props.sms.bind(this, obj2)} floated="right">
              <Icon name='talk' />
            </Button>*/}
          </Message.Content>
        </Message>
      )
    })
  }
  
  var mobiles = [],
      mobiles_sent = [];
  if(obj.mobiles.length > 0){
    mobiles = obj.mobiles.split("::");
    mobiles.pop();
    mobiles.shift();
  }
  
  console.log(obj.mobiles_sent);
  if(obj.mobiles_sent && !Array.isArray(obj.mobiles_sent) && obj.mobiles_sent.length > 0){
    mobiles_sent = obj.mobiles_sent.split(",");
  }
  
  
  //console.log(props.obj);
  var fmob = [],
      mLab = [];
  
  if(mobiles.length > mobiles_sent.length || props.show){
    fmob = mobiles;
  } else {
    fmob = mobiles.splice(0,1);
  }
  mLab=fmob.map((obj, i)=>{
    return (
      <div key={"mobl"+i} className="mobLabel">
        <div className="mobLabelText">
          {obj}
          {(props.usersByMobile[obj]) ? <span>{props.usersByMobile[obj].fullname}</span> : null}
          {(mobiles_sent.indexOf(obj) === -1) ? <button disabled={props.load} onClick={props.handleUpdateSMS.bind(this, props.obj, obj)}>Send SMS</button> : <span><i>...Sent</i></span>}
        </div>
      </div>
    );  
  });
  
  
  return (
    <div className="blastsMsg">
      <Message>
        <Message.Header className="blastsMsgHead">
          <div style={{flex:6}}>
            {obj.message}
            <span className="dateDisp"> - {date}</span>
            <div className="mobCont">
              {mLab}{(mobiles.length <= mobiles_sent.length && !props.show) ? 
                <div className="mobLabel" onClick={props.showMore}>
                  <div className="mobLabelText mobLabelTextMore">Show All Sent Numbers...</div>
                </div>
                : null}
            </div>
          </div>
          <div style={{flex:1, textAlign:"right"}}>
            <Button compact color="blue" onClick={props.handleReceives}>
              <Icon name="comments" /> {(obj.receivenum) ? obj.receivenum : 0} 
            </Button>
            <Divider />
            {(mobiles.length > mobiles_sent.length) ? <Button compact color="green" onClick={props.handleUpdateSMSAll.bind(this, props.obj, mobiles)}>Send SMS to Everybody</Button> : null }
          </div>
        </Message.Header>
        {receives}
      </Message>
    </div>
    
      
  )
}

class AllBlasts extends Component {
  constructor(props){
    super(props);
    this.state = {
      sfilter:null,
      efilter:null,
      showMore:{},
      prepare:{
        mobiles:[]
      },
      showPrepare:false
    }
    
    if(this.props.filter && this.props.messages.length>0){
      //
      let sdate = new Date(this.props.messages[0].time),
          edate = new Date();
      sdate.setHours(0,0,0);
      edate.setHours(23,59,59);
      this.state = {
        sfilter:sdate,
        efilter:edate,
        showMore:{},
        prepare:{
          mobiles:[]
        },
        showPrepare:false
      }
    }
  }
  
  componentWillMount(){
    this.props.dispatch(ReadMessages(this.props.events_id, 1));
    if(!this.props.groups){
      this.props.dispatch(ReadGroups(this.props.events_id));
    }
  }
  
  handleDate=(i, evt, data)=>{
    //
    var sdate = (this.state.sfilter) ? this.state.sfilter : new Date(),
        edate = (this.state.efilter) ? this.state.efilter : new Date();
    if(i===1){
      sdate = new Date(data.value);
      sdate.setTime(sdate.getTime() + sdate.getTimezoneOffset()*60*1000);
    } else {
      edate = new Date(data.value);
      edate.setTime(edate.getTime() + edate.getTimezoneOffset()*60*1000);
    }
    
    sdate.setHours(0,0,0);
    edate.setHours(23,59,59);
    
    this.setState({
      sfilter:sdate,
      efilter:edate
    })
  }
  
  handleReceives=(obj, i)=>{
    this.props.dispatch(ReadReceives(obj.id, (receives)=>{
      //console.log(receives);
      if(receives && receives.length > 0){
        var messages = this.props.messages;
        messages[i].receives = receives;
        this.props.dispatch(FinishReadMessages(messages));
      }
      
    }));
  }
  
  handlePrepare=(mobiles, sms)=>{
    //console.log(mobiles);
    var users_id = mobiles.map((obj,i)=>{
      return this.props.usersByMobile[obj].id;
    });
    
    //console.log(users_id);
    var smsobj = {
      message:sms,
      events_id:this.props.events_id,
      users_id:users_id,
      mobiles:mobiles,
      blasts:1,
      type:1,
      from:this.props.mobile,
    }
    
    this.props.dispatch(InsertSMSSend(smsobj, ()=>{
      this.props.dispatch(ReadMessages(this.props.events_id, 1));
      this.setState({
        prepare:{
          mobiles:[]
        },
        showPrepare:false
      })
    }))
  }
  
  startSMS=(num, gid)=>{
    //console.log(gid, this.props.groupusers)
    //return false;
    //users_id = [],
    var mobiles = [];
    
    switch (num){
      case 1:
        //users_id = [];
        mobiles = [];
        break;
      
      case 2:
        for(var i=0; i<this.props.users.length; i++){
          var uobj = this.props.users[i];
          if(uobj.optin === 1){
            //users_id.push(uobj.uid);
            mobiles.push(uobj.mobile);
          }
        }
        break;
      
      case 3:
        for(var i=0; i<this.props.users.length; i++){
          var uobj = this.props.users[i];
          if(this.props.groupusers.bygroups[gid] && this.props.groupusers.bygroups[gid].indexOf(uobj.id) !== -1){
            //users_id.push(uobj.uid);
            mobiles.push(uobj.mobile);
          }
        }
        break;
        
      default:
        //users_id = this.props.users.map((obj)=>{return obj.uid});
        mobiles = this.props.users.map((obj)=>{return obj.mobile});
        break;
    }
    
    this.state.prepare.mobiles = mobiles;
    this.setState({
      prepare:this.state.prepare,
      showPrepare:true
    });
    
    /*var obj = {
      mode:2,
      type:1,
      users_id:users_id,
      mobiles:mobiles,
      blasts:1,
      events_id:this.props.events_id,
      from:this.props.mobile
    };*/
    
    /*this.props.dispatch(InsertSMSSend(obj, ()=>{
      this.props.dispatch(ReadMessages(this.props.events_id, 1));
    }));*/
  }
  
  handleUpdateSMS=async (sms, mobiles_sent)=>{
    //console.log(sms);
    if(sms !== null){
      this.cursms = sms;
    }
    
    if(!this.cursms){
      //console.log("no sms to send");
      return false;
    }
    
    var tmb = [];
    if(this.cursms && this.cursms.mobiles_sent){
      if(typeof this.cursms.mobiles_sent === "string"){
        tmb = this.cursms.mobiles_sent.split(",");
      }
      if(Array.isArray(this.cursms.mobiles_sent)){
        tmb = this.cursms.mobiles_sent;
      }
    }
    
    if(tmb.indexOf(mobiles_sent) !== -1){
      //console.log("number already sent")
      return null;
    }
    
    tmb.push(mobiles_sent);
    this.cursms.mobiles_sent = tmb;
    
    //console.log("mobiles_sent", this.cursms.mobiles_sent);
    
    var message = this.cursms.message.split(" :: ");
    if(message.length > 1){
      var randInd = 0;
      while(randInd === 0 || randInd === message.length+1){
        randInd = Math.round(Math.random()*(message.length+1))
      }
      message = message[randInd-1];
      if(!message){
        message = message[0];
      }
    } else {
      message = message[0];
    }
    
    if(this.props.usersByMobile[mobiles_sent] && this.props.usersByMobile[mobiles_sent].fullname){
      var name = this.props.usersByMobile[mobiles_sent].fullname.split(" ");
      name = name[0] || "";
      message = message.replace("[name]", name);
    } else {
      message = message.replace("[name]", "guest");
    }
    
    var obj = {
      mobiles:[mobiles_sent],
      message:message,
      from:this.from || this.props.mobile
    };
    
    //console.log(obj.message);
    //return false;
    var data = await this.props.dispatch(UpdateSMSSend(this.cursms, obj));
    await this.props.dispatch(ReadMessages(this.props.events_id, 1));
  }
  
  handleUpdateSMSAll=async (sms, all_mobiles)=>{
    //console.log(sms, all_mobiles);
    //for tmp storage so that it goes through and doesn't change
    if(window.confirm("Are you sure you want to blast this text to everybody?")){
      this.cursms = Object.assign({},sms);
      //console.log(this.cursms);
      for(var i = 0; i<all_mobiles.length; i++){
        var mobiles_sent = all_mobiles[i];
        
        for(var j=0;j<5;j++){
          await this.handleUpdateSMS(null, mobiles_sent);
        }
      }
      this.cursms = null;
    }
  }
  
  respondSMS=(obj)=>{
    //
    var uid = this.props.usersByMobile[obj.mobile].uid;
    
    //console.log(uid);
    this.props.dispatch(ChangeSMSMode({
      mode:2,
      type:4,
      users_id:[uid],
      mobiles:[obj.mobile],
      blasts:0,
      events_id:this.props.events_id,
      sms_receive_id:obj.id,
      from:this.from || this.props.mobile
    }));
  }
  
  view=(obj)=>{
    //this.props.dispatch(ReadSurvey(obj));
    //this.props.dispatch(ChangePage(4));
  }
  
  showMore=(id)=>{
    var obj = this.state.showMore;
    obj[id] = true;
    this.setState({
      showMore:obj
    })
  }
  
  handleFrom=(ev, {value})=>{
    this.from = value;
  }
  
  renderGroups=()=>{
    var AllGroups = null;
    if(this.props.groups && this.props.groups.length > 0){
      AllGroups = this.props.groups.map((obj, i)=>{
        return (
          <Button title={obj.description} icon size="tiny" color="yellow" key={i} onClick={this.startSMS.bind(this, 3, obj.id)}>
            <Icon name='talk' /> {obj.title}
          </Button>
        )
      });
    } else {
      return null;
    }
    return (
      <div style={{padding:10, backgroundColor:"#FFF", marginTop:10, borderRadius:5}}>
        {AllGroups}
      </div>
    )
  }
  
  render() {
    //console.log(this.state.prepare);
    //
    let arr = this.props.messages;
    var sdate = null,
        edate = null;
    
    //
    if(this.props.filter && arr.length > 0){
      let csdate = this.state.sfilter,
          cedate = this.state.efilter;
      if(!csdate || !cedate){
        csdate = new Date(this.props.messages[0].time);
        cedate = new Date();
        csdate.setHours(0,0,0);
        cedate.setHours(23,59,59);
      }
      
      //sdate = csdate.toLocaleDateString("en-US", {timeZone: "Canada/Pacific", year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
      //edate = cedate.toLocaleDateString("en-US", {timeZone: "Canada/Pacific", year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
      sdate = csdate.toLocaleDateString("en-US", {year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
      edate = cedate.toLocaleDateString("en-US", {year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
      
      //sdate = new Date(sdate).toString("yyyy-MM-dd");
      sdate = sdate[2]+"-"+sdate[0]+"-"+sdate[1];
      edate = edate[2]+"-"+edate[0]+"-"+edate[1];
      //
      arr = arr.filter((obj)=>{
        var date = new Date(obj.time);
        return (date >= csdate && date <= cedate);
      });
    }
    
    if(this.props.limit){
      arr = arr.slice(0, this.props.limit);
    }
    
    //console.log(arr);
    
    let AllBlasts = arr.map((obj,i)=>{
      var show = (this.state.showMore[obj.id]) ? this.state.showMore[obj.id] : false;
      return (
        <BlastCard key={i} load={this.props.load} showMore={this.showMore.bind(this, obj.id)} usersByMobile={this.props.usersByMobile} show={show} obj={obj} handleReceives={this.handleReceives.bind(this, obj, i)} sms={this.respondSMS} handleUpdateSMS={this.handleUpdateSMS} handleUpdateSMSAll={this.handleUpdateSMSAll}/>
      )
    });
    
    var numbers = this.props.mobilearr.map((obj,i)=>{
      return {
        key: obj,
        text: obj,
        value: obj
      };
    });
    
    return (
      <div className="modalCont">
        <Form>
        {(sdate && edate) ?
            <Form.Group inline>
              <Label size="huge" pointing="right" color="blue">
                <Icon name='comments' /> {arr.length}
              </Label>
              <Form.Input type="date" label="From" max={edate} onChange={this.handleDate.bind(this, 1)} value={sdate} />
              <Form.Input type="date" label="To" min={sdate} onChange={this.handleDate.bind(this, 2)} value={edate} />
            </Form.Group>
          : null
        }
          <Form.Group inline>
            <Button icon size="tiny" color="teal" onClick={this.startSMS.bind(this, 2)} floated="right">
              <Icon name='talk' /> All Opt-in
            </Button>
            <Button icon size="tiny" color="blue" onClick={this.startSMS} floated="right">
              <Icon name='talk' /> All
            </Button>
            <Button icon size="tiny" color="blue" onClick={this.startSMS.bind(this, 1)} floated="right">
              <Icon name='talk' /> One
            </Button>
          </Form.Group>
          <Form.Group inline>
            {this.renderGroups()}
          </Form.Group>  
          
          {(this.state.showPrepare) ? <Form.Group inline>
            <PrepareForm users={this.props.users} mobiles={this.state.prepare.mobiles} usersByMobile={this.props.usersByMobile} handlePrepare={this.handlePrepare}/>
          </Form.Group> : null}
        </Form>
        <Divider hidden />
        <NumberDropdown numbers={numbers} handleFrom={this.handleFrom}/>
        {AllBlasts}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    load:state.Admin.load,
    messages: state.Events.messages,
    events_id: state.Events.id,
    users:state.Events.users,
    usersByMobile:state.Events.usersByMobile,
    mobile: state.Events.mobile,
    mobilearr: state.Events.mobilearr,
    groups:state.Events.groups,
    groupusers:state.Events.groupusers
  };
}

export default connect(mapStateToProps)(AllBlasts);