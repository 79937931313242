import React, { Component } from 'react';
import {connect} from 'react-redux';
import {InsertClient} from "../../actions/dashboard";
import { Card, Icon, Form, Divider } from 'semantic-ui-react'

class AddClient extends Component {
  constructor(props){
    super(props);
    this.name = "";
    this.email = "";
    this.desc = "";
    this.password = "";
    this.number = "";
  }
  
  handleName=(evt, data)=>{
    //
    this.name=data.value;
  }
  
  handleEmail=(evt, data)=>{
    this.email=data.value;
  }
  
  handlePassword=(evt, data)=>{
    this.password=data.value;
  }
  
  handleNumber=(evt, data)=>{
    this.number=data.value;
  }
  
  handleDesc=(evt, data)=>{
    this.desc=data.value;
  }
  
  insert=()=>{
    this.props.dispatch(InsertClient({
      name:this.name,
      email:this.email,
      description:this.desc,
      password:this.password,
      number:this.number
    }))
  }
  
  render() {
    
    return (
      <Form>
        <Card fluid={true}>
          <Card.Content>
            <Card.Header>
              <Form.Input onChange={this.handleName} fluid placeholder="eg: Boomerang Data Inc" label="Name"/>
            </Card.Header>
            <Divider hidden />
            <Card.Meta>
              <Form.Input onChange={this.handleEmail} fluid placeholder="eg: info@boomerangdata.com" label="Email" />
            </Card.Meta>
            <Divider hidden />
            <Card.Meta>
              <Form.Input onChange={this.handlePassword} fluid type="password" placeholder="*********" label="Password" />
            </Card.Meta>
            <Divider hidden />
            <Card.Meta>
              <Form.Input onChange={this.handleNumber} fluid type="number" placeholder="1234567890" label="Twilio Phone Number" />
            </Card.Meta>
            <Divider hidden />
            <Card.Description>
              <Form.TextArea onChange={this.handleDesc} label="Description" placeholder="Description of the client" />
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <a>
              <Form.Button icon labelPosition='left' onClick={this.insert}>
                <Icon name='checkmark' />
                Create Client
              </Form.Button>
            </a>
          </Card.Content>
        </Card>
      </Form>
    )
  }
}

function mapStateToProps(state) {
  return {
    newclient: state.Dashboard.newclient
  };
}

export default connect(mapStateToProps)(AddClient);