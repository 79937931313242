import React, { Component } from 'react';
import {connect} from 'react-redux';
import {awsfetch} from "../../connect";
import {UpdateSurvey, ChangeMode} from "../../actions/surveys";
import { Card, Icon, Form, Divider, Checkbox } from 'semantic-ui-react';
import {compress}from '../compress';

class EditSurvey extends Component {
  constructor(props){
    super(props);
    this.state = {
      title:this.props.title,
      desc:this.props.description,
      prev:null,
      popup:this.props.popup,
      reveal:this.props.reveal
    }
    this.files = null;
    this.compfileobj = null;
    this.blob = null;
  }
  
  handleTitle=(evt, data)=>{
    //
    this.setState({
      title:data.value
    })
  }
  
  handleDesc=(evt, data)=>{
    this.setState({
      desc:data.value
    })
  }
  
  handleCheck=(evt, data)=>{
    //console.log(data);
    this.setState({
      popup:(data.checked)?1:0
    })
  }
  
  handleReveal=(evt, data)=>{
    //console.log(data);
    this.setState({
      reveal:(data.checked)?1:0
    })
  }
  
  edit=async ()=>{
    
    if(this.compfileobj){
      var cid = (this.props.cid)?"cli"+this.props.cid:"clientid";
      var data = {
        name:"clients/"+cid+"/events/ev"+this.props.eid+"/surveys/sv"+this.props.id+"/survey",
        file:this.compfileobj,
        blob:this.blob
      }
      var json = await awsfetch(data);
      if(json.location){
        this.props.dispatch(UpdateSurvey({
          id:this.props.id,
          title:this.state.title,
          description:this.state.desc,
          popup:this.state.popup,
          reveal:this.state.reveal,
          img:json.location
        }));
      }
    } else {
     this.props.dispatch(UpdateSurvey({
        id:this.props.id,
        title:this.state.title,
        description:this.state.desc,
        popup:this.state.popup,
        reveal:this.state.reveal,
      }));
    }
  }
  
  handleFile=(evt, data)=>{
    //
    this.files = evt.target.files;
    var reader = new FileReader();

    reader.onload = (e)=>{
      //$('#blah').attr('src', e.target.result);
      compress(e.target.result, this.files[0].name, (dataurl, compfileobj, blob)=>{
        this.compfileobj = compfileobj;
        this.blob = blob;
        this.setState({
          prev:dataurl
        });
      });
      
    }
    //console.log(this.files);
    reader.readAsDataURL(this.files[0]);
  }
  
  render() {
    
    var prev = (this.state.prev) ? <div className="promo" style={{maxWidth:"400px", backgroundImage:"url("+this.state.prev+")"}}></div> : null;
    //console.log(this.state.popup);
    return (
      <Form>
        <Card fluid={true}>
          <Card.Content>
            <Card.Header>
              <Form.Input onChange={this.handleTitle} fluid placeholder="eg: Boomerang Data Survey" value={this.state.title} label="Title"/>
            </Card.Header>
            <Divider hidden />
            <Card.Description>
              <Form.Input type="file" label="Promo Image" onChange={this.handleFile} />
              {prev}
              <Divider hidden />
              <Form.TextArea onChange={this.handleDesc} label="Description" placeholder="Description of the survey" value={this.state.desc} />
              <Checkbox label="Survey Pop Up" checked={this.state.popup === 1} onChange={this.handleCheck} /> 
              <Checkbox label="Reveal Answers" checked={this.state.reveal === 1} onChange={this.handleReveal} style={{marginLeft:20}}/>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <a>
              <Form.Button icon labelPosition='left' onClick={this.edit}>
                <Icon name='checkmark' />
                Done
              </Form.Button>
            </a>
          </Card.Content>
        </Card>
      </Form>
    )
  }
}

function mapStateToProps(state) {
  return {
    id:state.Surveys.id,
    eid:state.Events.id,
    cid:state.Clients.id,
    title:state.Surveys.title,
    description:state.Surveys.description,
    popup:state.Surveys.popup,
    reveal:state.Surveys.reveal
  };
}

export default connect(mapStateToProps)(EditSurvey);