import React, { Component, useState } from 'react';
import {connect} from 'react-redux';
import {ReadSignins, ReadUsers} from "../../actions/events";
//import {ReadSurvey} from "../../actions/surveys";
import {Feed, Icon, Label, Form, Divider, Popup, Checkbox, Button, Badge} from 'semantic-ui-react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import {exportCSVFile, months, days} from '../../func/csv';

const SigninCard = (props) => {
  let obj = (props.arr) ? props.arr[0] : props.obj,
      arr = (props.arr) ? props.arr : null,
      //date = new Date(obj.time).toLocaleString("en-US", {timeZone: "Canada/Pacific"}),
      date = new Date(obj.time).toLocaleString("en-US");
      //udate = (uobj) ? new Date(uobj.time).toLocaleString("en-US", {timeZone: "Canada/Pacific"}) : null,
      //udate = (uobj) ? new Date(uobj.time).toLocaleString("en-US") : null,
      //totalpts = 0,
      //redeempts = 0,
      //optionspts = 0;
  
  const [showSigns, setShowSigns] = useState(false);
  /*
  if(uobj){
    totalpts = (uobj.totalpts)?parseInt(uobj.totalpts, 10):0;
    redeempts = (uobj.redeempts)?parseInt(uobj.redeempts, 10):0;
    optionspts = (uobj.optionspts)?parseInt(uobj.optionspts, 10):0;
  }
  //console.log(uobj);
  */
  //var trigger = <Feed.User>{obj.mobile} - {obj.fullname}</Feed.User>;
  var subSigns = null;
  if(showSigns && arr){
    subSigns = arr.map((obj,i)=>{
      return (
        <Feed.Summary style={{marginLeft:20}} key={i}>
          
          <span style={{color:"#666666"}}>
            {
              //(obj.optin === 1)? <Icon name='check' color="green" /> : null
              }
            {obj.mobile} - {obj.fullname}
          </span>
          <br />
          {/*}
          <Popup trigger={trigger}>
            <Popup.Content>
              <Icon name='gift' />{totalpts} + {optionspts} - {redeempts} = {totalpts+optionspts-redeempts}
              <div style={{fontSize:12, color:"#999999"}}>joined on {udate}</div>
            </Popup.Content>
          </Popup>*/}
          <Label size="mini" style={{marginRight:1}} color="orange">
            {obj.pts}pts
          </Label>
          {(obj.pts <= 0) ?
            <Label size="mini" style={{marginRight:5}} color="blue">
              promo
            </Label> : null}
          <Feed.Date>{date}</Feed.Date>
          <Divider />
        </Feed.Summary>
      )
    });
  }
  
  return (
    <Feed.Event>
      <Feed.Content>
        <Feed.Summary onClick={()=>{setShowSigns(!showSigns)}}>
          {(arr) ? 
          <Label size="mini" style={{marginRight:5}} color= {(!showSigns) ? "yellow" : "grey"}>
            {(!showSigns) ? <Icon name="sort down" /> : <Icon name="sort up" />}{arr.length}
          </Label> : null}
          <span style={{color:"#666666"}}>
            {
              //(obj.optin === 1)? <Icon name='check' color="green" /> : null
            }
            {obj.mobile} {obj.fullname}
          </span>
          <br />
          
          {/*}
          <Popup trigger={trigger}>
            <Popup.Content>
              <Icon name='gift' />{totalpts} + {optionspts} - {redeempts} = {totalpts+optionspts-redeempts}
              <div style={{fontSize:12, color:"#999999"}}>joined on {udate}</div>
            </Popup.Content>
          </Popup>*/}
          <Label size="mini" style={{marginRight:1}} color="orange">
            {obj.pts}pts
          </Label>
          {(obj.pts <= 0) ?
            <Label size="mini" style={{marginRight:5}} color="blue">
              promo
            </Label> : null}
          <Feed.Date>{date}</Feed.Date>
          <Divider />
        </Feed.Summary>
        {subSigns}
      </Feed.Content>
    </Feed.Event>
  )
}

class AllSignins extends Component {
  constructor(props){
    super(props);
    this.state = {
      sfilter:null,
      efilter:null,
      showgraph:false,
      all:false,
      word:"",
      showpromo:true
    }
    
    this.wordTimer = null;
    if(this.props.filter && this.props.signins && this.props.signins.length>0){
      
      let sdate = new Date(this.props.signins[0].time),
          edate = new Date();
      
      sdate.setHours(0,0,0);
      edate.setHours(23,59,59);
      this.state = {
        sfilter:sdate,
        efilter:edate,
        showgraph:false,
        all:false,
        word:"",
        showpromo:true
      }
    }
  }
  
  componentWillMount(){
    if(this.props.nofetch && this.props.nofetch === true){
      return false;
    }
    this.props.dispatch(ReadSignins(this.props.events_id));
    if(this.props.users){
      if(this.props.users.length <= 0){
        this.props.dispatch(ReadUsers(this.props.events_id));
      }
    }
  }
  
  handleDate=(i, evt, data)=>{
    var sdate = this.state.sfilter,
        edate = this.state.efilter;
    if(i===1){
      sdate = new Date(data.value);
      sdate.setTime(sdate.getTime() + sdate.getTimezoneOffset()*60*1000);
    } else {
      edate = new Date(data.value);
      edate.setTime(edate.getTime() + edate.getTimezoneOffset()*60*1000);
    }
    
    this.setState({
      sfilter:sdate,
      efilter:edate,
      all:false
    })
  }
  
  view=(obj)=>{
    //this.props.dispatch(ReadSurvey(obj));
    //this.props.dispatch(ChangePage(4));
  }
  
  exportCSV=(arr)=>{
    var headers = {
      number: 'Phone Numbers'.replace(/,/g, ''), // remove commas to avoid errors
      fullname: "Full Name",
      email: "Email",
      time: "Signin Time",
      year: "Year",
      month: "Month",
      date: "Date",
      day: "Day",
      hours: "Hours",
      minutes: "Minutes",
      seconds: "Seconds"
    };
    
    //console.log(arr);
    var items = arr.map((obj,i)=>{
      var dobj = new Date(obj.time),
          time = dobj.toLocaleString("en-US").replace(/,/g, ''),
          year = dobj.getFullYear(),
          month = months[dobj.getMonth()],
          date = dobj.getDate(),
          day = days[dobj.getDay()],
          hours = dobj.getHours(),
          minutes = dobj.getMinutes(),
          seconds = dobj.getSeconds();
      
      return {
        number:obj.mobile.replace(/,/g, ''),
        fullname:obj.fullname,
        email:obj.email,
        time,
        year,
        month,
        date,
        day,
        hours,
        minutes,
        seconds
      }
    });

    var fileTitle = 'signins_'+this.state.sfilter+"_to_"+this.state.efilter; // or 'my-unique-title'

    exportCSVFile(headers, items, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }
  
  
  render() {
    let arr = (this.props.signins) ? this.props.signins : [];
    var sdate = null,
        edate = null;
    
    
    var usignins = {};
    if(this.props.filter && arr.length > 0){
      let csdate = this.state.sfilter,
          cedate = this.state.efilter;
      
      //FOR RECHART============================
      var chartData = {},
          totTime = 0,
          totType = 0,
          interval = 0,
          //dateopts = {timeZone: "Canada/Pacific"};
          dateopts = {};
      
      if(!csdate || !cedate){
        csdate = new Date(this.props.messages[0].time);
        cedate = new Date();
        csdate.setHours(0,0,0);
        cedate.setHours(23,59,59);
      }
      
      if(this.state.all){
        //console.log(arr[arr.length-1].time);
        csdate = new Date(arr[arr.length-1].time);
        csdate.setHours(0,0,0);
      }
      
      totTime = cedate.getTime() - csdate.getTime();
      
      switch (true){
        case (totTime <= 60*60*24*1000):
          //separate by 24
          //dateopts = {timeZone: "Canada/Pacific", hour: '2-digit'};
          dateopts = {hour: '2-digit'};
          interval = 1;
          totType = 1;
          break;
        case (totTime <= 60*60*24*1000*14):
          //dateopts = {timeZone: "Canada/Pacific", month:"short", day:"2-digit"};
          dateopts = {month:"short", day:"2-digit"};
          //separate by 14
          totType = 2;
          break;
        case (totTime <= 60*60*24*1000*31):
          //separate by 31
          //dateopts = {timeZone: "Canada/Pacific", month:"short", day:"2-digit"};
          dateopts = {month:"short", day:"2-digit"};
          interval = 1;
          totType = 2;
          break;
        case (totTime <= 60*60*24*1000*31*3):
          //separate by 93
          //dateopts = {timeZone: "Canada/Pacific", month:"short", day:"2-digit"};
          dateopts = {month:"short", day:"2-digit"};
          interval = 4;
          totType = 2;
          break;
        case (totTime <= 60*60*24*1000*31*12):
          //dateopts = {timeZone: "Canada/Pacific", year:"numeric", month:"short"};
          dateopts = {year:"numeric", month:"short"};
          totType = 3;
          break;
        default:
          break;
      }
      
      var tDate = new Date(csdate),
          tInd = 0;
      while (tDate < cedate){
        chartData[tDate.toLocaleString("en-US", dateopts)] = 0;
        switch(totType){
          case 1:
            tDate.setHours(tDate.getHours()+1);
            break;
          case 2:
            tDate.setDate(tDate.getDate()+1);
            break;
          case 3:
            tDate.setMonth(tDate.getMonth()+1);
            break;
          default:
            break;
        }
        tInd++;
        if(tInd > 10000){
          break;
        }
      }
      
      //get the individual user and its arrays
      var numsignins = 0,
          chartArr = [],
          cheight = 5;
      for (var i=0; i<arr.length; i++){
        var obj = arr[i];
        //obj.users_events_id
        var date = new Date(obj.time);
        var tempdate = date.toLocaleString("en-US", dateopts);
        
        if((date >= csdate && date <= cedate) && (chartData[tempdate] || chartData[tempdate]===0)){
          chartData[tempdate]++;
        }
        if(date >= csdate && date <= cedate){
          if(!usignins[obj.users_events_id]){
            usignins[obj.users_events_id] = [];
          }
          if(!this.state.showpromo && obj.pts > 0){
            numsignins++;
            usignins[obj.users_events_id].push(obj);
          } else if(this.state.showpromo){
            numsignins++
            usignins[obj.users_events_id].push(obj);
          }
        }
      };
      
    
      for(var i in chartData){
        var tobj = chartData[i];
        var obj = {
          name:i,
          signins:tobj
        };
        
        if(obj.signins > cheight){
          cheight = obj.signins;
        }

        chartArr.push(obj);
      };
      //
      
      //FOR FILTER INPUT======================
      //sdate = csdate.toLocaleDateString("en-US", {timeZone: "Canada/Pacific", year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
      //edate = cedate.toLocaleDateString("en-US", {timeZone: "Canada/Pacific", year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
      sdate = csdate.toLocaleDateString("en-US", {year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
      edate = cedate.toLocaleDateString("en-US", {year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
      
      //sdate = new Date(sdate).toString("yyyy-MM-dd");
      sdate = sdate[2]+"-"+sdate[0]+"-"+sdate[1];
      edate = edate[2]+"-"+edate[0]+"-"+edate[1];
      //
    }
    
    let AllSignins = [];
    if(this.props.limit){
      arr = arr.slice(0, this.props.limit);
    }
    
    //console.log(uobj);
    //console.log(usignins);
    if(this.props.filter){
      for (var i in usignins){
        if(usignins[i].length > 0){
          AllSignins.push(<SigninCard key={i} arr={usignins[i]} />)
        }
      };
    } else {
      AllSignins = arr.map((obj,i)=>{
         return <SigninCard key={i} obj={obj} />
      });
    }
    
    
    return (
      <div>
        {(this.props.filter) ?
          <Form>
            <Form.Group inline>
              <Label size="huge" pointing="right" color="yellow">
                <Icon name='bell' /> {numsignins}
              </Label>
              {(edate && sdate) ? <Form.Input type="date" max={edate} value={sdate} label="From" onChange={this.handleDate.bind(this, 1)}  /> : null}
              {(edate && sdate) ? <Form.Input type="date" min={sdate} value={edate} label="To" onChange={this.handleDate.bind(this, 2)} /> : null}
              <Button
                icon
                labelPosition="left"
                onClick={this.exportCSV.bind(this, arr)}
                >
                <Icon name="download" />
                CSV
              </Button>
            </Form.Group>
            <Form.Group inline>
              <Form.Input placeholder="Phone / Name" onChange={(ev, data)=>{
                  if(this.wordTimer !== null){
                    clearTimeout(this.wordTimer);
                    this.wordTimer = null;
                  }
                  
                  this.wordTimer = setTimeout(()=>{
                    this.setState({word:data.value})
                  }, 500);
                }} />
              
              <Checkbox 
                style={{marginLeft:10}}
                toggle
                label="All Sign Ins"
                checked={this.state.all}
                onChange={(ev,data) => {this.setState({all: data.checked})}}
              />
              
              <Checkbox 
                style={{marginLeft:10}}
                toggle
                label="Show Graph"
                checked={this.state.showgraph}
                onChange={(ev,data) => {this.setState({showgraph: data.checked})}}
              />
               <Label size="medium" style={{marginLeft:10}} 
                 color={this.state.showpromo ? "blue" : "grey"} 
                 onClick={()=>{this.setState({showpromo:!this.state.showpromo})}}>
                promo
              </Label>
            </Form.Group>
          </Form>
          : null
        }
        {(sdate && edate && this.state.showgraph) ?
          <ResponsiveContainer width='100%' aspect={3.0/2.0}>
            <AreaChart data={chartArr}>
              <Area type='monotone' dataKey='signins' stackId="1" stroke='#FBBD08' fill='#FBBD08' />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" interval={interval}/>
              <YAxis type="number" domain={[0, cheight]} interval={0} />
              <Tooltip />
            </AreaChart>
          </ResponsiveContainer>
          : null
        }
        <Divider hidden />
        <Feed>{AllSignins}</Feed>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    signins: state.Events.signins,
    events_id: state.Events.id,
    users: state.Events.users
  };
}

export default connect(mapStateToProps)(AllSignins);