import {mfetch} from '../connect';
import {clients, reducers} from '../types';
import {Loading} from './admin';

export function InsertUser(mobile, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"create", key:"users", data:{mobile:mobile}}});
    
    //dispatch(FinishUser(json.data[0]));
    if(typeof callback === "function"){
      callback(json.data[0]);
    }
    return json.data[0];
    dispatch(Loading(false));
  }
}

export function InsertUsersEvents(ue, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"create", key:"users_events", data:ue}});
    if(typeof callback === "function"){
      callback();
    }
    return json;
    dispatch(Loading(false));
  }
}

export function UpdateUsersEvents(ue, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"update", key:"users_events", data:ue}});
    if(typeof callback === "function"){
      callback();
    }
    dispatch(Loading(false));
  }
}