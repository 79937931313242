import React, { Component } from 'react';
import {connect} from 'react-redux';
import {ChangePage} from "../actions/admin";
import {Icon, Breadcrumb} from 'semantic-ui-react';

const MyStep = function(props){
  let ico = <Icon name={props.icon} />
  if(props.page===props.active){
    ico = <Icon name={props.icon} />
  }
  return (
    <span>
      <Breadcrumb.Section active={props.page===props.active} onClick={props.onClick}>
        {ico} - {props.name}
      </Breadcrumb.Section>
      <Breadcrumb.Divider />
    </span>
  );
}

class Bread extends Component {

  changePage=(num)=>{
    this.props.dispatch(ChangePage(num));
  }
  
  render() {
    let client = (this.props.client.id !== null) ? <MyStep name={this.props.client.name} page={this.props.page} active={2} onClick={this.changePage.bind(this,2)} icon="id card"/> : null,
        event = (this.props.event.id !== null) ? <MyStep name={this.props.event.name} page={this.props.page} active={3} onClick={this.changePage.bind(this,3)} icon="add to calendar" /> : null,
        survey = (this.props.survey.id !== null) ? <MyStep name={this.props.survey.title} page={this.props.page} active={4} onClick={this.changePage.bind(this,4)} icon="bar chart" /> : null,
        prize = (this.props.prize.id !== null) ? <MyStep name={this.props.prize.name} page={this.props.page} active={5} onClick={this.changePage.bind(this,5)} icon="gift" /> : null;
    
    return (
      <Breadcrumb size="mini">
        {client}
        {event}
        {survey}
        {prize}
      </Breadcrumb>
    )
  }
}


function mapStateToProps(state) {
  return {
    page:state.Admin.page,
    client:state.Clients,
    event:state.Events,
    survey:state.Surveys,
    prize:state.Prizes
  };
}

export default connect(mapStateToProps)(Bread);