import {reducers} from "../types";
const initialState = {
  mode:0,
  ea:{}
};


export default function eventsadmin(state = initialState, action){
  let obj = Object.assign({}, state);
  switch(action.type) {
    case reducers.EVENTS_ADMIN_MODE:
      obj.mode = action.mode;
      return obj;
      
    case reducers.EVENTS_ADMIN_READ:
      obj.ea = action.ea;
      return obj;
    case reducers.LOAD_STORED:
      return action.storedState.EventsAdmin;
    default:
      return state;
  }
}