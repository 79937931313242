import React, { Component } from 'react';
import './dist/semantic.cerulean_c.css';
import './dist/custom_style.css';
//import 'semantic-ui-css/semantic.min.css';
import './css/app.scss';
//import './semantic.superhero.min.css';
import Main from "./admin/Main";

//import Register from "./customers/Register";
//import Customer from "./customers/App";
import thunkMiddleware from 'redux-thunk';
//import { createSession } from 'redux-session';
import reduxRemember from 'redux-remember';
import {Provider} from "react-redux";
import {applyMiddleware, compose} from "redux";

import reducers from "./reducers/reducers";
import {DEV} from './connect';
//const session = createSession({ ns: 'boomproj' });
const { createStore, combineReducers } = reduxRemember(
    window.sessionStorage
);

const composeEnhancers =
  typeof window === 'object' && DEV &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(
    //session,
    thunkMiddleware
  )
  // other store enhancers if any
);
const store = createStore(
  combineReducers(
    reducers.persistable,
    reducers.forgettable
  ), enhancer
);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Main />
      </Provider>
    );
  }
}

export default App;
