import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Reset, ChangeMode} from "../../actions/clients";
import {ChangeMenu} from "../../actions/menu";
import ClientInfo from "./ClientInfo";
import EditClient from "./EditClient";
import AddEvent from "./AddEvent";
import AllEvents from "./AllEvents";
import {Segment} from 'semantic-ui-react';

class Home extends Component {
  
  
  componentWillUnmount(){
    this.props.dispatch(Reset());
  }
  
  componentDidMount(){
    var menuItems = [
      {
        icon:"info",
        active:1,
        content:"Info",
        click:()=>{
          this.props.dispatch(ChangeMode(1))
        }
      },
      {
        icon:"edit",
        active:2,
        content:"Edit",
        click:()=>{
          this.props.dispatch(ChangeMode(2))
        }
      },
      {
        icon:"add",
        active:3,
        content:"New Item",
        click:()=>{
          this.props.dispatch(ChangeMode(3))
        }
      }
      /*{
        sub:{
          icon:"add",
          text:"New",
          items:[
            {
              icon:"add",
              active:3,
              content:"Survey",
              click:()=>{
                this.props.dispatch(ChangeMode(3))
              }
            },
            {
              icon:"add",
              active:4,
              content:"Prize",
              click:()=>{
                this.props.dispatch(ChangeMode(4))
              }
            }
          ]
        } 
      }*/
    ];
    this.props.dispatch(ChangeMenu(menuItems, "clients"));
  }
  
  render() {
    let comp = null;
    
    
    switch(this.props.mode){
      
      case 1:
        comp = <ClientInfo />;
        break;
      case 2:
        comp = <EditClient />;
        break;
      case 3:
        comp = <AddEvent />;
        break;
      default:
        break;
    }
    
    return (
      <Segment padded className="ctrlSeg">
        {comp}
      </Segment>
    )
  }
}

function mapStateToProps(state) {
  return {
    load: state.Clients.load,
    mode: state.Clients.mode
  };
}

export default connect(mapStateToProps)(Home);