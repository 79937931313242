import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Reset} from "../../actions/events";
import {ChangePage} from "../../actions/admin";
import {ChangeMode} from "../../actions/surveys";
import {ChangeMenu} from "../../actions/menu";

import SurveyInfo from "./SurveyInfo";
import EditSurvey from "./EditSurvey";
import AddQuestion from "./AddQuestion";
import EditQuestion from "./EditQuestion";
import Statistics from "./Statistics";

import {Segment} from 'semantic-ui-react';

class Home extends Component {
  
  componentDidMount(){
    if(this.props.ea){
      return false;
    }
    var menuItems = [
      {
        icon:"arrow left",
        active:-1,
        content:"Back",
        click:()=>{
          this.props.dispatch(ChangePage(3))
        }
      },
      {
        icon:"info",
        active:1,
        content:"Survey",
        click:()=>{
          this.props.dispatch(ChangeMode(1))
        }
      },
      {
        icon:"edit",
        active:2,
        content:"Edit",
        click:()=>{
          this.props.dispatch(ChangeMode(2))
        }
      },
      {
        icon:"add",
        active:3,
        content:"Questions",
        click:()=>{
          this.props.dispatch(ChangeMode(3))
        }
      },
      {
        icon:"bar chart",
        active:4,
        content:"Statistics",
        click:()=>{
          this.props.dispatch(ChangeMode(5))
        }
      }
      /*{
        sub:{
          icon:"add",
          text:"New",
          items:[
            {
              icon:"add",
              active:3,
              content:"Survey",
              click:()=>{
                this.props.dispatch(ChangeMode(3))
              }
            },
            {
              icon:"add",
              active:4,
              content:"Prize",
              click:()=>{
                this.props.dispatch(ChangeMode(4))
              }
            }
          ]
        } 
      }*/
    ];
    this.props.dispatch(ChangeMenu(menuItems, "surveys"));
  }
  
  componentWillUnmount(){
    this.props.dispatch(Reset());
  }
  
  render() {
    let comp = null;
    
    switch(this.props.mode){
      
      case 1:
        comp = <SurveyInfo />;
        break;
      case 2:
        comp = <EditSurvey />;
        break;
      case 3:
        comp = <AddQuestion />;
        break;
      case 4:
        comp = <EditQuestion />
        break;
      case 5:
        comp = <Statistics />
        break;
      default:
        break;
    }
    
    return (
      <Segment padded className="ctrlSeg modalCont">
        {comp}
      </Segment>
    )
  }
}

function mapStateToProps(state) {
  return {
    load: state.Surveys.load,
    mode: state.Surveys.mode
  };
}

export default connect(mapStateToProps)(Home);