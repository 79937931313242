import React, { Component } from 'react';
import {connect} from 'react-redux';
import {ReadUsers, ReadReceives, FinishReadMessages, ReadReceivesEvents, InsertGroupUsers, DeleteGroupUsers, ReadGroups} from "../../actions/events";
import {UpdateUsersEvents} from "../../actions/customers";
import {ChangeSMSMode} from "../../actions/sms";
import {Message, Icon, Label, Form, Divider, Button, Comment, Checkbox, Dropdown} from 'semantic-ui-react'

import {exportCSVFile, months, days} from '../../func/csv';

import AllBlasts from './AllBlasts';

const GroupDropdown = (props) => {
  var groups = props.groups;
  
  groups = groups.map((obj)=>{
    return {
      key:obj.id,
      text:obj.title,
      value:obj.id
    }
  });
  return (
    <Dropdown
      button
      className='icon'
      floating
      labeled
      icon='add'
      options={groups}
      color='yellow'
      text='Add to Group'
      onChange={(ev, data)=>{props.JoinGroup(data.value)}}
    />
  )
}

const MessageCard = (props) =>{
  if(!props.msgs){
    return null;
  }
  
  var msgs = props.msgs.map((obj, i)=>{
    var date = new Date(obj.time).toLocaleString("en-US");
    if("sms_receive_id" in obj){
      return (
        <Comment key={i} className="msgs msgsOut">
          <Comment.Content>
            {obj.message}<span className="dateDisp"> - {date}</span>
          </Comment.Content>
        </Comment>
      )
    } else {
      return (
        <Comment className={obj.message.length>0 && obj.message[0] === "#" ? "msgs msgsInTags" :"msgs msgsIn"} key={i}>
          <Comment.Content>
            {obj.message}<span className="dateDisp"> - {date}</span>
          </Comment.Content>
        </Comment>
      )
    }
  });
  
  return msgs;
}

const FeedbackCard = (props)=>{
  var msgsArr = props.obj,
      date = null,
      msg = null;
  
  if((msgsArr && msgsArr.length>0)){
    date = new Date(msgsArr[0].time).toLocaleString("en-US");
    msg =  msgsArr[0].message;
  }
  
  /*
      date = new Date(obj.time).toLocaleString("en-US", {timeZone: "Canada/Pacific"}),
      receives = null,
      color = (obj.blasts === -1) ? "teal" : "blue";
  */
  
  //double loop to go through all the messages
  
  //{msg}
  var active = (props.active)?"active":null;
  return (
    <div className="nums" onClick={props.viewMsgs}>
      <div className={"numsContact "+active}>
        {props.mobile}
        {(props.fullname)?" - "+props.fullname:""}
      </div>
      {(msg) ?
        <div className={"numsLastMsg "+active}>
          {msg}<span className="dateDisp"> - {date}</span>
        </div>
        :null }
    </div>
  )
}

class AllFeedback extends Component {
  constructor(props){
    super(props);
    var obj = {
      sfilter:null,
      efilter:null,
      all:false,
      tagcode:{},
      word:"",
      curNum:"",
      curId:null,
      autosync:false,
      slicemax:10,
      hasalltags:false,
      filtergroups:[],
      showFilter:false,
      showUser:false,
      showBlasts:false,
      hideSMSTags:false,
      curUser:{
        fullname:"",
        email:"",
        notes:"",
        firsttime:true
      }
    }
    this.state = obj;
    
    this.wordTimer = null;
    if(this.props.filter && this.props.receives.length>0){
      //
      var arr = this.props.receives.filter((obj, i)=>{
        return obj.type !== 2;
      });
      if(arr && arr.length > 0){
        let sdate = new Date(arr[0].time),
            edate = new Date();
        sdate.setDate(sdate.getDate() - 5);
        sdate.setHours(0,0,0);
        edate.setHours(23,59,59);
        obj.sfilter = sdate;
        obj.efilter = edate;
        this.state = obj;
      }
      
    }
  }
  
  componentWillMount=async()=>{
    if(this.props.nofetch && this.props.nofetch === true){
      return false;
    }
    
    await this.readMessages();
    if(Array.isArray(this.props.groups) && this.props.groups.length <= 0){this.props.dispatch(ReadGroups(this.props.events_id));}
  }
  
  readMessages=async ()=>{
    await this.props.dispatch(ReadUsers(this.props.events_id, false, true));
    await this.props.dispatch(ReadReceivesEvents(this.props.events_id));
  }
  //find first message that isn't a sub message
  findFirstMessage=(arr)=>{
    if(!arr){
      return false;
    }
    var first=null;
    for(var i=0; i<arr.length; i++){
      if(arr[i].sms_receive_id === -1){
        first = arr[i];
        break;
      }
    }
   
    return first;
  }
  
  handleDate=(i, evt, data)=>{
    //
    var sdate = (this.state.sfilter) ? this.state.sfilter : new Date(),
        edate = (this.state.efilter) ? this.state.efilter : new Date();
    if(i===1){
      sdate = new Date(data.value);
      sdate.setTime(sdate.getTime() + sdate.getTimezoneOffset()*60*1000);
    } else {
      edate = new Date(data.value);
      edate.setTime(edate.getTime() + edate.getTimezoneOffset()*60*1000);
    }
    
    sdate.setHours(0,0,0);
    edate.setHours(23,59,59);
    this.setState({
      sfilter:sdate,
      efilter:edate,
      all:false
    })
  }
  
  handleInfo=(code, obj)=>{
    if(this.state.tagcode[code]){
      delete this.state.tagcode[code];
      this.setState({
        tagcode:this.state.tagcode
      });
    } else {
      var sdate = new Date(obj[code][0].time);
      sdate.setHours(0,0,0);
      this.state.tagcode[code] = true;
      this.setState({
        tagcode:this.state.tagcode,
        sfilter:sdate
      })
    }
    
  }
  
  startSMS=(num)=>{
    //
    //console.log(num, this.props.usersByMobile);
    var uid = this.props.usersByMobile[num].uid;
    //console.log(uid);
    //console.log(obj, uid);
    this.props.dispatch(ChangeSMSMode({
      mode:2,
      type:4,
      users_id:[uid],
      mobiles:[num],
      blasts:0,
      events_id:this.props.events_id,
      sms_receive_id:this.state.curId,
      from:this.props.mobile,
      callback:this.readMessages
    }));
  }
  
  view=(obj)=>{
    //this.props.dispatch(ReadSurvey(obj));
    //this.props.dispatch(ChangePage(4));
  }
  
  exportCSV=(arr)=>{
    var headers = {
      number: 'Phone Numbers'.replace(/,/g, ''), // remove commas to avoid errors
      fullname: "Full Name",
      email: "Email",
      time: "Message Time",
      message: "Message",
      year: "Year",
      month: "Month",
      date: "Date",
      day: "Day",
      hours: "Hours",
      minutes: "Minutes",
      seconds: "Seconds"
    };
    
    //console.log(arr);
    var items = arr.map((obj,i)=>{
      var dobj = new Date(obj.time),
          fullname = (this.props.usersByMobile[obj.mobile] && this.props.usersByMobile[obj.mobile].fullname) ? this.props.usersByMobile[obj.mobile].fullname : "",
          email = (this.props.usersByMobile[obj.mobile] && this.props.usersByMobile[obj.mobile].email) ? this.props.usersByMobile[obj.mobile].email : "",
          time = dobj.toLocaleString("en-US").replace(/,/g, ''),
          message = '"'+obj.message+'"',
          year = dobj.getFullYear(),
          month = months[dobj.getMonth()],
          date = dobj.getDate(),
          day = days[dobj.getDay()],
          hours = dobj.getHours(),
          minutes = dobj.getMinutes(),
          seconds = dobj.getSeconds();
      
      return {
        number:obj.mobile.replace(/,/g, ''),
        fullname,
        email,
        time,
        message,
        year,
        month,
        date,
        day,
        hours,
        minutes,
        seconds
      }
    });

    var fileTitle = 'feedbacks_'+this.state.sfilter+"_to_"+this.state.efilter; // or 'my-unique-title'

    exportCSVFile(headers, items, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }
  
  syncFeedback=(ev, data)=>{
    this.setState({
      autosync:data.checked
    });
    
    if(data.checked){
      this.sync = setInterval(()=>{
        this.readMessages();
      }, 5000)
    } else {
      if(this.sync){
        clearInterval(this.sync);
        this.sync = null;
      }
    }
  }
  
  viewMsgs=(i, id)=>{
    this.setState({curNum:i, curId:id});
    if(this.props.usersByMobile[i]){
      this.setState({
        curUser:{
          fullname:this.props.usersByMobile[i].fullname,
          email:this.props.usersByMobile[i].email,
          notes:this.props.usersByMobile[i].notes
        }
      })
    }
    if(this.fm){
      this.fm.scrollTop = 0;
    }
  }
  
  sortByTime=(a,b)=>{
    var dateA = new Date(a.time);
    var dateB = new Date(b.time);
    return ((dateA > dateB) ? -1 : ((dateA < dateB) ? 1 : 0));
  }
  
  handleJoinGroup=(num, gid)=>{
    //console.log(obj);
    if(!this.props.groupusers.byusers[this.props.usersByMobile[num].id] || this.props.groupusers.byusers[this.props.usersByMobile[num].id].indexOf(gid) === -1){
      this.props.dispatch(InsertGroupUsers({
        users_events_id:this.props.usersByMobile[num].id,
        events_group_id:gid
      },(data)=>{
        this.props.dispatch(ReadGroups(this.props.events_id));
      }))
    }
  }
  
  handleRemoveGroupUser=(num, gid)=>{
    this.props.dispatch(DeleteGroupUsers({
      users_events_id:this.props.usersByMobile[num].id,
      events_group_id:gid
    },(data)=>{
      this.props.dispatch(ReadGroups(this.props.events_id));
    }))
  }
  
  handleFilterGroup=(gid)=>{
    if(this.state.filtergroups.indexOf(gid) === -1){
      this.state.filtergroups.push(gid);
      this.setState({
        filtergroups:this.state.filtergroups
      })
    } else {
      this.state.filtergroups.splice(this.state.filtergroups.indexOf(gid), 1)
      this.setState({
        filtergroups:this.state.filtergroups
      })
    }
  }
  
  handleEditObj=(prop, ev, data)=>{
    if(!this.edit){
      this.edit = {};
    }
    this.edit[prop] = data.value;
    this.state.curUser[prop] = data.value;
    this.setState({
       curUser:this.state.curUser
    });
  }
  
  handleUserUpdate=(num)=>{
    this.props.dispatch(UpdateUsersEvents({
      id:this.props.usersByMobile[num].id,
      fullname:(this.edit.fullname || this.edit.fullname === "") ? this.edit.fullname : null,
      email:(this.edit.email || this.edit.email === "") ? this.edit.email : null,
      notes:(this.edit.notes || this.edit.notes === "") ? this.edit.notes : null
    }, ()=>{
      this.props.dispatch(ReadUsers(this.props.events_id));
      this.edit = null;
    }));
  }
  
  
  render() {
    //
    let arr = this.props.receives;
    //console.log(arr);
    var sdate = null,
        edate = null;
    
    let AllFeedback = [],
        MsgBox = null;
    //
    
    if(this.props.filter && arr.length > 0){
      let csdate = this.state.sfilter,
          cedate = this.state.efilter;
      
      var tagobj = {},
          tagarr = [],
          tagmobile = {},
          normarr = [],
          loadmore = false,
          AllGroups = [],
          UserGroups = [],
          AllTags = [];
      
      //order of filter, first by number, then by date, then by groups within the date, and everything else
      if(this.state.word !== ""){
        arr = arr.filter((obj)=>{
          //console.log(obj);
          if(isNaN(this.state.word[0])){
            return (this.props.usersByMobile[obj.mobile] && this.props.usersByMobile[obj.mobile].fullname.indexOf(this.state.word) !== -1);
          } else {
            return (obj.mobile.indexOf(this.state.word) !== -1);
          }
        });
      }
      
      //console.log(arr, this.state.word);
      if(arr.length > 0){
        if(this.state.all || this.state.word !== ""){
          //console.log(arr[arr.length-1].time);
          csdate = new Date(arr[arr.length-1].time);
          cedate = new Date();
          csdate.setHours(0,0,0);
          cedate.setHours(23,59,59);
        } else if(!csdate || !cedate){
          csdate = new Date(arr[0].time);
          cedate = new Date();
          csdate.setHours(0,0,0);
          csdate.setDate(csdate.getDate() - 5);
          cedate.setHours(23,59,59);
        }

        //sdate = csdate.toLocaleDateString("en-US", {timeZone: "Canada/Pacific", year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
        //edate = cedate.toLocaleDateString("en-US", {timeZone: "Canada/Pacific", year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
        sdate = csdate.toLocaleDateString("en-US", {year:"numeric", month:"2-digit", day:"2-digit"}).split("/");
        edate = cedate.toLocaleDateString("en-US", {year:"numeric", month:"2-digit", day:"2-digit"}).split("/");

        //sdate = new Date(sdate).toString("yyyy-MM-dd");
        sdate = sdate[2]+"-"+sdate[0]+"-"+sdate[1];
        edate = edate[2]+"-"+edate[0]+"-"+edate[1];
        //
        
        arr = arr.filter((obj)=>{
          var date = new Date(obj.time);
          return (date >= csdate && date <= cedate);
        });
        
        if(this.state.filtergroups.length > 0){
          arr = arr.filter((obj)=>{
            return (this.props.usersByMobile[obj.mobile] && this.props.groupusers.byusers[this.props.usersByMobile[obj.mobile].id] && this.state.filtergroups.some(r=> this.props.groupusers.byusers[this.props.usersByMobile[obj.mobile].id].indexOf(r) !== -1));
          });
        }
        
        for(var i=0; i<arr.length; i++){
          var robj = arr[i];
          if(robj.type === 2){
            var tag = robj.message.split(" ");
            if(this.state.tagcode[tag[0]]){
              tagarr.push(robj);
              if(!tagmobile[robj.mobile]){
                tagmobile[robj.mobile]={};
              }
              if(!tagmobile[robj.mobile][tag[0]]){
                tagmobile[robj.mobile][tag[0]] = true;
              }
            }
            if(!tagobj[tag[0]]){
              tagobj[tag[0]] = [];
            }

            tagobj[tag[0]].push({
              mobile:robj.mobile,
              msg:robj.message,
              time:new Date(robj.time).toLocaleString("en-US")
            })
          } else {
            normarr.push(robj);
          }
        }

        if(tagarr.length > 0){
          arr = tagarr;
        }
        
        if(this.state.hideSMSTags){
          arr = normarr;
        }

        for(var i in tagobj){
          AllTags.push(<Label className="boomTags" style={{cursor:"pointer"}} color={(this.state.tagcode[i]) ? "yellow" : "grey"} onClick={this.handleInfo.bind(this, i, tagobj)} key={i}>{i} ({tagobj[i].length})</Label>)
        }
        
        if(this.props.limit){
          arr = arr.slice(0, this.props.limit);
        }

        var resps = {},
            mmsgs = {};
        //console.log(this.props.sends, this.props.receives);
        /*for(var i = 0; i<this.props.sends.length; i++){
          var tobj = this.props.sends[i];
          if(tobj.sms_receive_id !== null){
            if(!resps[tobj.sms_receive_id]){
              resps[tobj.sms_receive_id] = [];
            }
            resps[tobj.sms_receive_id].push(tobj);
          }
        }*/
        //console.log(this.state.tagcode, Object.keys(this.state.tagcode).length, tagmobile);
        //console.log(arr);
        var order = 1;
        for(var i = 0; i<arr.length; i++){
          var tobj = arr[i];
          
          if(this.state.hasalltags){
            if(tagmobile[tobj.mobile] && Object.keys(this.state.tagcode).length === Object.keys(tagmobile[tobj.mobile]).length){
              if(!mmsgs[tobj.mobile]){
                mmsgs[tobj.mobile] = [];
              }
              mmsgs[tobj.mobile].push(tobj);
            }
          } else {
            //console.log(mmsgs);
            if(!mmsgs[tobj.mobile]){
              mmsgs[tobj.mobile] = [];
            }
            mmsgs[tobj.mobile].push(tobj);
          }
        }

        var firstnum = null;
        //mnum is the number & id used in the render
        var mnum = null;
        //console.log(mmsgs);
        var singleFeedbackCard = {};
        for(var i = 0; i<arr.length; i++){
          var obj = arr[i];
          if(!singleFeedbackCard[obj.mobile]){
            singleFeedbackCard[obj.mobile] = true;
            var fullname=""
            if(this.state.curNum === "" && firstnum === null){
              firstnum = obj.mobile;
              if(this.props.usersByMobile[firstnum] && this.state.curUser.firsttime){
                this.state.curUser = {
                  fullname:this.props.usersByMobile[firstnum].fullname,
                  email:this.props.usersByMobile[firstnum].email,
                  notes:this.props.usersByMobile[firstnum].notes
                }
              }
            }
            mnum = (this.state.curNum) ? this.state.curNum : firstnum;
            if(this.props.usersByMobile[obj.mobile]){
              fullname = this.props.usersByMobile[obj.mobile].fullname;
            }
            AllFeedback.push(<FeedbackCard 
                               key={"feed"+obj.mobile} 
                               obj={mmsgs[obj.mobile]}
                               active={(obj.mobile===mnum)}
                               mobile={obj.mobile}
                               fullname={fullname}
                               viewMsgs={this.viewMsgs.bind(this, obj.mobile, mmsgs[obj.mobile][0].id)}
                                />)
          }
        }
        /*
        for(var i in mmsgs){
          if(this.state.curNum === "" && firstnum === null){
            firstnum = i;
            if(this.props.usersByMobile[i]){
              this.state.curUser = {
                fullname:this.props.usersByMobile[i].fullname,
                email:this.props.usersByMobile[i].email,
                notes:this.props.usersByMobile[i].notes
              }
            }
          }
          mnum = (this.state.curNum) ? this.state.curNum : firstnum;
          if(this.props.usersByMobile[i]){
            fullname = this.props.usersByMobile[i].fullname;
          }
          AllFeedback.push(<FeedbackCard 
                             key={"feed"+i} 
                             obj={mmsgs[i]}
                             active={(i===mnum)}
                             resps={resps} 
                             mobile={i}
                             fullname={fullname}
                             viewMsgs={this.viewMsgs.bind(this, i, mmsgs[i].id)}
                              />)
        }
        */
        
        if(AllFeedback.length > 0 && (mnum && mmsgs[mnum])){
          resps = this.props.sends.filter((obj,i)=>{
            var date = new Date(obj.time);
            //var check = true;
            if(obj.type === 1){
              return (obj.mobiles_sent && obj.mobiles_sent.indexOf(mnum) !== -1 && (date >= csdate && date <= cedate));
            } else {
             return (obj.mobiles.indexOf(mnum) !== -1 && date >= csdate && date <= cedate);
            }
          });
          //console.log(resps);
          
          var msgs = mmsgs[mnum].concat(resps);
          //console.log(msgs);
          
          msgs = msgs.sort(this.sortByTime);
          
          if(msgs.length>this.state.slicemax){
            msgs = msgs.slice(0,this.state.slicemax);
            loadmore = true;
          }
          //console.log(msgs);
          MsgBox = <MessageCard msgs={msgs} />;
        }
        
        //console.log(this.props.usersByMobile[mnum], this.props.groupusers);
        var lgroups = [];
        var fgroups = this.props.groups.filter((obj,i)=>{
          AllGroups.push((
            <Label className="boomTags" color={(this.state.filtergroups.indexOf(obj.id) !== -1) ? "yellow" : "grey"} key={i} onClick={this.handleFilterGroup.bind(this, obj.id)}>
              {obj.title}
            </Label>
          ))
          
          if((mnum && this.props.usersByMobile[mnum] && this.props.groupusers.byusers[this.props.usersByMobile[mnum].id] && this.props.groupusers.byusers[this.props.usersByMobile[mnum].id].indexOf(obj.id) !== -1)){
            return true;
          } else {
            lgroups.push(obj);
            return false;
          }
        });
        
        UserGroups = fgroups.map((obj, i)=>{
          return (
            <Label title={obj.description || ""} className="boomTags" color="yellow" key={i}>
              {obj.title} <Icon name="close" onClick={this.handleRemoveGroupUser.bind(this, mnum, obj.id)} color="red"/>
            </Label>
          )
        });
      }
    }
    return (
      <div>
        <Form className="boomFilter">
          <Form.Group inline>
            <Label size="huge" pointing="right" color="blue">
              <Icon name='comments' /> {arr.length}
            </Label>
            <Button
              icon
              labelPosition="left"
              onClick={this.exportCSV.bind(this, arr)}
              >
              <Icon name="download" />
              CSV
            </Button>
            <Button
              style={{marginLeft:10}}
              icon
              color={(this.state.showFilter)?"violet":"grey"}
              onClick={()=>{this.setState({showFilter:!this.state.showFilter})}}
              >
              <Icon name="settings" />
            </Button>
            <Button
              style={{marginLeft:10}}
              icon
              color={(this.state.showBlasts)?"green":"grey"}
              onClick={()=>{this.setState({showBlasts:!this.state.showBlasts})}}
              >
              <Icon name="conversation" />
            </Button>
          </Form.Group>
          {(this.state.showFilter) ?
            <span>
              <Form.Group inline>
              {(sdate && edate) ? <Form.Input className="boomFilterInput" type="date" label="From" max={edate} onChange={this.handleDate.bind(this, 1)} value={sdate} /> : null}
              {(sdate && edate) ? <Form.Input className="boomFilterInput" type="date" label="To" min={sdate} onChange={this.handleDate.bind(this, 2)} value={edate} /> : null}
            </Form.Group>
            <Form.Group inline>
              <Form.Input className="boomFilterInput" placeholder="Filter Phone" onChange={(ev, data)=>{
                  if(this.wordTimer !== null){
                    clearTimeout(this.wordTimer);
                    this.wordTimer = null;
                  }

                  this.wordTimer = setTimeout(()=>{
                    this.setState({word:data.value})
                  }, 500);
                }} />
              <Checkbox
                className="boomFilterCheckbox"
                toggle
                label="All Feedback"
                checked={this.state.all}
                onChange={(ev,data) => {this.setState({all: data.checked})}}
              />
              <Checkbox
                className="boomFilterCheckbox"
                toggle
                label="Auto Sync"
                checked={this.state.autosync}
                onChange={this.syncFeedback}
              />

              <Checkbox
                className="boomFilterCheckbox"
                label="Has (x) tags"
                checked={this.state.hasalltags}
                onChange={()=>{
                  this.setState({hasalltags:!this.state.hasalltags});
                }}
              />
            </Form.Group>
          </span> : null}
          <Divider />
          <Form.Group inline>
            {AllTags}
          </Form.Group>
          <div>
            {AllGroups}
          </div>
        </Form>
        <Divider hidden />
        <div className="feedbackCont">
          <div className="feedbackNums">
            {AllFeedback}
          </div>
          <div className="feedbackMsgsCont">
            {(mnum && AllFeedback.length > 0) ? <div className="feedbackReply">
                <Button icon onClick={()=>{this.setState({showUser:!this.state.showUser})}} color={this.state.showUser ? "blue" : "grey"}>
                  <Icon name="info" style={{marginLeft:10, marginRight:0}} />
                </Button>
                {
                  (this.state.showUser) ? 
                    <Form style={{marginTop:10, width:"100%"}}>
                      <Form.Group inline>
                        <Form.Input label="Full Name" value={this.state.curUser["fullname"] || ""} placeholder="Bob Jones" onChange={this.handleEditObj.bind(this, "fullname")}/>
                        <Form.Input label="Email" value={this.state.curUser["email"] || ""} placeholder="bobjones@email.com" onChange={this.handleEditObj.bind(this, "email")}/>
                      </Form.Group>
                      <div>
                        <Form.TextArea label="Notes" value={this.state.curUser["notes"] || ""} placeholder="Notes for Bob Jones" onChange={this.handleEditObj.bind(this, "notes")}/>
                      </div>
                      <div style={{marginTop:10}}>
                        <Button onClick={this.handleUserUpdate.bind(this, mnum)}>Update</Button>
                      </div>
                      <Divider hidden />
                      <div className="feedbackGroups">
                        <GroupDropdown groups={lgroups} JoinGroup={this.handleJoinGroup.bind(this, mnum)}/>
                        {UserGroups}
                      </div>
                      <Divider />
                    </Form> : null}
                  <Button onClick={this.startSMS.bind(this, mnum)} color="blue">
                    Reply<Icon name="chat" style={{marginLeft:10, marginRight:0}} />
                  </Button>
                  <Checkbox style={{marginLeft:5}} label="Hide #tags" checked={this.state.hideSMSTags} onChange={(ev, {checked})=>{this.setState({hideSMSTags:checked})}}/>
              </div> : null}
            <div className="feedbackMsgs" ref={re=>{this.fm = re;}}>
              <i style={{alignSelf:"center"}}>...Latest message...</i>
              {MsgBox}
              {(loadmore) ? <Button className="loadmore" onClick={()=>{this.setState({slicemax:this.state.slicemax+10})}}>See More Messages</Button>:null}
            </div>
          </div>
        </div>
        {(this.props.ea && this.state.showBlasts) ? 
        <div className="boomModalCont">
          <div className="boomModalInfo">
            <div className="boomModalMain">
              <AllBlasts />
              <Icon name="close" inverted circular className="boomModalClose" size="large" onClick={()=>{this.setState({showBlasts:false})}}/>
            </div>
          </div>
        </div> : null}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    sends: state.Events.sends,
    receives: state.Events.receives,
    events_id: state.Events.id,
    usersByMobile:state.Events.usersByMobile,
    users:state.Events.users,
    mobile:state.Events.mobile,
    groups:state.Events.groups,
    groupusers:state.Events.groupusers
  };
}

export default connect(mapStateToProps)(AllFeedback);