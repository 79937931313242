import React, { Component } from 'react';
import {connect} from 'react-redux';
import {ReadPrizes, PrizeUser, UpdatePrizes} from "../../actions/events";
import {ReadPrize, UpdatePrize} from "../../actions/prizes";
import {ChangePage} from "../../actions/admin";
import {Card, Icon, Button, Checkbox, Label, Menu, Divider} from 'semantic-ui-react'
import {awsfetch} from "../../connect";
import {compress}from '../compress';

import EditPrize from '../Prize/EditPrize';

const PrizesCard = (props)=>{
  let obj = props.obj;
  let typecolors = ["grey", "teal", "yellow", "purple", "violet"];
  return (
    <Card>
      {(obj.img && obj.img === "") ? null : <div className="preview" style={{backgroundImage:"url("+obj.img+")"}}/>}
      <Card.Content>
        <Label attached="top left">
          <Checkbox toggle checked={obj.active === 1} onChange={props.activate}/>
        </Label>
        <input type="file" className="fileinput" id={"myfile"+obj.id} onChange={props.handleFile} />
        <Label attached="top right" color="grey">
          <label htmlFor={"myfile"+obj.id} className="fileinputlabel">
            <Icon name='camera' size="big" />
          </label>
        </Label>
        <Card.Description>
          <div style={{fontSize:10}}>
            {(obj.redeemable===0) ? <i>Not Redeemable via kiosk</i> : null}
            <h3 className="boomOpts">
              <Icon name='gift' />{obj.name}
            </h3>
            <Label color={typecolors[obj.type]}>
              {obj.typename}
            </Label>
            <Label color="orange">
              {obj.pts} PTS
            </Label>
            <div style={{marginTop:10}}></div>
            {(obj.odds)? <Label color="grey">
              1/{obj.odds}
            </Label> : null }
            {(obj.repeats)? <Label color="grey">
              <Icon className="sync" />{obj.repeats} Day
            </Label> : null }
            {(obj.maxwin)? <Label color="teal">
              {obj.maxwin} / Day
            </Label> : null }
          </div>
          <Divider hidden />
          {obj.win_text}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button compact icon onClick={props.onClick}>
          <Icon name='edit' />
        </Button>
        {(obj.active !== -1) ? 
          <Button compact floated="right" icon color="red" onClick={props.archive}>
            <Icon name='trash' />
          </Button>
          :
          <Button compact floated="right" icon color="green" onClick={props.activate}>
            <Icon name='redo' />
          </Button>
        }
      </Card.Content>
    </Card>
  )
}

class AllPrizes extends Component {
  
  state={
    view:1,
    showPrize:false
  }
  
  componentWillMount(){
    if(this.props.nofetch && this.props.nofetch === true){
      return false;
    }
    this.props.dispatch(ReadPrizes(this.props.events_id));
  }
  
  view=async (obj)=>{
    await this.props.dispatch(ReadPrize(obj));
    await this.props.dispatch(ChangePage(5));
    if(this.props.ea){
      this.setState({
        showPrize:true
      })
    }
  }
  
  winner=(obj)=>{
    this.props.dispatch(PrizeUser(obj));
  }
  
  activate=(obj, i, prizes, evt, val)=>{
    //return false;
    if(obj.active === -1){
      obj.active = 0;
    } else {
      if(val.checked){
        obj.active = 1;
      } else {
        obj.active = 0;
      }
    }
    
    this.props.dispatch(UpdatePrize(obj, ()=>{
      prizes[i] = obj;
      this.props.dispatch(UpdatePrizes(prizes));
    }));
  }
  
  archive=(obj, i, prizes, evt)=>{
    obj.active = -1;
    this.props.dispatch(UpdatePrize(obj, ()=>{
      prizes[i] = obj;
      this.props.dispatch(UpdatePrizes(prizes));
    }));
  }
  
  handleFile=(obj, i, prizes, evt)=>{
    //
    //console.log(obj, evt, data);
    this.files = evt.target.files;
    var reader = new FileReader();

    reader.onload = (e)=>{
      //$('#blah').attr('src', e.target.result);
      compress(e.target.result, this.files[0].name, async (dataurl, compfileobj, blob)=>{
        this.compfileobj = compfileobj;
        if(this.compfileobj){
          var cid = (this.props.cid)?"cli"+this.props.cid:"clientid";
          var data = {
            name:"clients/"+cid+"/events/ev"+this.props.events_id+"/prizes/pr"+obj.id+"/header",
            file:this.compfileobj,
            blob:blob
          }
          //console.log(data);
          var json = await awsfetch(data);
          if(json.location){
            obj.img = json.location;
            this.props.dispatch(UpdatePrize(obj, ()=>{
              prizes[i] = obj;
              this.props.dispatch(UpdatePrizes(prizes));
            }));
          } else {
            alert("Only Images Allowed!");
          }
        }
      });
      
    }
    reader.readAsDataURL(this.files[0]);
  }
  
  handleView=(num)=>{
    this.setState({
      view:num
    })
  }
  
  render() {
    //
    let tprizes = (this.props.prizes)?this.props.prizes:[];
    
    let AllPrizes = [];
    
    for(var i = 0; i<tprizes.length; i++){
      var obj = tprizes[i];
      if(this.state.view === 1 && obj.active !== -1){
        AllPrizes.push(<PrizesCard key={i} obj={obj} onClick={this.view.bind(this, obj)} activate={this.activate.bind(this, obj, i, tprizes)} handleFile={this.handleFile.bind(this, obj, i, tprizes)} archive={this.archive.bind(this, obj, i, tprizes)} />);
      } else if(this.state.view === 2 && obj.active === -1){
        AllPrizes.push(<PrizesCard key={i} obj={obj} onClick={this.view.bind(this, obj)} activate={this.activate.bind(this, obj, i, tprizes)} handleFile={this.handleFile.bind(this, obj, i, tprizes)} archive={this.archive.bind(this, obj, i, tprizes)} />);
      }
    }
    
    return (
      <div>
        <Menu attached='top' tabular>
          <Menu.Item 
            name='Active' 
            color="green"
            active={this.state.view===1} 
            onClick={this.handleView.bind(this, 1)}
          />
          <Menu.Item 
            active={this.state.view===2} 
            onClick={this.handleView.bind(this, 2)}
            name='Archived'
            icon={{name:"trash"}}
            color="red"
          />
        </Menu>
        <Divider hidden />
        <Card.Group itemsPerRow={4} stackable>{AllPrizes}</Card.Group>
        {(this.props.ea && this.state.showPrize) ? 
        <div className="boomModalCont">
            <div className="boomModalInfo">
              <div className="boomModalMain">
                <EditPrize ea={true} />
                <Icon name="close" inverted circular className="boomModalClose" size="large" onClick={()=>{this.setState({showPrize:false})}}/>
              </div>
            </div>
        </div> : null}
      </div>
      
    )
  }
}

function mapStateToProps(state) {
  return {
    prizes: state.Events.prizes,
    events_id: state.Events.id,
    cid: state.Clients.id
  };
}

export default connect(mapStateToProps)(AllPrizes);