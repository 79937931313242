import {reducers} from "../types";
//mode 0 = view, 1 = add
const initialState = {
  mode:1,
  id:null,
  load:false,
  name:"",
  img:"",
  active:0,
  win_text:"",
  description:"",
  redeemable:"",
  pts:0,
  type:0,
  odds:0,
  repeats:0,
  maxwin:0,
  typename:"",
  expire_time:"",
  prizes_users:[]
};


export default function prizes(state = initialState, action){
  let obj = Object.assign({}, state);
  switch(action.type) {
    case reducers.PRIZE_CHANGE_MODE:
      obj.mode = action.mode;
      return obj;
    case reducers.PRIZE_RESET:
      obj.mode = 1;
      return obj;
    case reducers.PRIZE_READ:
      //console.log(action.prize);
      obj.id = action.prize.id;
      obj.name = action.prize.name;
      obj.img = action.prize.img;
      obj.active = action.prize.active;
      obj.redeemable = action.prize.redeemable;
      obj.expire_time = action.prize.expire_time;
      obj.win_text = action.prize.win_text;
      obj.description = action.prize.description;
      obj.pts = action.prize.pts;
      obj.type = action.prize.type;
      switch(obj.type){
        case 1:
          obj.typename="Regular"
          break;
        case 2:
          obj.typename="One-Time"
          break;
        case 3:
          obj.typename="Chance"
          break;
        case 4:
          obj.typename="One-Time Chance"
          break;
      }
      obj.odds = action.prize.odds;
      obj.repeats = action.prize.repeats;
      obj.maxwin = action.prize.maxwin;
      return obj;
    case reducers.PRIZE_UPDATE:
      obj.load = action.load;
      obj.name = (action.prize.name) ? action.prize.name : obj.name;
      obj.img = (action.prize.img) ? action.prize.img : obj.img;
      obj.active = (action.prize.active) ? action.prize.active : obj.active;
      obj.redeemable = (action.prize.redeemable) ? action.prize.redeemable : obj.redeemable;
      obj.expire_time = (action.prize.expire_time) ? action.prize.expire_time : obj.expire_time;
      obj.win_text = (action.prize.win_text) ? action.prize.win_text : obj.win_text;
      obj.description = (action.prize.description) ? action.prize.description : obj.description;
      obj.pts = (action.prize.pts || action.prize.pts === 0) ? action.prize.pts : obj.pts;
      obj.type = (action.prize.type) ? action.prize.type : obj.type;
      switch(obj.type){
        case 1:
          obj.typename="Regular"
          break;
        case 2:
          obj.typename="One-Time"
          break;
        case 3:
          obj.typename="Chance"
          break;
        case 4:
          obj.typename="One-Time Chance"
          break;
      }
      obj.odds = (action.prize.odds || action.prize.odds === 0) ? action.prize.odds : obj.odds;
      obj.repeats = (action.prize.repeats || action.prize.repeats === 0) ? action.prize.repeats : obj.repeats;
      obj.maxwin = (action.prize.maxwin || action.prize.maxwin === 0) ? action.prize.maxwin : obj.maxwin;
      return obj;
    case reducers.LOAD_STORED:
      return action.storedState.Prizes;
    default:
      return state;
  }
}