import React, { Component } from 'react';
import {connect} from 'react-redux';
import {ReadEvents, InsertEvent} from "../../actions/clients";
import {ReadEvent, UpdateEvent} from "../../actions/events";
import {ChangePage} from "../../actions/admin";
import {Card, Icon, Button, Segment, Header, Label, Message, Form} from 'semantic-ui-react'

const EventsCard = (props)=>{
  let obj = props.obj,
      tobj = props.tobj,
      //date = new Date(obj.time).toLocaleString("en-US", {timeZone: "Canada/Pacific"}),
      item_name = (obj.type === 0)?"Event":"Shop";
  
  var mobUI = (props.isadmin) ? (
    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
        <Form>
          <Form.TextArea size="small" value={(tobj.mobile)?tobj.mobile:""} onChange={props.numChange} />
        </Form>
        <div>
          <Button fluid size="small" onClick={props.updateNumber} disabled={(tobj.isedit && tobj.isedit !== true)}>
            Update
          </Button>
        </div>
      </div>
  ) : null;
  
  return (
    <Card>
      <Card.Content>
        <Label attached="top left" color="blue">
          <Icon name="code" /><Label.Detail>{obj.hash}</Label.Detail>
        </Label>
        <Card.Header>
          {obj.name}
        </Card.Header>
        <Card.Meta>
          <Icon name="hashtag" /> {obj.slug}
        </Card.Meta>
        <Card.Description>
          {mobUI}
          <Message className="eventsDesc">
            <Message.Content>{obj.description}</Message.Content>
          </Message>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button icon labelPosition='left' onClick={props.onClick}>
          <Icon name='external' />
          View {item_name}
        </Button>
        <Button icon onClick={props.duplicate}>
          <Icon name='copy' />
        </Button>
      </Card.Content>
    </Card>
  )
}

class AllEvents extends Component {
  
  state={
    tmp_ev:this.props.events
  }
  
  componentWillReceiveProps(newProps) {
    if( newProps.events !== this.props.events ){
      this.setState({
        tmp_ev:newProps.events
      })
    } /* do stuff */
  }
  componentDidMount(){
    this.props.dispatch(ReadEvents(this.props.id));
  }
  
  view=(obj)=>{
    this.props.dispatch(ReadEvent(obj));
    this.props.dispatch(ChangePage(3));
  }
  
  duplicate=async (obj)=>{
    //delete obj.id;
    if(window.confirm("Are you sure you want to duplicate this event?")){
      var newev = {
        name:obj.name,
        slug:obj.slug,
        description:obj.description,
        start_time:obj.start_time,
        end_time:obj.end_time,
        cli_id:obj.cli_id,
        welcome:obj.welcome,
        type:obj.type,
        signin_pts:obj.signin_pts,
        employee_code:obj.employee_code,
        signin_msg:obj.signin_msg,
        default_msg:obj.default_msg,
        optin_msg:obj.optin_msg
      }
      await this.props.dispatch(InsertEvent(newev));
      await this.props.dispatch(ReadEvents(this.props.id));
    }
  }
  
  numChange=(ind, evt, data)=>{
    this.state.tmp_ev[ind].mobile = data.value;
    this.state.tmp_ev[ind].isedit = true;
    this.setState({
      tmp_ev:this.state.tmp_ev
    })
  }
  
  updateNumber=(ind)=>{
    if(this.state.tmp_ev[ind].isedit){
      this.props.dispatch(UpdateEvent(this.state.tmp_ev[ind]));
    }
  }
  
  render() {
    let allEvents = [];
    
    let allShops = [];
    
    let events = (this.props.events) ? this.props.events : [];
    
    for(var i=0;i<events.length; i++){
      var obj = events[i];
      if(obj.type === 0){
        allEvents.push(<EventsCard key={i} 
                         obj={obj}
                         tobj={(this.state.tmp_ev[i])?this.state.tmp_ev[i]:{}}
                         isadmin={this.props.isadmin}
                         numChange={this.numChange.bind(this, i)}
                         updateNumber={this.updateNumber.bind(this,i)}
                         onClick={this.view.bind(this, obj)}
                         duplicate={this.duplicate.bind(this, obj)}
                         />
                      );
      } else {
        allShops.push(<EventsCard key={i} 
                        obj={obj}
                        tobj={(this.state.tmp_ev[i])?this.state.tmp_ev[i]:{}}
                        isadmin={this.props.isadmin}
                        numChange={this.numChange.bind(this,i)}
                        updateNumber={this.updateNumber.bind(this,i)}
                        onClick={this.view.bind(this, obj)} 
                        duplicate={this.duplicate.bind(this, obj)}
                        />
                     );
      }
        
    }
    
    return (
      <div>
        <Segment>
          <Header as='h3'>Items</Header>
          <Card.Group itemsPerRow={4} stackable>{allShops}</Card.Group>
        </Segment>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    events: state.Clients.events,
    isadmin:state.Dashboard.isadmin,
    id:state.Clients.id,
    token:state.Clients.token
  };
}

export default connect(mapStateToProps)(AllEvents);