import {reducers} from "../types";
//mode 0 = view, 1 = add
const initialState = {
  mode:1,
  id:null,
  title:"",
  description:"",
  img:null,
  questions:[],
  options:[],
  curquestion:{},
  usersoptions:[],
  popup:false,
  reveal:false
};


export default function surveys(state = initialState, action){
  let obj = Object.assign({}, state);
  switch(action.type) {
    case reducers.SURVEY_CHANGE_MODE:
      obj.mode = action.mode;
      return obj;
    case reducers.SURVEY_READ:
      obj.mode = 1;
      obj.id = action.survey.id;
      obj.title = action.survey.title;
      obj.img = action.survey.img;
      obj.popup = action.survey.popup;
      obj.reveal = action.survey.reveal;
      obj.description = action.survey.description;
      return obj;
    case reducers.SURVEY_UPDATE:
      obj.title = action.survey.title;
      obj.img = (action.survey.img) ? action.survey.img : obj.img;
      obj.popup = action.survey.popup;
      obj.reveal = action.survey.reveal;
      obj.description = action.survey.description;
      return obj;
    case reducers.SURVEY_QUESTIONS_READ:
      obj.questions = action.questions;
      obj.options = action.options;
      return obj;
    case reducers.SURVEY_CUR_QUESTION:
      obj.curquestion = action.question;
      obj.mode = 4;
      return obj
    case reducers.SURVEY_RESET:
      obj.mode = 1;
      return obj;
    case reducers.SURVEY_USERS_OPTIONS_READ:
      obj.usersoptions = action.usersoptions;
      return obj;
    case reducers.LOAD_STORED:
      return action.storedState.Surveys;
    default:
      return state;
  }
}