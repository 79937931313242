import {mfetch} from '../connect';
import {ReadClient} from './clients';
import {FinishRead} from './dashboard';
import {ReadEventsAdmin} from './eventsadmin';
import {clients, reducers, ChangeToken} from '../types';

export function ChangePage(page){
  return {
    type:reducers.ADMIN_CHANGE_PAGE,
    page
  }
}

function ChangeMode(mode){
  return {
    type:reducers.ADMIN_CHANGE_MODE,
    mode
  }
}

export function Loading(load){
  return {
    type:reducers.LOADING,
    load:load
  }
}

export function ClientLogin(email, password){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.AUTH_PATH, data:{key:"clients", email:email, password:password}});
    if(json.status && json.token){
      //console.log(json);
      ChangeToken(json.token);
      dispatch(ChangeMode(2));
      if(json.admin && json.admin === 2){
        //console.log(json);
        dispatch(FinishRead(json.data, true));
        dispatch(ChangePage(1));
      } else {
        dispatch(ReadClient(json.data[0]));
        dispatch(ChangePage(2));
      }
    } else {
      alert("Incorrect Credentials");
    }
    dispatch(Loading(false));
  }
}

export function EventsAdminLogin(username, password){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.AUTH_PATH, data:{key:"events_admin", username:username, password:password}});
    if(json.status && json.data && json.data.length > 0){
      dispatch(ChangeMode(3));
      dispatch(ReadEventsAdmin(json.data[0]));
    } else {
      alert("Incorrect Credentials");
    }
    /*if(json.status && json.token){
      //console.log(json);
      ChangeToken(json.token);
      if(json.admin && json.admin === 2){
        //console.log(json);
        dispatch(FinishRead(json.data, true));
        dispatch(ChangePage(1));
      } else {
        dispatch(ReadClient(json.data[0]));
        dispatch(ChangePage(2));
      }
    }*/
    dispatch(Loading(false));
  }
}

/*
export function CheckSession(callback){
  return (dispatch)=>{
    return mfetch({param:'read/test', data:{test:true, sesstest:true}}, (json)=>{
      if(typeof callback === "function"){
        callback();
      }
    })
  }
}
*/