import React, { Component } from 'react';
import {connect} from 'react-redux';
import {ChangeMode} from "../../actions/prizes";
import {Card, Icon, Button, Divider, Label, Message} from 'semantic-ui-react'

class PrizeInfo extends Component {
  
  edit=()=>{
    this.props.dispatch(ChangeMode(2));
  }
  
  render() {
    let typecolors = ["grey", "teal", "yellow", "purple", "violet"];
    return (
      <div>
        <Card fluid>
          <Card.Content>
            <Card.Header>
              {this.props.name}
            </Card.Header>
            <Card.Description>
              {(this.props.redeemable===0) ? <i>Not Redeemable via kiosk</i> : null}
              {(this.props.img && this.props.img === "") ? null : <div className="preview" style={{backgroundImage:"url("+this.props.img+")", maxWidth:"250px"}}/>}
              <Divider hidden />
              <Label color={typecolors[this.props.type]}>
                {this.props.typename}
              </Label>
              <Label color="orange">
                {this.props.pts} PTS
              </Label>
              <div style={{marginTop:10}}></div>
              {(this.props.odds)? <Label color="grey">
                1/{this.props.odds} Chance
              </Label> : null }
              
              {(this.props.repeats) ? <Label color="grey">
                <Icon name="sync" /> {this.props.repeats} Day
              </Label> : null }
              
              {(this.props.maxwin) ? <Label color="teal">
                {this.props.maxwin} / Day
              </Label> : null }
              
              <Divider hidden />
              {this.props.win_text}
              <Divider hidden />
              {(this.props.description) ? <Message>
                {this.props.description}
              </Message> : null }
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button icon labelPosition='left' onClick={this.edit}>
              <Icon name='edit' />
              Edit Reward
            </Button>
          </Card.Content>
        </Card>
      </div>
    )
  }
}

function mapStateToProps(state) {
  
  return {
    id:state.Prizes.id,
    name:state.Prizes.name,
    img:state.Prizes.img,
    win_text:state.Prizes.win_text,
    description:state.Prizes.description,
    redeemable:state.Prizes.redeemable,
    pts:state.Prizes.pts,
    type:state.Prizes.type,
    odds:state.Prizes.odds,
    repeats:state.Prizes.repeats,
    maxwin:state.Prizes.maxwin,
    typename:state.Prizes.typename
  };
}

export default connect(mapStateToProps)(PrizeInfo);