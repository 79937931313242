import React, { Component, useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {ReadSurveys, UpdateSurveys} from "../../actions/events";
import {ReadSurvey, UpdateSurvey, ReadQuestions, ReadUsersOptions} from "../../actions/surveys";
import {ChangePage} from "../../actions/admin";
import {Card, Icon, Label, Button, Checkbox, Menu, Divider} from 'semantic-ui-react'
import Statistics from '../Survey/Statistics';
import SurveyHome from '../Survey/Home';


const SurveysCard = (props)=>{
  let obj = props.obj;
      //date = new Date(obj.time).toLocaleString("en-US", {timeZone: "Canada/Pacific"});
  
  return (
    <Card>
      {obj.popup === 1 ? <Label color="yellow" corner="right" size="small"></Label> : null}
      {(obj.img && obj.img !== "") ? <div className="preview" style={{backgroundImage:"url("+obj.img+")"}}/> : null}
      <Card.Content>
        <Label attached="top left">
          <Checkbox toggle checked={obj.active === 1} onChange={props.activate}/>
        </Label>
        <Card.Header>
          {obj.title}
        </Card.Header>
        <Card.Description>
          {obj.description}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button compact icon onClick={()=>{props.onClick(obj, "survey")}}>
          <Icon name='info' />
        </Button>
        <Button compact icon onClick={()=>{props.onClick(obj, "stats")}} color="blue">
          <Icon className='bar graph' />
        </Button>
        {(obj.active !== -1) ? 
          <Button compact floated="right" icon color="red" onClick={props.archive}>
            <Icon name='trash' />
          </Button>
          :
          <Button compact floated="right" icon color="green" onClick={props.activate}>
            <Icon className='redo' />
          </Button>
        }
      </Card.Content>
    </Card>
  )
}

class AllSurveys extends Component {
  
  state={
    view:1,
    showSurvey:false,
    showStats:false
  }

  componentWillMount(){
    if(this.props.nofetch && this.props.nofetch === true){
      return false;
    }
    this.props.dispatch(ReadSurveys(this.props.events_id));
  }
  
  view=async (obj, type)=>{
    if(this.props.ea){
      await this.props.dispatch(ReadUsersOptions(obj.id));
      await this.props.dispatch(ReadQuestions(obj.id));
      await this.props.dispatch(ReadSurvey(obj));
      if(type==="survey"){
        this.setState({
          showSurvey:true
        })
      }
      
      if(type==="stats"){
        this.setState({
          showStats:true
        })
      }
      
    } else {
      this.props.dispatch(ReadSurvey(obj));
      this.props.dispatch(ChangePage(4));
    }
    
  }
  
  activate=(obj, i, surveys, evt, val)=>{
    //return false;
    if(obj.active === -1){
      obj.active = 0;
    } else {
      if(val.checked){
        obj.active = 1;
      } else {
        obj.active = 0;
      }
    }
    this.props.dispatch(UpdateSurvey(obj, ()=>{
      surveys[i] = obj;
      this.props.dispatch(UpdateSurveys(surveys));
    }));
  }
  
  archive=(obj, i, surveys, evt)=>{
    obj.active = -1;
    this.props.dispatch(UpdateSurvey(obj, ()=>{
      surveys[i] = obj;
      this.props.dispatch(UpdateSurveys(surveys));
    }));
  }
  
  handleView=(num)=>{
    this.setState({
      view:num
    })
  }
  
  render() {
    
    let tsurveys = (this.props.surveys) ? this.props.surveys : [];
    
    let AllSurveys = [];
    
    for(var i = 0; i<tsurveys.length; i++){
      var obj = tsurveys[i];
      if(this.state.view === 1 && obj.active !== -1){
        AllSurveys.push(<SurveysCard key={i} obj={obj} onClick={this.view} activate={this.activate.bind(this, obj, i, tsurveys)} archive={this.archive.bind(this, obj, i, tsurveys)} />);
      } else if(this.state.view === 2 && obj.active === -1){
        AllSurveys.push(<SurveysCard key={i} obj={obj} onClick={this.view} activate={this.activate.bind(this, obj, i, tsurveys)} archive={this.archive.bind(this, obj, i, tsurveys)} />);
      }
    }
    
    return (
      <div>
        <Menu attached='top' tabular>
          <Menu.Item 
            name='Active' 
            color="green"
            active={this.state.view===1} 
            onClick={this.handleView.bind(this, 1)}
          />
          <Menu.Item 
            active={this.state.view===2} 
            onClick={this.handleView.bind(this, 2)}
            name='Archived'
            icon={{name:"trash"}}
            color="red"
          />
        </Menu>
        <Divider hidden />
        <Card.Group>{AllSurveys}</Card.Group>
         {(this.props.ea && (this.state.showSurvey || this.state.showStats)) ? 
          <div className="boomModalCont">
            {(this.props.ea && this.state.showSurvey) ? 
              <div className="boomModalInfo">
                <div className="boomModalMain">
                  <SurveyHome ea={true} />
                  <Icon name="close" inverted circular className="boomModalClose" size="large" onClick={()=>{this.setState({showSurvey:false})}}/>
                </div>
              </div>
            : null}
          
          {(this.props.ea && this.state.showStats) ? 
            <div className="boomModalInfo">
              <div className="boomModalMain">
                <Statistics />
                <Icon name="close" inverted circular className="boomModalClose" size="large" onClick={()=>{this.setState({showStats:false})}}/>
              </div>
            </div>
          : null}
        </div> : null }
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    surveys: state.Events.surveys,
    events_id: state.Events.id
  };
}

export default connect(mapStateToProps)(AllSurveys);