import React, { Component } from 'react';
import {connect} from 'react-redux';

import {Dimmer, Button, Card, Form, Icon, Label, Dropdown, Search} from 'semantic-ui-react'
import {SendSMS, ResetSMS} from "../../actions/sms";
import {ReadTemplates} from "../../actions/events";

const NumberDropdown = (props) => {
  //console.log(props.numbers);
  if(!props.numbers || props.numbers.length <= 1){
    return null;
  }
  return (
    <Dropdown
      style={{marginTop:10}}
      placeholder={props.numbers[0].text || ""}
      selection
      search
      options={props.numbers}
      onChange={props.handleFrom}
    />
  );
}

class SMS extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      text:"",
      mobiles: this.props.mobiles,
      newmobile:"",
      isLoading:false,
      results:[]
    }
    
    this.stimer = null;
  }
  
  componentWillMount(){
    this.props.dispatch(ReadTemplates(this.props.events_id));
  }
  
  close=()=>{
    this.setState({
      text:"",
      mobiles:[],
      newmobile:"",
      isLoading:false,
      results:[]
    });
    this.props.dispatch(ResetSMS());
  }
  
  blast=()=>{
    //console.log(this.props.mobiles, this.state.mobiles)
    if(window.confirm("Are you sure you want to send this?")){
      var from = this.from || this.props.fromSMS || this.props.fromClient;
      
      this.props.dispatch(SendSMS({
        message:this.state.text,
        events_id:this.props.events_id,
        users_id:this.props.users_id,
        mobiles:this.state.mobiles,
        blasts:this.props.blasts,
        prizes_id:this.props.prizes_id,
        surveys_id:this.props.surveys_id,
        type:this.props.type,
        questions_id:this.props.questions_id,
        from:from,
        sms_receive_id:this.props.sms_receive_id
      }, this.props.callback));
      this.setState({
        text:""
      });
    }
  }
  
  handleText=(evt, data)=>{
    this.setState({
      text:data.value
    })
  }
  
  handleNewMobile=(evt, data)=>{
    this.setState({
      newmobile:data.value
    })
  }
  
  handleTemplates=(evt,data)=>{
    //console.log(data)
    this.setState({
      text:data.value
    })
  }
  
  addMobile=(mobile)=>{
    if(!isNaN(mobile) && mobile.length > 5){
      if(this.state.mobiles.indexOf(mobile) === -1){
        this.state.mobiles.push(mobile);
        this.setState({
          mobiles:this.state.mobiles,
          newmobile:""
        })
      } else {
        alert("You already added this number");
      }
    } else {
      alert("Invalid number");
    }
  }
  
  resetComponent = () => {this.setState({ isLoading: false, results: [], newmobile: '' })}
  
  handleResultSelect = (e, { result }) => {
    this.addMobile(result.title);
  }
  
  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, newmobile:value })
    
    if(this.stimer !== null){
      clearTimeout(this.stimer);
    }
    
    this.stimer = setTimeout(() => {
      if (this.state.newmobile.length < 1) return this.resetComponent();
      var results = this.props.users.filter((obj)=>{
        return ((obj.mobile && obj.mobile.indexOf(value) !== -1) || (obj.fullname && obj.fullname.indexOf(value) !== -1) || (obj.email && obj.email.indexOf(value) !== -1));
      });
      this.setState({
        isLoading: false,
        results: results
      })
      this.stimer = null;
    }, 300)
  }
  
  handleFrom=(ev, {value})=>{
    this.from = value;
  }
  
  render() {
    //console.log(this.props.mobiles);
    var AllMobiles = this.state.mobiles.map((obj,i)=>{
      return (
        <Button icon labelPosition='left' size="mini" className="boomSMSMobileLabel" key={i}
          onClick={()=>{
            this.state.mobiles.splice(i, 1)
            this.setState({
              mobiles:this.state.mobiles
            })
          }}
          >
          <Icon name="close" />
          {obj}
          {(this.props.usersByMobile[obj])?"\n"+this.props.usersByMobile[obj].fullname:null}
        </Button>
      )
    })
    
    var options = this.props.templates.map((obj,i)=>{
      return {key: obj.id, value: obj.message, text: obj.name }
    })
    //console.log(this.state.results);
    var searchresults = this.state.results.map((obj,i)=>{
      return {
        title:obj.mobile,
        description:obj.fullname+" | "+obj.email
      }
    })
    
    var numbers = this.props.mobilearr.map((obj,i)=>{
      return {
        key: obj,
        text: obj,
        value: obj
      };
    });
    return (
      <Dimmer page active={this.props.mode === 2} onClickOutside={this.close}>
        <Form className='boomSMSForm'>
          <Card className='boomSMSCard'>
            <Card.Content>
              <Card.Header>
                {(Math.floor(this.state.text.length/100) + 1)} <Icon name='mail' /> Message
              </Card.Header>
              <Card.Meta>
                <span>
                  {this.state.text.length} characters
                </span>
              </Card.Meta>
              <Card.Description>
                <NumberDropdown numbers={numbers} handleFrom={this.handleFrom}/>
                <div style={{marginTop:10}}></div>
                <Dropdown
                  search
                  selection
                  options={options}
                  onChange={this.handleTemplates}
                  placeholder="Templates"
                />
                <div style={{marginTop:10}}></div>
                <Form.TextArea onChange={this.handleText} placeholder="Dear Customers, we have something going on!" label="Message Content" value={this.state.text}/>
                <Form.Group inline>
                  <Search
                    loading={this.state.isLoading}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={this.handleSearchChange}
                    results={searchresults}
                    placeholder="search..."
                    value={this.state.newmobile}
                  />
                  <Form.Button icon onClick={()=>{
                      this.addMobile(this.state.newmobile);
                    }} style={{float:"left"}}>
                    <Icon name="plus" />
                  </Form.Button>
                </Form.Group>
                <div style={{display:"flex", flexWrap:"wrap", maxHeight:200, overflow:"auto"}}>
                  {AllMobiles}
                </div>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <Button icon labelPosition="left" onClick={this.blast} style={{float:"left"}} color="blue">
                <Icon name="send" />
                Blast
              </Button>
              <Button icon labelPosition="left" onClick={this.close} style={{float:"right"}} color="yellow">
                <Icon name="close" />
                Cancel
              </Button>
            </Card.Content>
          </Card>
        </Form>
      </Dimmer>
    )
  }
}

function mapStateToProps(state) {
  return {
    mode:state.SMS.mode,
    events_id:state.SMS.events_id,
    users_id:state.SMS.users_id,
    mobiles:state.SMS.mobiles,
    prizes_id:state.SMS.prizes_id,
    surveys_id:state.SMS.surveys_id,
    questions_id:state.SMS.questions_id,
    type:state.SMS.smstype,
    blasts:state.SMS.blasts,
    sms_receive_id:state.SMS.sms_receive_id,
    fromSMS:state.SMS.from,
    fromClient:state.Events.mobile,
    callback:state.SMS.callback,
    templates:state.Events.templates,
    users:state.Events.users,
    usersByMobile:state.Events.usersByMobile,
    slug:state.Events.slug,
    mobilearr:state.Events.mobilearr
  };
}

export default connect(mapStateToProps)(SMS);