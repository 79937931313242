import {mfetch} from '../connect';
import {clients, reducers} from '../types';

export function ChangeMenu(items, mtype){
  
  return {
    type:reducers.MENU_CHANGE_ITEM,
    items,
    mtype
  }
}

export function Signout(){
  window.sessionStorage.clear();
  window.location.reload();
}

export function GoAdmin(){
  return {
    type:reducers.ADMIN_CHANGE_PAGE,
    page:1
  }
}