import {reducers} from "../types";
const initialState = {
  page:0,
  mode:1,
  load:false
};


export default function admin(state = initialState, action){
  let obj = Object.assign({}, state);
  switch(action.type) {
    case reducers.LOADING:
      obj.load = action.load;
      return obj;
    case reducers.ADMIN_CHANGE_PAGE:
      obj.page = action.page;
      return obj;
    case reducers.ADMIN_CHANGE_MODE:
      obj.mode = action.mode;
      return obj;
    case reducers.ADMIN_ERROR:
      obj.page = 0;
      return obj;
    case reducers.LOAD_STORED:
      return action.storedState.Admin;
    default:
      return state;
  }
}