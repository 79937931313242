import {reducers} from "../types";
//mode 0 = view, 1 = add
const initialState = {
  items:[],
  mtype:null
};


export default function prizes(state = initialState, action){
  let obj = Object.assign({}, state);
  switch(action.type) {
    case reducers.MENU_CHANGE_ITEM:
      obj.items = action.items;
      obj.mtype = action.mtype
      return obj;
    case reducers.LOAD_STORED:
      return action.storedState.Menu;
    default:
      return state;
  }
}