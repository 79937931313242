import React, { Component } from 'react';
import {connect} from 'react-redux';
import {InsertTemplates, ReadTemplates, UpdateTemplates, DeleteTemplates} from "../../actions/events";
//import {ReadSurvey} from "../../actions/surveys";
import {ChangeSMSMode} from "../../actions/sms";
import {Icon, Label, Form, Divider, Button, Header} from 'semantic-ui-react'

const TemplateCard = (props)=>{
  let obj = props.obj,
      comp = null;
  
  if(props.isEdit){
    comp = (
      <Form>
        <Form.Input onChange={props.handleEditObj.bind(this, 1)} fluid placeholder="eg: Daily Reminder" label="Template Name" defaultValue={obj.name}/>
        <Form.TextArea onChange={props.handleEditObj.bind(this, 2)} defaultValue={obj.message} placeholder="eg: Remember to Visit us to see our special of the week!" label="Template Messge"/>
        <div style={{display:"flex"}}>
          <Button icon={{name:"check"}} color="green" onClick={props.handleTemplateEdit} />
          <Button style={{flex:1}} onClick={props.handleOpenEdit.bind(this, -1)}>Cancel</Button>
          <Button icon={{name:"trash"}} color="red" onClick={props.handleDelete} />
        </div>
      </Form>
    )
  } else { 
    comp = (
      <div>
        <Header>{(obj.name)?obj.name:"No Name"}</Header>
        <div>{obj.message}</div>
        <Button onClick={props.handleOpenEdit.bind(this, obj)}>Edit</Button>
      </div>
    )
  }
  return (
    <div className="templateBox">
      {comp}
    </div>
  )
}

class AllTemplates extends Component {
  constructor(props){
    super(props);
    this.state = {
      editid:null
    }
    this.name = "";
    this.msg = "";
    this.ename = "";
    this.emsg = "";
    this.tid = null;
  }
  
  componentWillMount(){
    this.props.dispatch(ReadTemplates(this.props.events_id));
  }
  
  handleName=(evt, data)=>{
    this.name = data.value;
  }
  
  handleMessage=(evt, data)=>{
    this.msg = data.value;
  }
  
  handleInsert=()=>{
    this.props.dispatch(InsertTemplates({
      name:this.name,
      message:this.msg,
      events_id:this.props.events_id
    }))
  }
  
  handleDelete=()=>{
    this.props.dispatch(DeleteTemplates({
      id:this.state.editid
    }, ()=>{
      this.handleOpenEdit(-1);
      this.props.dispatch(ReadTemplates(this.props.events_id));
    }))
  }
  
  handleOpenEdit=(obj)=>{
    if(obj === -1){
      this.setState({
        editid:obj
      })
    } else {
      this.ename = obj.name;
      this.emsg = obj.message;
      this.setState({
        editid:obj.id
      })
    }
    
  }
  
  handleTemplateEdit=()=>{
    /*
    displatch update ({
      id:template_id,
      name:this.ename,
      message:this.emsg
    })
    */
    
    this.props.dispatch(UpdateTemplates({
      id:this.state.editid,
      name:this.ename,
      message:this.emsg
    }, (data)=>{
      //console.log(data);
      this.handleOpenEdit(-1);
      this.props.dispatch(ReadTemplates(this.props.events_id));
    }));
  }
  
  handleEditObj=(type, evt, data)=>{
    switch(type){
      case 1:
        this.ename = data.value;
        break;
      case 2:
        this.emsg = data.value;
        break;
      default:
        break;
    }
    
    //console.log(evt, data, type, this.ename, this.emsg);
  }
  
  render() {
    //
    let arr = this.props.templates || [];
    
    let AllTemplates = arr.map((obj,i)=>{
      var isEdit = (obj.id===this.state.editid) ? true : false;
      return (
        <TemplateCard 
          key={i} 
          obj={obj}
          handleOpenEdit={this.handleOpenEdit}
          handleEditObj={this.handleEditObj}
          handleTemplateEdit={this.handleTemplateEdit}
          handleDelete={this.handleDelete}
          isEdit={isEdit}
          />
      )
    });
    
    return (
      <div>
        <div style={{padding:10, backgroundColor:"#FFF", marginTop:10, borderRadius:5}}>
          <Form>
            <Form.Input onChange={this.handleName} fluid placeholder="eg: Daily Reminder" label="Template Name"/>
            <Form.TextArea onChange={this.handleMessage} placeholder="eg: Remember to Visit us to see our special of the week!" label="Template Messge"/>
          </Form>
          <Divider hidden />
          <Button icon labelPosition="left" onClick={this.handleInsert}><Icon name="add" />Add Template</Button>
        </div>
        <Divider hidden />
        <div style={{display:"flex", flexWrap:"wrap"}}>
          {AllTemplates}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    events_id: state.Events.id,
    templates: state.Events.templates
  };
}

export default connect(mapStateToProps)(AllTemplates);