import React, { Component } from 'react';
import {connect} from 'react-redux';
import {ChangeMode} from "../../actions/clients";
import AllEvents from "./AllEvents";
import {Icon, Message, Button, Segment, Divider, Label} from 'semantic-ui-react'

class ClientInfo extends Component {
  
  edit=()=>{
    this.props.dispatch(ChangeMode(2));
  }
  
  render() {
    
    return (
      <div>
        <Segment>
          <Label attached="top left" size="big" color="grey"><Icon name='setting' />{this.props.name}</Label>
          <Label attached="top right" size="big" color="grey">{this.props.credits} <Icon name="r circle" /></Label>
          <Divider hidden />
          <Divider hidden />
          <Icon name="mail" /> {this.props.email}
          <Message>
            <Message.Content>
              {this.props.description}
            </Message.Content>
          </Message>
          
          <Divider />
          <Button icon labelPosition='left' onClick={this.edit}>
            <Icon name='edit' />
            Edit Client
          </Button>
          
        </Segment>
        
        <AllEvents />
      </div>
    )
  }
}

function mapStateToProps(state) {
  
  return {
    name:state.Clients.name,
    email:state.Clients.email,
    description:state.Clients.description,
    credits:state.Clients.credits
  };
}

export default connect(mapStateToProps)(ClientInfo);