import {token, ChangeToken} from "./types";

export const DEV = false;
//CHECK index.js for the REDUX devtool
let CONNECT = null

if(DEV){
  CONNECT = {
    dev:"http://localhost:3001/"
  }
} else {
  CONNECT = {
    dev:"https://api.qoouo.com/call/"
  }
}

export async function mfetch(data, callback){
  //console.log("connect",data);
  if(token){
    data.data.token = token;
  }
  //console.log(data);
  let param = data.param,
      mdata = JSON.stringify(data.data);
  
  var json = null;
  try {
    var resp = await fetch(CONNECT.dev+param, {
      method:"POST",
      body:mdata,
      //credentials:"include",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
    json = await resp.json();
    //console.log("connected", json);
  } catch (err){
    console.log("Err connect",err);
  }
  
  if(!json || json.status === -1){
    alert("Please Login");
    window.sessionStorage.clear();
    window.location.reload();
    throw new Error("Need to login!");
    return false;
  } else {
    if(json.token){
      ChangeToken(json.token);
    }
    if(typeof callback === 'function'){
      callback(json);
    }
    return json;
  }
}

/*
export function awsfetch(data, callback){
  var fname = data.name;
  //files[0].fname = fname;
  var fd = new FormData();
  fd.append('name', fname);
  fd.append('prize', data.file);

  fetch(CONNECT.dev+'/stella/upload', { // Your POST endpoint
    method: 'POST',
    body: fd // This is your file object
  }).then(
    response => response.json() // if the response is a JSON object
  ).then(
    json => {
      if(typeof callback === 'function'){
        callback(json); 
      }
    } // Handle the success response object
  ).catch(
    error => {
      if(typeof callback === 'function'){
        callback(error);
      }
    }
  );
}
*/

export async function awsfetch(data, callback){
  //console.log(data);
  var fname = data.name;
  //console.log(data.file);
  //files[0].fname = fname;
  var mdata = {
    data:{
      name:data.name+"."+data.file.type.split("/")[1],
      type:data.file.type
    }
  };
  
  if(token){
    mdata.token = token;
  }
  mdata = JSON.stringify(mdata);
  
  try {
    //console.log(CONNECT.dev+'stella/upload');
    var resp = await fetch(CONNECT.dev+'stella/upload', {
      method:"POST",
      body:mdata,
      //credentials:"include",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
    var json = await resp.json();
    //console.log("aws connected", json);
    
    
    
    var resp = await fetch(json.uploadURL, {
      method:"PUT",
      body:data.blob,
      headers: {
        'Accept': data.file.type,
        'Content-Type': data.file.type,
      }
    });
    return json;
    
    /*
    var req = new XMLHttpRequest();
    req.open("POST", json.uploadURL, true);
    req.setRequestHeader("Content-Type", data.file.type);
    req.onload = function (ev) {
      // Uploaded.
      console.log("s3 done", ev);
    };
    req.send(data.blob);
    */
    
  } catch (err){
    console.log(err);
  }
}