import React, { Component } from 'react';
import {connect} from 'react-redux';
import {InsertEvent} from "../../actions/clients";
import { Card, Icon, Form, Divider } from 'semantic-ui-react'

class AddEvent extends Component {
  constructor(props){
    super(props);
    this.name = "";
    this.desc = "";
    this.slug = "";
    this.stime = new Date();
    this.etime = new Date();
    this.welcome = "";
    this.etype = 1;
    this.signin_pts = 0;
    this.employee_code = 0;
    this.signin_msg = "";
    this.default_msg = "";
    this.optin_msg = "";
    this.profile_msg = "";
    
    this.opts = [
      { key: 1, text: 'Event', value: 0 },
      { key: 2, text: 'Retail', value: 1 },
    ]
    
    this.state = {
      value:1
    }
  }
  
  handleType=(evt, data)=>{
    this.etype = data.value;
    this.setState({
      value:data.value
    })
  }

  handleName=(evt, data)=>{
    //
    this.name=data.value;
  }
  
  handleSlug=(evt, data)=>{
    this.slug=data.value;
  }
  
  handleDesc=(evt, data)=>{
    this.desc=data.value;
  }
  
  handleStartTime=(evt, data)=>{
    this.stime=data.value;
  }
  
  handleEndTime=(evt, data)=>{
    this.etime=data.value;
  }
  
  handleWelcome=(evt, data)=>{
    this.wmsg=data.value;
  }
  
  handleSigninPts=(evt, data)=>{
    this.signin_pts=data.value;
  }
  
  handleEmployeeCode=(evt, data)=>{
    this.employee_code=data.value;
  }
  
  handleSigninMsg=(evt, data)=>{
    this.signin_msg=data.value;
  }
  
  handleDefaultMsg=(evt, data)=>{
    this.default_msg=data.value;
  }
  
  handleOptinMsg=(evt, data)=>{
    this.optin_msg=data.value;
  }
  
  handleProfileMsg=(evt, data)=>{
    this.profile_msg=data.value;
  }
  
  insert=()=>{
    if(this.name === "" || this.slug === "" || this.stime === "" || this.etime === ""){
      return false;
    }
    
    let stime = new Date(this.stime).toISOString().replace('T', ' '),
        etime = new Date(this.etime).toISOString().replace('T', ' ');
    
    //
    this.props.dispatch(InsertEvent({
      name:this.name,
      slug:this.slug,
      description:this.desc,
      start_time:stime,
      end_time:etime,
      cli_id:this.props.id,
      welcome:this.wmsg,
      type:this.etype,
      signin_pts:this.signin_pts,
      employee_code:this.employee_code,
      signin_msg:this.signin_msg,
      default_msg:this.default_msg,
      optin_msg:this.optin_msg
    }));
  }
  
  render() {
    var item_name = (this.state.value === 0) ? "Event" : "Shop";
    return (
      <Form>
        <Card fluid={true}>
          <Card.Content>
            <Form.Dropdown
              onChange={this.handleType}
              options={this.opts}
              placeholder='Choose an option'
              selection 
              value={this.state.value}
            />
            <Divider hidden />
            <Card.Header>
              <Form.Input onChange={this.handleName} fluid placeholder={"eg: Boomerang Data "+item_name} label={item_name+" Name"}/>
            </Card.Header>
            <Divider hidden />
            <Card.Meta>
              <Form.Input onChange={this.handleWelcome} fluid placeholder={"eg: Thanks for signing up for Boomerang "+item_name+"!"} label="Welcome" />
            </Card.Meta>
            <Divider hidden />
            <Card.Meta>
              <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
                <div style={{flex:"1 0 20%", marginRight:10}}>
                  <Form.Input onChange={this.handleSlug} fluid placeholder={"my"+item_name} label="Slug" />
                </div>
                <div style={{flex:"1 0 20%", marginRight:10}}>
                  <Form.Input onChange={this.handleSigninPts} type="number" fluid placeholder="0" label="Signin Points" />
                </div>
                <div style={{flex:"1 0 20%", marginRight:10}}>
                  <Form.Input onChange={this.handleEmployeeCode} type="number" fluid placeholder="0" label="Employee Code" />
                </div>
              </div>
            </Card.Meta>
            <Divider hidden />
            <Card.Meta>
              <Form.Input onChange={this.handleSigninMsg} fluid placeholder={"default: to sign in to the event's reward/marketing program"} label="Sign In Message" />
              <Form.Input onChange={this.handleDefaultMsg} fluid placeholder={"EG: Thanks for messaging us"} label="Default Message" />
              <Form.Input onChange={this.handleOptinMsg} fluid placeholder={"EG: To join our service use @join"} label="Opt-in Message" />
              <Form.Input onChange={this.handleProfileMsg} fluid placeholder={"EG: Complete your profile by typing @name and @email"} label="Profile Message" />
            </Card.Meta>
            <Divider hidden />
            <Card.Description>
              <Form.TextArea onChange={this.handleDesc} label={item_name+" Description"} placeholder={"Description of the "+item_name} />
              {/*
              <Divider hidden />
              <Form.Input onChange={this.handleStartTime} label="Start Time" type="datetime-local"/>
              <Form.Input onChange={this.handleEndTime} label="End Time" type="datetime-local"/>
              */}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <a>
              <Form.Button icon labelPosition='left' onClick={this.insert}>
                <Icon name='checkmark' />
                Create {item_name}
              </Form.Button>
            </a>
          </Card.Content>
        </Card>
      </Form>
    )
  }
}

function mapStateToProps(state) {
  return {
    newevent: state.Clients.newevent,
    id:state.Clients.id,
  };
}

export default connect(mapStateToProps)(AddEvent);