import {mfetch} from '../connect';
import {clients, reducers} from '../types';
import {Loading} from './admin';

export function ChangeMode(mode){
  return {
    type:reducers.CLIENT_CHANGE_MODE,
    mode
  }
}

/*function RequestRead(){
  return {
    type:"REQUEST_CLIENT_ID",
    load:true
  }
}

function FinishRead(client){
  
  return {
    type:"FINISH_CLIENT_ID",
    load:false,
    client
  }
}*/

export function ReadClient(client){
  /*return (dispatch)=>{
    dispatch(RequestRead());
    return mfetch({param:'read/clients', data:{id:id}}, (json)=>{
      dispatch(FinishRead(json.data[0]));
    })
  }*/
  return {
    type:reducers.CLIENT_READ,
    load:false,
    client
  }
}


function FinishUpdate(client){
  return {
    type:reducers.CLIENT_UPDATE,
    client
  }
}

export function UpdateClient(client){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"update", key:"client",data:client}});
    dispatch(FinishUpdate(client));
    dispatch(Loading(false));
  }
}

function FinishEventInsert(event){
  return {
    type:reducers.CLIENT_EVENT_INSERT,
    event
  }
}

export function InsertEvent(event){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"create", key:"event", data:event}})
    dispatch(FinishEventInsert(json.data));
  }
}

function FinishEventRead(events){
  return {
    type:reducers.CLIENT_EVENTS_READ,
    load:false,
    events
  }
}

export function ReadEvents(id){
  var data = {};
  if(id){
    data = {cli_id:id};
  }
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"events", data:data}});
    if(json.status && json.data){
      dispatch(FinishEventRead(json.data));
      dispatch(Loading(false));
    }
  }
}

export function Reset(){
  return {
    type:reducers.CLIENT_RESET
  }
}