export const reducers = {
  LOAD_STORED:"LOAD_STORED_STATE",
  LOADING:"LOADING",
  ADMIN_CHANGE_PAGE:"CHANGE_PAGE",
  ADMIN_CHANGE_MODE:"ADMIN_CHANGE_MODE",
  ADMIN_ERROR:"ERROR",
  EVENTS_ADMIN_MODE:"EVENTS_ADMIN_MODE",
  EVENTS_ADMIN_READ:"EVENTS_ADMIN_READ",
  CLIENT_CHANGE_MODE:"CLIENT_CHANGE_MODE",
  CLIENT_READ:"CLIENT_READ",
  CLIENT_RESET:"CLIENT_RESET",
  CLIENT_UPDATE:"CLIENT_UPDATE",
  CLIENT_EVENTS_READ:"CLIENT_EVENTS_READ",
  CLIENT_EVENT_INSERT:"CLIENT_EVENT_INSERT",
  CLIENT_EVENT_ADMIN_READ:"CLIENT_EVENT_ADMIN_READ",
  DASHBOARD_CHANGE_MODE:"DASHBOARD_CHANGE_MODE",
  DASHBOARD_CLIENT_INSERT:"DASHBOARD_CLIENT_INSERT",
  DASHBOARD_CLIENTS_READ:"DASHBOARD_CLIENTS_READ",
  EVENT_READ:"EVENT_READ",
  EVENT_CHANGE_MODE:"EVENT_CHANGE_MODE",
  EVENT_READ_ALL:"EVENT_READ_ALL",
  EVENT_RESET:"EVENT_RESET",
  EVENT_UPDATE:"EVENT_UPDATE",
  EVENT_SURVEY_INSERT:"EVENT_SURVEY_INSERT",
  EVENT_SURVEYS_READ:"EVENT_SURVEYS_READ",
  EVENT_UPDATE_SURVEYS:"EVENT_UPDATE_SURVEYS",
  EVENT_PRIZE_INSERT:"EVENT_PRIZE_INSERT",
  EVENT_PRIZES_READ:"EVENT_PRIZES_READ",
  EVENT_PRIZES_UPDATE:"EVENT_PRIZES_UPDATE",
  EVENT_USERS_READ:"EVENT_USERS_READ",
  EVENT_PRIZESUSERS_READ:"EVENT_PRIZESUSERS_READ",
  EVENT_SIGNINS_READ:"EVENT_SIGNINS_READ",
  EVENT_MESSAGES_READ:"EVENT_MESSAGES_READ",
  EVENT_FEEDBACKS_READ:"EVENT_FEEDBACKS_READ",
  EVENT_TEMPLATES_READ:"EVENT_TEMPLATES_READ",
  EVENT_EVENTS_PROMO_READ:"EVENT_EVENTS_PROMO_READ",
  EVENT_GROUPS_READ:"EVENT_GROUPS_READ",
  EVENT_EA_READ:"EVENT_EA_READ",
  PRIZE_UPDATE:"PRIZE_UPDATE",
  PRIZE_READ:"PRIZE_READ",
  PRIZE_RESET:"PRIZE_RESET",
  PRIZE_CHANGE_MODE:"PRIZE_CHANGE_MODE",
  SMS_CHANGE_MODE:"SMS_CHANGE_MODE",
  SMS_RESET:"SMS_RESET",
  SURVEY_CHANGE_MODE:"SURVEY_CHANGE_MODE",
  SURVEY_READ:"SURVEY_READ",
  SURVEY_RESET:"SURVEY_RESET",
  SURVEY_UPDATE:"SURVEY_UPDATE",
  SURVEY_QUESTIONS_READ:"SURVEY_QUESTIONS_READ",
  SURVEY_CUR_QUESTION:"SURVEY_CUR_QUESTION",
  SURVEY_USERS_OPTIONS_READ:"SURVEY_USERS_OPTIONS_READ",
  MENU_CHANGE_ITEM:"MENU_CHANGE_ITEM"
}

export const clients = {
  AUTH_PATH:"stella/auth",
  ADMIN_PATH:"stella/admin",
  EVENTS_ADMIN_PATH:"stella/eventsadmin",
  MESSAGE_PATH:"stella/message"
}

export var token = null;
try {
  token = sessionStorage.getItem("token");
} catch (err){
  
}
export function ChangeToken(t){
  token = t;
  sessionStorage.setItem("token", t);
}