import React, { Component } from 'react';
import {connect} from 'react-redux';
import {UpdatePrize, ChangeMode} from "../../actions/prizes";
import {awsfetch} from "../../connect";
import { Card, Icon, Form, Divider, Checkbox } from 'semantic-ui-react';
import {compress}from '../compress';

class EditPrize extends Component {
  constructor(props){
    super(props);
    this.state = {
      name:this.props.name,
      img:this.props.img,
      win:this.props.win_text,
      description:this.props.description,
      redeemable:this.props.redeemable,
      pts:this.props.pts,
      type:this.props.type,
      odds:this.props.odds,
      repeats:this.props.repeats,
      maxwin:this.props.maxwin,
      prev:null,
      src:null
    }
    this.files = null;
    this.blob = null;
    
    this.opts = [
      { key: 1, text: 'Regular', value: 1 },
      { key: 2, text: 'One-Time', value: 2 },
      { key: 3, text: 'Chance', value: 3 },
      { key: 4, text: 'One-Time Chance', value: 4 }
    ]
  }
  
  handleName=(evt, data)=>{
    //
    this.setState({
      name:data.value
    })
  }
  
  handleImg=(evt, data)=>{
    this.setState({
      img:data.value
    })
  }
  
  handlePts=(evt, data)=>{
    this.setState({
      pts:data.value
    })
  }
  
  handleWin=(evt, data)=>{
    this.setState({
      win:data.value
    })
  }
  
  handleDesc=(evt, data)=>{
    this.setState({
      description:data.value
    })
  }
  
  handleRedeemable=(evt, data)=>{
    this.setState({
      redeemable:(data.checked)?1:0
    })
  }
  
  handleMaxWin=(evt, data)=>{
    this.setState({
      maxwin:data.value
    })
  }
  
  handleType=(evt, data)=>{
    this.setState({
      type:data.value
    })
  }
  
  handleOdds=(evt, data)=>{
    this.setState({
      odds:data.value
    })
  }
  
  handleRepeats=(evt, data)=>{
    this.setState({
      repeats:data.value
    })
  }
  
  handleFile=(evt, data)=>{
    //
    this.files = evt.target.files;
    var reader = new FileReader();

    reader.onload = (e)=>{
      //$('#blah').attr('src', e.target.result);
      compress(e.target.result, this.files[0].name, (dataurl, compfileobj, blob)=>{
        this.compfileobj = compfileobj;
        this.blob = blob;
        this.setState({
          prev:dataurl
        });
      });
    }

    reader.readAsDataURL(this.files[0]);
  }

  edit=async ()=>{
    if(this.compfileobj){
      var cid = (this.props.cid)?"cli"+this.props.cid:"clientid";
      var data = {
        name:"clients/cli"+this.props.cid+"/events/ev"+this.props.eid+"/prizes/pr"+this.props.id+"/header",
        file:this.compfileobj,
        blob:this.blob
      }
      
      var json = await awsfetch(data);
      if(json.location){
        this.props.dispatch(UpdatePrize({
          id:this.props.id,
          name:this.state.name,
          img:json.location,
          win_text:this.state.win,
          description:this.state.description,
          redeemable:this.state.redeemable,
          pts:this.state.pts,
          type:this.state.type,
          odds:this.state.odds,
          repeats:this.state.repeats,
          maxwin:this.state.maxwin
        }));
      } else {
        alert("Only Images Allowed!");
      }
    } else {
      this.props.dispatch(UpdatePrize({
        id:this.props.id,
        name:this.state.name,
        win_text:this.state.win,
        description:this.state.description,
        redeemable:this.state.redeemable,
        pts:this.state.pts,
        type:this.state.type,
        odds:this.state.odds,
        repeats:this.state.repeats,
        maxwin:this.state.maxwin
      }));
    }
    
  }
  
  render() {
    return (
      <Form>
        <Card fluid={true}>
          <Card.Content>
            <Card.Header>
              <Form.Input type="file" onChange={this.handleFile} />
              <Divider hidden />
              {(this.state.prev) ? <div className="preview" style={{maxWidth:"250px", backgroundImage:"url("+this.state.prev+")"}}/> : null}
            </Card.Header>
            <Divider hidden />
            <Card.Description>
              <Checkbox defaultChecked={(this.state.redeemable===1)} onChange={this.handleRedeemable} label="Redeemable"/>
              
              <Form.Input onChange={this.handleName} placeholder="eg: New Ipad!" value={this.state.name} label="Name"/>
              <Form.Dropdown
                onChange={this.handleType}
                options={this.opts}
                label="Reward Type"
                placeholder='What kind of rewards?'
                selection 
                value={this.state.type}
              />
              
              <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
                <div style={{flex:"1 0 15%", marginRight:10}}>
                  <Form.Input type="number" onChange={this.handlePts} placeholder="100" value={this.state.pts} label="Redeem Points"/>
                </div>
                
                {(this.state.type === 3  || this.state.type === 4) ? 
                  <div style={{flex:"1 0 15%", marginRight:10}}>
                    <Form.Input type="number" onChange={this.handleOdds} placeholder="0" label="1 in ## Chance to Win" value={this.state.odds} />
                  </div>
                  : null
                 }
                
                {(this.state.type === 3  || this.state.type === 4) ? 
                  <div style={{flex:"1 0 15%", marginRight:10}}>
                    <Form.Input type="number" onChange={this.handleMaxWin} placeholder="0" label="Max # of wins" value={this.state.maxwin} />
                  </div>
                  : null
                 }

                {(this.state.type === 2  || this.state.type === 4) ? 
                  <div style={{flex:"1 0 15%", marginRight:10}}>
                    <Form.Input type="number" onChange={this.handleRepeats} placeholder="0" label="Repeats Every # of Days" value={this.state.repeats} />
                  </div>
                  : null
                 }
              </div>
              <Divider hidden />
              <Form.Input onChange={this.handleWin} fluid placeholder="eg: You've won the New Ipad!" value={this.state.win} label="Winner SMS"/>
              
              <Form.TextArea onChange={this.handleDesc} placeholder={"EG: Reward is a one of a kind special...."} label="Reward Description" value={(this.state.description)?this.state.description:""} />
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <a>
              <Form.Button icon labelPosition='left' onClick={this.edit}>
                <Icon name='checkmark' />
                Done
              </Form.Button>
            </a>
          </Card.Content>
        </Card>
      </Form>
    )
  }
}

function mapStateToProps(state) {
  return {
    id:state.Prizes.id,
    name:state.Prizes.name,
    img:state.Prizes.img,
    type:state.Prizes.type,
    win_text:state.Prizes.win_text,
    description:state.Prizes.description,
    redeemable:state.Prizes.redeemable,
    pts:state.Prizes.pts,
    odds:state.Prizes.odds,
    repeats:state.Prizes.repeats,
    maxwin:state.Prizes.maxwin,
    cid:state.Clients.id,
    eid:state.Events.id
  };
}

export default connect(mapStateToProps)(EditPrize);