import React, { Component } from 'react';
import {connect} from 'react-redux';
import {awsfetch} from "../../connect";
import {UpdateQuestion, ChangeMode} from "../../actions/surveys";
import { Card, Icon, Input, Button, Divider, Form, Checkbox } from 'semantic-ui-react';
import {compress}from '../compress';

class EditQuestion extends Component {
  constructor(props){
    super(props);
    this.state = {
      qtext:this.props.curquestion.text,
      options:this.props.curquestion.options || [],
      order:this.props.curquestion.qorder,
      track:this.props.curquestion.track,
      track_response:this.props.curquestion.track_response || "",
      prev:null,
      answers_desc:this.props.curquestion.answers_desc || "",
      ninputs:0,
    }
    this.files = null;
    this.noptions=[];
    this.compfileobj = null;
    this.blob = null;
  }
  
  componentWillMount(){
    /*if(this.state.options.length < 4){
      let options = this.state.options;
      let ind = this.state.options.length;
      
      for(let i = ind; i<4; i++){
        options.push({
          id:null,
          text:"",
          img:"",
          questions_id:this.props.curquestion.id,
          pts:0
        })
      }
      this.setState({
        options:options
      })
      
    }*/
  }
  
  handleNInputs=()=>{
    this.setState({
      ninputs:this.state.ninputs+1
    })
  }
  
  handleNOps=(ind, evt, data)=>{
    
    if(!this.noptions[ind]){
      this.noptions[ind]= {
        text:"",
        pts:0
      }
    }
    
    this.noptions[ind].text = data.value;
  }
  
  handleNPts=(ind, evt, data)=>{
    if(!this.noptions[ind]){
      this.noptions[ind]= {
        text:"",
        pts:0
      }
    }
    
    this.noptions[ind].pts = data.value;
  }
  
  handleText=(evt, data)=>{
    //
    this.setState({
      qtext:data.value
    })
  }
  
  handleOrder=(evt, data)=>{
    //
    this.setState({
      order:data.value
    })
  }
  
  handleTracked=(evt, data)=>{
    //
    this.setState({
      track:(data.checked)?1:0
    })
  }
  
  handleTrackResponse=(evt, data)=>{
    //
    this.setState({
      track_response:data.value
    })
  }
  
  handleAnswersDesc=(evt, data)=>{
    //
    this.setState({
      answers_desc:data.value
    })
  }
  
  handleOps=(ind, evt, data)=>{
    
    let options = this.state.options;
    options[ind].text = data.value;
    this.setState({
      options:options
    });
  }
  
  handlePts=(ind, evt, data)=>{
    
    let options = this.state.options;
    options[ind].pts = parseInt(data.value, 10);
    this.setState({
      options:options
    });
  }
  
  handleFile=(evt, data)=>{
    //
    this.files = evt.target.files;
    var reader = new FileReader();

    reader.onload = (e)=>{
      //$('#blah').attr('src', e.target.result);
      compress(e.target.result, this.files[0].name, (dataurl, compfileobj, blob)=>{
        this.compfileobj = compfileobj;
        this.blob = blob;
        this.setState({
          prev:dataurl
        });
      });
      
    }
    console.log(this.files);
    reader.readAsDataURL(this.files[0]);
  }
  
  //changing to questions update to disconnect opts (NOT TESTED)
  update=async ()=>{
    let curquestion = this.props.curquestion;
    let opts = this.state.options.concat(this.noptions);
    let newopts = [];
    for(var i in opts){
      if(opts[i].text !== "" && opts[i].id !== null){
        newopts.push(opts[i]);
      }
    }
    curquestion.options = newopts;
    curquestion.text = this.state.qtext;
    curquestion.qorder = this.state.order;
    curquestion.answers_desc = this.state.answers_desc;
    curquestion.track = this.state.track;
    curquestion.track_response = this.state.track_response;
    
    if(this.compfileobj){
      var cid = (this.props.cid)?"cli"+this.props.cid:"clientid";
      var data = {
        name:"clients/"+cid+"/events/ev"+this.props.eid+"/surveys/sv"+this.props.sid+"/q"+this.props.curquestion.id,
        file:this.compfileobj,
        blob:this.blob
      }
      var json = await awsfetch(data);
      if(json.location){
        curquestion.img = json.location;
      }
      this.props.dispatch(UpdateQuestion(curquestion));
      //this.props.dispatch(ReadQuestions(this.props.sid));
      //this.props.dispatch(ChangeMode(1));
    } else {
    //console.log(curquestion);
      this.props.dispatch(UpdateQuestion(curquestion));
      //this.props.dispatch(ReadQuestions(this.props.sid));
      //this.props.dispatch(ChangeMode(1));
    }
  }
  
  render() {
    //
    
    let optscomp = null;
    
    /*optscomp = (
        <Input onChange={this.handleOps.bind(this, 0)} placeholder="Black Smith Love" label="Option 1" value={this.state.options[0].text}/>
        <Input type="number" onChange={this.handlePts.bind(this, 0)} label="Points" placeholder="0" value={this.state.options[0].pts}/>

        <Divider hidden />
        <Input onChange={this.handleOps.bind(this, 1)} placeholder="Free for all" label="Option 2" value={this.state.options[1].text}/>
        <Input type="number" onChange={this.handlePts.bind(this, 1)} label="Points" placeholder="0" value={this.state.options[1].pts}/>

        <Divider hidden />
        <Input onChange={this.handleOps.bind(this, 2)} placeholder="Lovers in Paradise" label="Option 3" value={this.state.options[2].text}/>
        <Input type="number" onChange={this.handlePts.bind(this, 2)} label="Points" placeholder="0" value={this.state.options[2].pts}/>

        <Divider hidden />
        <Input onChange={this.handleOps.bind(this, 3)} placeholder="Farooq Nevada" label="Option 4" value={this.state.options[3].text}/>
        <Input type="number" onChange={this.handlePts.bind(this, 3)} label="Points" placeholder="0" value={this.state.options[3].pts}/>
      
    )*/
    
    //console.log(this.props.curquestion.options);
    optscomp = this.state.options.map((obj,i)=>{
      return (
        <span key={i}>
          <Input onChange={this.handleOps.bind(this, i)} placeholder="Editted Options" label={"Option "+(i+1)} value={obj.text}/>
          <Input type="number" onChange={this.handlePts.bind(this, i)} label="Points" placeholder="0" value={obj.pts}/>
          <Divider hidden />
        </span>
      )
    });
    
    var allinps = [];
    for(var i = 0; i<this.state.ninputs; i++){
      allinps.push(
        <span key={i}>
          <Input onChange={this.handleNOps.bind(this, i)} placeholder="New Option" label={"Option "+(optscomp.length+i+1)}/>
          <Input type="number" onChange={this.handleNPts.bind(this, i)} label="Points" placeholder="0" />
          <Divider hidden />
        </span>
      )
    }
    var prev = (this.state.prev) ? <div className="promo" style={{maxWidth:"400px", backgroundImage:"url("+this.state.prev+")"}}></div> : null;
    
    return (
      <Card fluid={true}>
        <Card.Content>
          <Card.Header>
            <Input onChange={this.handleText} fluid placeholder="eg: What is this fundraiser's name?" label="Survey Question" value={this.state.qtext}/>
          </Card.Header>
          <Divider hidden />
          <Card.Description>
            <Form>
              <Form.Group inline>
                <Input onChange={this.handleOrder} placeholder="1" label="Order" value={this.state.order} style={{marginRight:75, width:50}} />
                <Form.Input type="file" onChange={this.handleFile} />
                {prev}
              </Form.Group>
            </Form>
            <Divider hidden />
            {optscomp}
            {allinps}
            <Divider hidden />
            <Form>
              <Form.Group inline>
                <Form.TextArea onChange={this.handleAnswersDesc} placeholder={"A is the reason why everything is so great"} label="Description For The Answer" value={this.state.answers_desc} />
              </Form.Group>
              <Divider hidden />
              <Checkbox label="tracked" checked={this.state.track === 1} onChange={this.handleTracked} />
              <Divider hidden />
              <Form.TextArea onChange={this.handleTrackResponse} placeholder={"Thanks for voting, we'll give you the result soon!"} label="SMS Response For Question" value={this.state.track_response} />
            </Form>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <a>
            <Button icon labelPosition='left' onClick={this.handleNInputs}>
              <Icon name='add' />
              Options
            </Button>
            <Button icon labelPosition='left' onClick={this.update}>
              <Icon name='checkmark' />
              Edit Question
            </Button>
          </a>
        </Card.Content>
      </Card>
    )
  }
}

function mapStateToProps(state) {
  return {
    curquestion:state.Surveys.curquestion,
    sid:state.Surveys.id,
    eid:state.Events.id,
    cid:state.Clients.id,
  };
}

export default connect(mapStateToProps)(EditQuestion);