import React, { Component, useState, useEffect } from 'react';
import {ChangeMode, ReadAll, UpdateEA} from '../actions/eventsadmin';
import {Signout} from '../actions/menu';
import {connect} from 'react-redux';
import {Loader, Icon, Divider, Form, Checkbox, Button} from 'semantic-ui-react';

import AllFeedback from '../comps/Event/AllFeedback';
import AllUsers from '../comps/Event/AllUsers';
import AllSurveys from '../comps/Event/AllSurveys';
import AllPrizes from '../comps/Event/AllPrizes';
import AllPromos from '../comps/Event/AllPromos';

const Settings=(props)=>{
  
  const [un, setUn] = useState(null);
  const [pw, setPw] = useState(null)
  const [notif, setNotif] = useState(0);
  const [mobile, setMobile] = useState(null);
  
  useEffect(()=>{
    console.log(props.ea);
    if(props.ea){
      setUn(props.ea.username);
      setNotif(props.ea.notify);
      setMobile(props.ea.notify_mobile);
    }
  },[props.ea])
  
  return (
    <div>
      Settings
      <Form className="boomFilter">
        <Form.Group inline>
          <Form.Input className="boomFilterInput" placeholder="username"  label="Username" value={un || ""} onChange={(e, {value})=>{setUn(value)}}/>
          <Form.Input type="password" className="boomFilterInput" placeholder="********"  label="Password" value={pw || ""} onChange={(e, {value})=>{setPw(value)}}/>
        </Form.Group>
        <Checkbox className="boomFilterCheckbox" checked={notif === 1} onChange={(e, {checked})=>{setNotif(checked ? 1 : 0)}} label="Notify me when there's a new SMS" />
        <Form.Input className="boomFilterInput" value={mobile || ""} onChange={(e, {value})=>{setMobile(value)}} placeholder="7781234567" label="Notification Mobile Number"/>
        <Button onClick={()=>{
            var ea = props.ea;
            ea.username = un;
            ea.password = pw;
            ea.notify = notif;
            ea.notify_mobile = mobile;
            props.handleUpdate(ea);
          }}>Update</Button>
      </Form>
    </div>
  )
}

const Menu=(props)=>{
  return (
    <div className="eamenu" style={{right:props.mright, opacity:(props.mright === 0) ? 1 : 0.3}} 
      onClickCapture={()=>{props.handleMRight(0);}}>
      <Icon name={(props.mright === 0) ? "caret right" : "caret left"}   color="grey" size="large" 
        onClick={()=>{
          var r = (props.mright === 0) ? -110 : 0;
          props.handleMRight(r);
        }}
        />
      <div className={(props.mode === 2) ? "active" : ""} onClick={()=>{props.handleMode(2)}}>
        <Icon name="chat" circular inverted color="brown" /> SMS
      </div>
      <div className={(props.mode === 3) ? "active" : ""} onClick={()=>{props.handleMode(3)}}>
        <Icon name="users" circular inverted color="blue" /> Users
      </div>
      <div className={(props.mode === 4) ? "active" : ""} onClick={()=>{props.handleMode(4)}}>
        <Icon name="pencil" circular inverted color="orange" /> Surveys
      </div>
      <div className={(props.mode === 5) ? "active" : ""} onClick={()=>{props.handleMode(5)}} >
        <Icon name="gift" circular inverted color="teal" /> Rewards
      </div>
      <div className={(props.mode === 6) ? "active" : ""} onClick={()=>{props.handleMode(6)}}>
        <Icon name="hashtag" circular inverted color="purple" /> Promos
      </div>
      <div className={(props.mode === 7) ? "active" : ""} onClick={()=>{props.handleMode(7)}}>
        <Icon name="settings" circular inverted color="yellow" /> Settings
      </div>
      <div onClick={props.Signout}>
        <Icon className="sign out alternate" circular inverted color="grey" /> Signout
      </div>
    </div>
  )
}

class EventsAdmin extends Component {
  
  constructor(props){
    super(props);
    this.state={
      mright:0
    }
  }
  
  componentWillMount=async()=>{
    await this.props.dispatch(ReadAll());
    if(this.props.mode === 0){
      await this.props.dispatch(ChangeMode(2));
    }
  }
  
  handleMode=async (mode)=>{
    if(this.state.mright === 0){
      await this.props.dispatch(ReadAll());
      this.props.dispatch(ChangeMode(mode));
    }
  }
  
  handleMRight=(r)=>{
    this.setState({
      mright:r
    })
  }
  
  handleUpdate=async (ea)=>{
    //console.log(ea);
    await this.props.dispatch(UpdateEA(ea));
    alert("Profile Updated");
  }
  
  render() {
    var comp = null,
        header = "";
    
    switch(this.props.mode){
      case 2:
        comp = <AllFeedback filter={true} nofetch={true} ea={true}/>;
        header = "SMS";
        break; 
      case 3:
        comp = <AllUsers filter={true} nofetch={true} />;
        header = "Users";
        break;
      case 4:
        comp = <AllSurveys nofetch={true} ea={true} />;
        header = "Surveys";
        break;
      case 5:
        comp = <AllPrizes filter={true} nofetch={true} ea={true}/>;
        header = "Rewards";
        break;
      case 6:
        comp = <AllPromos filter={true} nofetch={true} />;
        header = "Promos";
        break;
      case 7:
        comp = <Settings ea={this.props.ea} handleUpdate={this.handleUpdate}/>;
        header = "Settings";
        break;
      default:
        comp = <AllFeedback filter={true} nofetch={true} />;
        header = "SMS";
        break;
    }
    
    //console.log(this.props.ea);
    return (
      <div className="eacont">
        <div className="eamain" onClick={this.handleMRight.bind(this, -110)}>
          <div className="eahead">{header}</div>  
          <div className="eainfo">
            {comp}
          </div>
          </div>
        <Menu 
          handleMode={this.handleMode} 
          mode={this.props.mode} 
          mright={this.state.mright} 
          handleMRight={this.handleMRight}
          Signout={()=>{if(this.state.mright === 0){this.props.dispatch(Signout())}}}
          />
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    ea:state.EventsAdmin.ea,
    mode:state.EventsAdmin.mode
  };
}

export default connect(mapStateToProps)(EventsAdmin);