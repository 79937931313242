import React, { Component } from 'react';
import {connect} from 'react-redux';
import {UpdateClient, ChangeMode} from "../../actions/clients";
import { Card, Icon, Form, Divider } from 'semantic-ui-react'

class EditClient extends Component {
  constructor(props){
    super(props);
    this.state = {
      name:this.props.name,
      email:this.props.email,
      desc:this.props.description,
      password:""
    }
  }
  
  handleName=(evt, data)=>{
    //
    this.setState({
      name:data.value
    })
  }
  
  handleEmail=(evt, data)=>{
    this.setState({
      email:data.value
    })
  }
  
  handlePassword=(evt, data)=>{
    this.setState({
      password:data.value
    })
  }
  
  handleDesc=(evt, data)=>{
    this.setState({
      desc:data.value
    })
  }
  
  edit=()=>{
    this.props.dispatch(UpdateClient({
      id:this.props.id,
      name:this.state.name,
      email:this.state.email,
      password:this.state.password,
      description:this.state.desc
    }));
    this.props.dispatch(ChangeMode(1));
  }
  
  render() {
    
    return (
      <Form>
        <Card fluid={true}>
          <Card.Content>
            <Card.Header>
              <Form.Input onChange={this.handleName} placeholder="eg: Boomerang Data Inc" value={this.state.name} label="Name"/>
            </Card.Header>
            <Divider hidden />
            <Card.Meta>
              <Form.Input onChange={this.handleEmail} placeholder="eg: info@boomerangdata.com" value={this.state.email} label="Email" />
            </Card.Meta>
            <Divider hidden />
            <Card.Meta>
              <Form.Input onChange={this.handlePassword} fluid type="password" placeholder="*********" label="Password" />
            </Card.Meta>
            <Divider hidden />
            <Card.Description>
              <Form.TextArea onChange={this.handleDesc} placeholder="Description of the client" value={this.state.desc} label="Description" />
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <a>
              <Form.Button icon labelPosition='left' onClick={this.edit}>
                <Icon name='checkmark' />
                Done
              </Form.Button>
            </a>
          </Card.Content>
        </Card>
      </Form>
    )
  }
}

function mapStateToProps(state) {
  return {
    id:state.Clients.id,
    name:state.Clients.name,
    email:state.Clients.email,
    description:state.Clients.description
  };
}

export default connect(mapStateToProps)(EditClient);