import React, { Component } from 'react';
import {connect} from 'react-redux';
import {InsertEventsPromo, ReadEventsPromo, UpdateEventsPromo, DeleteEventsPromo, ReadReceivesEvents} from "../../actions/events";
//import {ReadSurvey} from "../../actions/surveys";
import {ChangeSMSMode} from "../../actions/sms";
import {Icon, Label, Form, Divider, Button, Message, Card, Grid, Checkbox, Feed} from 'semantic-ui-react'

import {awsfetch} from "../../connect";
import {compress}from '../compress';
import {months, days} from '../../func/csv';
var QRCode = require('qrcode.react');

//<QRCode value={this.props.hash} size={90}/>
const PromoCard = (props)=>{
  let obj = props.obj,
      comp = null;
  
  if(props.isEdit){
    comp = (
      <Form>
        <Form.Input onChange={props.handleEditObj.bind(this, 1)} placeholder="eg: Daily Special!" label="Promo Title" defaultValue={obj.title}/>
        <Form.Input type="file" label="Promo Image" onChange={props.handleFile} />
        {(props.prev) ? 
          <div className="promo" style={{backgroundImage:"url("+props.prev+")"}}></div> : null}
        <Divider hidden />
        <Checkbox defaultChecked={obj.display === 1} onChange={props.handleEditObj.bind(this, 6)} label="Display to Public"/>
        <Form.Group inline>
          <Form.Input fluid onChange={props.handleEditObj.bind(this, 3)} placeholder="1" label="Order" defaultValue={obj.porder} />
          <Form.Input fluid onChange={props.handleEditObj.bind(this, 2)} placeholder="eg: dailyspecial" label="Promo SMS Code #" defaultValue={obj.sms_code}/>
        </Form.Group>
        <Form.TextArea onChange={props.handleEditObj.bind(this, 4)} placeholder="eg: Look four our specialized Brazilian coffee. It helps enhance productivity with brain power." label="Promo Description" defaultValue={obj.description}/>
        <Form.TextArea onChange={props.handleEditObj.bind(this, 5)} placeholder="eg: Our daily special includes coffee, tea, mugs and much more. Learn more here [link]" label="Promo SMS Message" defaultValue={obj.sms_message}/>
        <div style={{display:"flex"}}>
          <Button icon={{name:"check"}} color="green" onClick={props.handlePromoEdit} />
          <Button style={{flex:1}} onClick={props.handleOpenEdit.bind(this, -1)}>Cancel</Button>
          <Button icon={{name:"trash"}} color="red" onClick={props.handleDelete} />
        </div>
      </Form>
    )
  } else { 
    /*comp = (
      <div>
        <Header>{(obj.title)?obj.title:"No Title"}</Header>
        <div>{obj.description}</div>
        <Button onClick={props.handleOpenEdit.bind(this, obj)}>Edit</Button>
      </div>
    )*/
    var lastSMS = null;
    
    if(obj.sms_code && props.tagobj["#"+obj.sms_code]) {
      var smsobj = props.tagobj["#"+obj.sms_code][0],
          smsdate = new Date(smsobj.time);
      
      lastSMS = (
        <div className="promoLastSMSCont">
          <div style={{fontSize:10}}>Last SMS</div>
          <div className="promoLastSMS">
            <div>{smsobj.mobile}</div>
            <div>{months[smsdate.getMonth()]} {smsdate.getDate()}</div>
            <div>{smsdate.getHours()}:{(smsdate.getMinutes() < 10) ? "0"+smsdate.getMinutes():smsdate.getMinutes()}</div>
          </div>
          <div className="promoLastSMSMsg">
            <div>{(props.usersByMobile[smsobj.mobile]) ? props.usersByMobile[smsobj.mobile].fullname+": " : null}{smsobj.msg}</div>
          </div>
        </div>
      );
    }
    comp = (
      <Card className="promoCard">
        <Card.Content>
          <Label attached="top left">
            <Checkbox toggle checked={obj.active === 1} onChange={props.handleActivate.bind(this, obj)}/>
          </Label>
          <Label attached="top right" size="tiny" color="grey">
            <Button disabled={obj.porder <= 1} size="mini" icon onClick={props.handleOrder.bind(this, -1, obj)}>
              <Icon name='sort up' />
            </Button>
            <Button size="mini" icon onClick={props.handleOrder.bind(this, 1, obj)}>
              <Icon name='sort down' />
            </Button>
            <Icon name='sort' />
            {obj.porder}
          </Label>
          <Card.Header>
            <div style={{marginTop:50}}></div>
            {obj.title}
          </Card.Header>
          <Card.Description>
            {(obj.display === 0) ? <i>hidden</i> : null}
            {(obj.img) ? <div className="promo" style={{backgroundImage:"url("+props.obj.img+")"}}></div> : null}
            
            {(obj.sms_code) ? 
            <Label size="tiny" color="yellow">
              <Icon name='hashtag' />
              <Label.Detail>{obj.sms_code}</Label.Detail>
            </Label> : null}
            
            {lastSMS}
            
            {(obj.description) ? <Message>
              <Message.Header as="h3">Description</Message.Header>
              {obj.description}
            </Message> : null}
            {(obj.sms_message) ? <Message>
              <Message.Header>SMS Message</Message.Header>
              {obj.sms_message}
            </Message> : null}
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button icon onClick={props.handleOpenEdit.bind(this, obj)}>
            <Icon name='edit' />
          </Button>
          <Button icon onClick={props.handleQR}>
            <Icon name='qrcode' />
          </Button>
        </Card.Content>
      </Card>
    )
  }
  return (
    <div className="templateBox">
      {comp}
    </div>
  )
}

class AllPromos extends Component {
  constructor(props){
    super(props);
    this.state = {
      editid:null,
      prev:null,
      newpromo:false,
      showtags:true,
      info:[],
      curtag:"",
      autosync:false,
      cur_sms_code:null,
      qrheight:150
    }
    
    this.title = "";
    this.description = "";
    this.sms_code = "";
    this.sms_message = "";
    this.display = 1;
    this.order = this.props.promos.length+1;
    
    this.eimg = null;
    this.etitle = "";
    this.edescription = "";
    this.esms_code = "";
    this.esms_message = "";
    this.eorder = 1;
    this.edisplay = null;
    
    this.files = null;
    this.compfileobj = null;
    this.blob = null;
    this.rtimer = null;
  }
  
  componentWillMount(){
    if(this.props.nofetch && this.props.nofetch === true){
      return false;
    }
    this.props.dispatch(ReadEventsPromo(this.props.events_id));
    this.props.dispatch(ReadReceivesEvents({
      events_id:this.props.events_id,
      type:2
    }));
  }
  
  componentWillUnmount(){
    if(this.sync !== null){
      clearInterval(this.sync);
    }
  }
  
  //img files
  handleFile=(evt, data)=>{
    //
    this.files = evt.target.files;
    var reader = new FileReader();

    reader.onload = (e)=>{
      //$('#blah').attr('src', e.target.result);
      compress(e.target.result, this.files[0].name, (dataurl, compfileobj, blob)=>{
        this.compfileobj = compfileobj;
        this.blob = blob;
        this.setState({
          prev:dataurl
        });
      });
      
    }
    //console.log(this.files);
    reader.readAsDataURL(this.files[0]);
  }
  
  handleInsertObj=(type, evt, data)=>{
    switch(type){
      case 1:
        this.title = data.value;
        break;
      case 2:
        this.sms_code = data.value;
        break;
      case 3:
        this.order = data.value;
        break;
      case 4:
        this.description = data.value;
        break;
      case 5:
        this.sms_message = data.value;
        break; 
      case 6:
        this.display = (data.checked)?1:0;;
        break;
      default:
        break;
    }
  }
  handleInsert=()=>{
    this.props.dispatch(InsertEventsPromo({
      title:this.title,
      description:this.description,
      events_id:this.props.events_id,
      sms_code:this.sms_code,
      sms_message:this.sms_message,
      display:this.display,
      porder:this.order
    }, (status)=>{
      if(status){
        this.setState({
          newpromo:false
        })
      }
    }))
  }
  
  handleDelete=()=>{
    this.props.dispatch(DeleteEventsPromo({
      id:this.state.editid
    }, ()=>{
      this.handleOpenEdit(-1);
      this.props.dispatch(ReadEventsPromo(this.props.events_id));
    }))
  }
  
  handleActivate=(obj)=>{
    this.props.dispatch(UpdateEventsPromo({
      id:obj.id,
      active:(obj.active === 0) ? 1 : 0
    }, ()=>{
      this.props.dispatch(ReadEventsPromo(this.props.events_id));
    }))
  }
  
  handleOpenEdit=(obj)=>{
    if(obj === -1){
      this.ResetEdit();
      this.setState({
        editid:obj
      })
    } else {
      this.eimg = obj.img;
      this.etitle = obj.title;
      this.edescription = obj.description;
      this.esms_code = obj.sms_code;
      this.esms_message = obj.sms_message;
      this.eorder = obj.porder;
      this.edisplay = obj.display;
      this.setState({
        editid:obj.id
      })
    }
    
  }
  
  ResetEdit=()=>{
    this.eimg = null;
    this.etitle = "";
    this.edescription = "";
    this.esms_code = "";
    this.esms_message = "";
    this.eorder = 1;
    this.edisplay = null;
    
    this.files = null;
    this.compfileobj = null;
    this.setState({
      prev:null
    });
  }
  
  handlePromoEdit=async ()=>{
    
    if(this.compfileobj){
      var cid = (this.props.cid)?"cli"+this.props.cid:"clientid";
      var data = {
        name:"clients/"+cid+"/events/ev"+this.props.events_id+"/promo/promo"+this.state.editid,
        file:this.compfileobj,
        blob:this.blob
      }
      
      var json = await awsfetch(data);
      if(json.location){
        this.props.dispatch(UpdateEventsPromo({
          id:this.state.editid,
          title:this.etitle,
          description:this.edescription,
          sms_code:this.esms_code,
          sms_message:this.esms_message,
          display:this.edisplay,
          porder:this.eorder,
          img:json.location
        }, (data)=>{
          //console.log(data);
          this.handleOpenEdit(-1);
          this.props.dispatch(ReadEventsPromo(this.props.events_id));
        }));

      } else {
        alert("Only Images Allowed!");
      }
    } else {
      this.props.dispatch(UpdateEventsPromo({
        id:this.state.editid,
        title:this.etitle,
        description:this.edescription,
        sms_code:this.esms_code,
        sms_message:this.esms_message,
        display:this.edisplay,
        porder:this.eorder
      }, (data)=>{
        //console.log(data);
        this.handleOpenEdit(-1);
        this.props.dispatch(ReadEventsPromo(this.props.events_id));
      }));
    }
  }
  
  handleEditObj=(type, evt, data)=>{
    //console.log(type, data);
    switch(type){
      case 1:
        this.etitle = data.value;
        break;
      case 2:
        this.esms_code = data.value;
        break;
      case 3:
        this.eorder = data.value;
        break;
      case 4:
        this.edescription = data.value;
        break;
      case 5:
        this.esms_message = data.value;
        break;
      case 6:
        this.edisplay = (data.checked)?1:0;
        break;
      default:
        break;
    }
    console.log(this.edisplay, data.checked);
    //console.log(evt, data, type, this.ename, this.emsg);
  }
  
  handleOrder=(num, obj, evt, data)=>{
    this.props.dispatch(UpdateEventsPromo({
      id:obj.id,
      porder:obj.porder+num
    }, (data)=>{
      //console.log(data);
      this.props.dispatch(ReadEventsPromo(this.props.events_id));
    }));
  }
  
  handleInfo=(tagobj, i)=>{
    this.setState({
      info:(this.state.info.length <= 0 || i != this.state.curtag) ? tagobj[i] : [],
      curtag:(this.state.info.length <= 0 || i != this.state.curtag) ? i : ""
    });
  }
  
  handleQR=(code)=>{
    this.setState({
      cur_sms_code:code
    })
  }
  
  startSMS=(arr, type)=>{
    //console.log(this.props.users);
    //return false;
    var users_id = [],
        mobiles = [];
    
    switch (type){
      case 1:
        for(var i=0; i<arr.length; i++){
          var uobj = this.props.usersByMobile[arr[i].mobile];
          if(uobj.optin === 1 && mobiles.indexOf(uobj.mobile) === -1){
            users_id.push(uobj.uid);
            mobiles.push(uobj.mobile);
          }
        }
        break;
        
      case 2:
        for(var i=0; i<arr.length; i++){
          var uobj = this.props.usersByMobile[arr[i].mobile];
          if(mobiles.indexOf(uobj.mobile) === -1){
            users_id.push(uobj.uid);
            mobiles.push(uobj.mobile);
          }
        }
        break;
        
      case 3:
        break;
        
      default:
        break;
    }
    
    var obj = {
      mode:2,
      type:1,
      users_id:users_id,
      mobiles:mobiles,
      blasts:1,
      events_id:this.props.events_id,
      from:this.props.mobile
    };
    
    this.props.dispatch(ChangeSMSMode(obj));
  }
  
  syncFeedback=(ev, data)=>{
    this.setState({
      autosync:data.checked
    });
    
    if(data.checked){
      this.sync = setInterval(()=>{
        this.props.dispatch(ReadReceivesEvents({
          events_id:this.props.events_id,
          type:2,
          load:false
        }));
      }, 5000)
    } else {
      if(this.sync){
        clearInterval(this.sync);
        this.sync = null;
      }
    }
  }
  
  render() {
    //
    //console.log(this.props.receives);
    let arr = this.props.promos || [];
    
    var tagobj = {};
    for(var i=0; i<this.props.receives.length; i++){
      var robj = this.props.receives[i];
      if(robj.type === 2){
        var tag = robj.message.split(" ");
        if(!tagobj[tag[0]]){
          tagobj[tag[0]] = [];
        }

        tagobj[tag[0]].push({
          mobile:robj.mobile,
          msg:robj.message,
          time:new Date(robj.time).toLocaleString("en-US")
        });
      }
    }
    
    let AllPromos = arr.map((obj,i)=>{
      var isEdit = false,
          prev = false;
      if(obj.id===this.state.editid){
        isEdit = true;
        prev = this.state.prev;
      }
      
      return (
        <PromoCard 
          key={i} 
          obj={obj}
          handleOpenEdit={this.handleOpenEdit}
          handleEditObj={this.handleEditObj}
          handlePromoEdit={this.handlePromoEdit}
          handleDelete={this.handleDelete}
          handleOrder={this.handleOrder}
          handleFile={this.handleFile}
          handleActivate={this.handleActivate}
          handleStats={this.handleStats}
          handleQR={this.handleQR.bind(this, obj.sms_code)}
          isEdit={isEdit}
          prev={prev}
          tagobj={tagobj}
          usersByMobile={this.props.usersByMobile}
          />
      )
    });
    
    var AllTags = [],
        AllTagInfo = [],
        InfoComp = null;
    
    if(this.state.showtags){
      for(var i in tagobj){
        AllTags.push(<Label className="boomTags" style={{cursor:"pointer"}} color={(this.state.curtag === i) ? "yellow" : "grey"} onClick={this.handleInfo.bind(this, tagobj, i)} key={i}>{i} ({tagobj[i].length})</Label>)
      }
      
      if(this.state.info.length > 0 && this.state.curtag){
        AllTagInfo = this.state.info.map((obj, i)=>{
          return (
            <Message key={i}>
              <Label color="blue" style={{marginRight:10}}>
                {(this.props.usersByMobile[obj.mobile] && this.props.usersByMobile[obj.mobile].optin === 1) ? <Icon name='check' color="green" /> : null}
                {obj.mobile}
                {(this.props.usersByMobile[obj.mobile]) ? " - "+this.props.usersByMobile[obj.mobile].fullname:null}
              </Label> - {obj.msg}<span className="dateDisp"> - {obj.time}</span>
            </Message>
          )
        })
        
        InfoComp = (
          <div style={{marginTop:20}}>
            <Button icon size="tiny" color="teal" onClick={this.startSMS.bind(this, tagobj[this.state.curtag], 1)}>
              <Icon name='talk' /> All Opt-in
            </Button>
            <Button icon size="tiny" color="blue" onClick={this.startSMS.bind(this, tagobj[this.state.curtag], 2)} >
              <Icon name='talk' /> All
            </Button>
            <Button icon size="tiny" color="blue" onClick={this.startSMS.bind(this, null, 3)} >
              <Icon name='talk' /> One
            </Button>
            {AllTagInfo}
          </div>
        )
      }
    }
    
    return (
      <div className="boomFilter">
        <Checkbox 
          className="boomFilterCheckbox"
          toggle
          label="New Promo"
          checked={this.state.newpromo}
          onChange={(ev,data) => {this.setState({newpromo: data.checked})}}
        />
        <Checkbox 
          className="boomFilterCheckbox"
          toggle
          label="Show Tags"
          checked={this.state.showtags}
          onChange={(ev,data) => {this.setState({showtags: data.checked})}}
        />
        <Checkbox
          className="boomFilterCheckbox"
          toggle
          label="Auto Sync"
          checked={this.state.autosync}
          onChange={this.syncFeedback}
        />
        <Button className="boomFilterCheckbox" icon onClick={this.handleQR.bind(this, this.props.promos)}>
          <Icon name='qrcode' />
        </Button>
        {(this.state.showtags) ? <div style={{padding:10, backgroundColor:"#FFF", marginTop:10, borderRadius:5}}>
          {AllTags}
          {InfoComp}
        </div> : null}
        {(this.state.newpromo) ? <div style={{padding:10, backgroundColor:"#FFF", marginTop:10, borderRadius:5}}>
          <Form>
            <Checkbox defaultChecked={true} onChange={this.handleInsertObj.bind(this, 6)} label="Display to Public"/>
            <Form.Group inline>
              <Form.Input fluid onChange={this.handleInsertObj.bind(this, 1)} placeholder="eg: Daily Special!" label="Promo Title" style={{minWidth:"300px"}}/>
              <Form.Input fluid onChange={this.handleInsertObj.bind(this, 2)} placeholder="eg: dailyspecial" label="Promo SMS Code #"/>
              <Form.Input fluid onChange={this.handleInsertObj.bind(this, 3)} placeholder={this.props.promos.length+1} label="Order" />
            </Form.Group>
            <Form.TextArea onChange={this.handleInsertObj.bind(this, 4)} placeholder="eg: Look four our specialized Brazilian coffee. It helps enhance productivity with brain power." label="Promo Description"/>
            <Form.TextArea onChange={this.handleInsertObj.bind(this, 5)} placeholder="eg: Our daily special includes coffee, tea, mugs and much more. Learn more here [link]" label="Promo SMS Message"/>
          </Form>
          <Divider hidden />
          <Button icon labelPosition="left" onClick={this.handleInsert}><Icon name="add" />Add Promo</Button>
        </div> : null}
        <Divider hidden />
        <div style={{display:"flex", flexWrap:"wrap"}}>
          {AllPromos}
        </div>
        {(this.state.cur_sms_code) ? <div className="qrcont" style={(Array.isArray(this.state.cur_sms_code)) ? {justifyContent:"flex-start"}:{}}>
          <div className="qrcontrols">
            <Button icon onClick={()=>{this.setState({qrheight:this.state.qrheight+10})}}>
              <Icon name="plus" />
            </Button>
            <Button icon>
              <Icon name="minus" onClick={()=>{this.setState({qrheight:this.state.qrheight-10})}}/>
            </Button>
            <Button icon color="red" onClick={this.handleQR.bind(this, null)}>
              <Icon name="close" />
            </Button>
          </div>
          {(Array.isArray(this.state.cur_sms_code)) ? this.state.cur_sms_code.map((obj,i)=>{
            return (
            <div className="qrbox" key={i}>
              <QRCode value={"SMSTO:+1"+this.props.mobile+":#"+obj.sms_code} className="qrcode" size={this.state.qrheight}/>
              <div className="qrinfo" style={{height:this.state.qrheight, width:this.state.qrheight*1.2}}>
                <div className="qrprompt">To Learn More... Text</div>
                <div className="qrhash">#{obj.sms_code}</div>
                <div className="qrmobile">
                  <div>To</div>
                  <div>1-{this.props.mobile.slice(0,3)+"-"+this.props.mobile.slice(3,6)+"-"+this.props.mobile.slice(6)}</div>
                </div>
              </div>
            </div>
            )
          }) : <div className="qrbox">
            <QRCode value={"SMSTO:+1"+this.props.mobile+":#"+this.state.cur_sms_code} className="qrcode" size={this.state.qrheight}/>
            <div className="qrinfo" style={{height:this.state.qrheight, width:this.state.qrheight*1.2}}>
              <div className="qrprompt">To Learn More... Text</div>
              <div className="qrhash">#{this.state.cur_sms_code}</div>
              <div className="qrmobile">
                <div>To</div>
                <div>1-{this.props.mobile.slice(0,3)+"-"+this.props.mobile.slice(3,6)+"-"+this.props.mobile.slice(6)}</div>
              </div>
            </div>
          </div>}
        </div> : null}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    events_id: state.Events.id,
    promos: state.Events.promos,
    cid:state.Clients.id,
    receives:state.Events.receives,
    usersByMobile:state.Events.usersByMobile,
    mobile: state.Events.mobile
  };
}

export default connect(mapStateToProps)(AllPromos);