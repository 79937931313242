import React, { Component } from 'react';
import {connect} from 'react-redux';
import {InsertQuestion, ReadQuestions} from "../../actions/surveys";
import { Card, Icon, Input, Button, Divider, Form } from 'semantic-ui-react'

class AddQuestion extends Component {
  constructor(props){
    super(props);
    this.text="";
    this.options=[];
    this.order="";
    this.answers_desc="";
    this.state={
      ninputs:3,
      dName:["Black Smith Love", "Free for all", "Lovers in Paradise", "Farooq Nevada"]
    }
  }
  
  handleText=(evt, data)=>{
    //
    this.text=data.value;
  }
  
  handleOrder=(evt, data)=>{
    //
    this.order=data.value;
  }
  
  handleAnswersDesc=(evt, data)=>{
    //
    this.answers_desc = data.value;
  }
  
  handleOps=(ind, evt, data)=>{
    //
    if(!this.options[ind]){
      this.options[ind]= {
        text:"",
        pts:0
      }
    }
    
    this.options[ind].text = data.value;
  }
  
  handlePts=(ind, evt, data)=>{
    if(!this.options[ind]){
      this.options[ind]= {
        text:"",
        pts:0
      }
    }
    
    this.options[ind].pts = data.value;
  }
  
  handleNInputs=()=>{
    this.setState({
      ninputs:this.state.ninputs+1
    })
  }
  
  insert=()=>{
    this.props.dispatch(InsertQuestion({
      text:this.text,
      qorder:(this.order !== "") ? this.order : this.props.questions.length+1,
      surveys_id:this.props.id,
      options:this.options,
      answers_desc:this.answers_desc
    }));
  }
  
  render() {
    
    var allinps = [];
    
    for(var i = 0; i<=this.state.ninputs; i++){
      var obj = this.state.ninputs[i];
      allinps.push(
        <span key={i}>
          <Input onChange={this.handleOps.bind(this, i)} placeholder={(this.state.dName[i])?this.state.dName[i]:"New Option"} label={"Option "+(i+1)}/>
          <Input type="number" onChange={this.handlePts.bind(this, i)} label="Points" placeholder="0" />
          <Divider hidden />
        </span>
      )
    }
    return (
      <Card fluid={true}>
        <Card.Content>
          <Card.Header>
            <Input onChange={this.handleText} fluid placeholder="eg: What is this fundraiser's name?" label="Survey Question"/>
          </Card.Header>
          <Divider hidden />
          <Card.Description>
            <Input onChange={this.handleOrder} placeholder={(this.props.questions.length+1)} label="Order" />
            <Divider hidden />
            {allinps}
            <Divider hidden />
            <Form>
              <Form.Group inline>
                <Form.TextArea onChange={this.handleAnswersDesc} placeholder={"A is the reason why everything is so great"} label="Description For The Answer" />
              </Form.Group>
            </Form>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <a>
            <Button icon labelPosition='left' onClick={this.handleNInputs}>
              <Icon name='add' />
              Options
            </Button>
            <Button icon labelPosition='left' onClick={this.insert}>
              <Icon name='checkmark' />
              Create Question
            </Button>
          </a>
        </Card.Content>
      </Card>
    )
  }
}

function mapStateToProps(state) {
  return {
    id:state.Surveys.id,
    questions:state.Surveys.questions
  };
}

export default connect(mapStateToProps)(AddQuestion);