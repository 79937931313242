import React, { Component } from 'react';
import {connect} from 'react-redux';
import {ChangeMode} from "../../actions/dashboard";
import {ChangeMenu} from "../../actions/menu";
import AddClient from "./AddClient";
import AllClients from "./AllClients";
import {Segment} from 'semantic-ui-react'

class Home extends Component {
  
  componentDidMount(){
    var menuItems = [
      {
        icon:"id card",
        active:1,
        content:"Clients",
        click:()=>{
          this.props.dispatch(ChangeMode(1))
        }
      },
      {
        icon:"add",
        active:2,
        content:"New Client",
        click:()=>{
          this.props.dispatch(ChangeMode(2))
        }
      }
      /*{
        sub:{
          icon:"add",
          text:"New",
          items:[
            {
              icon:"add",
              active:3,
              content:"Survey",
              click:()=>{
                this.props.dispatch(ChangeMode(3))
              }
            },
            {
              icon:"add",
              active:4,
              content:"Prize",
              click:()=>{
                this.props.dispatch(ChangeMode(4))
              }
            }
          ]
        } 
      }*/
    ];
    this.props.dispatch(ChangeMenu(menuItems, "dashboard"));
  }
  
  render() {
    let comp = null;
    
    switch(this.props.mode){
      
      case 1:
        comp = <AllClients />;
        break;
      case 2:
        comp = <AddClient />
        break;
      default:
        break;
    }
    
    return (
      <Segment padded className="ctrlSeg">
        {comp}
      </Segment>
    )
  }
}

function mapStateToProps(state) {
  return {
    load: state.Dashboard.load,
    mode: state.Dashboard.mode
  };
}

export default connect(mapStateToProps)(Home);