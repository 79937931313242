import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Reset, ChangeMode} from "../../actions/events";
import {ChangeMenu} from "../../actions/menu";
import EventInfo from "./EventInfo";
import EditEvent from "./EditEvent";
import AddSurvey from "./AddSurvey";
import AddPrize from "./AddPrize";
import AllSignins from "./AllSignins";
import AllUsers from "./AllUsers";
import AllRedeems from "./AllRedeems";
import AllBlasts from "./AllBlasts";
import AllTemplates from "./AllTemplates";
import AllFeedback from "./AllFeedback";
import AllPromos from "./AllPromos";
import AllEventAdmin from "./AllEventAdmin";
import {Segment} from 'semantic-ui-react';

class Home extends Component {
  componentWillUnmount(){
    this.props.dispatch(Reset());
  }
  
  componentDidMount(){
    var menuItems = [
      {
        icon:"info",
        active:1,
        content:"Info",
        click:()=>{
          this.props.dispatch(ChangeMode(1))
        }
      },
      {
        icon:"setting",
        active:2,
        content:"Settings",
        click:()=>{
          this.props.dispatch(ChangeMode(2))
        }
      },
      {
        icon:"user secret",
        active:66,
        content:"Admins",
        click:()=>{
          this.props.dispatch(ChangeMode(66))
        }
      },
      {
        icon:"users",
        active:6,
        content:"Users",
        click:()=>{
          this.props.dispatch(ChangeMode(6))
        }
      },
      {
        sub:{
          icon:"comments",
          text:"SMS",
          items:[
            {
              icon:"comments",
              active:8,
              content:"Blasts",
              click:()=>{
                this.props.dispatch(ChangeMode(8))
              }
            },
            {
              icon:"comments",
              active:9,
              content:"Feedbacks",
              click:()=>{
                this.props.dispatch(ChangeMode(9))
              }
            },
            {
              icon:"comments",
              active:10,
              content:"Templates",
              click:()=>{
                this.props.dispatch(ChangeMode(10))
              }
            },
            {
              icon:"comments",
              active:11,
              content:"Promos",
              click:()=>{
                this.props.dispatch(ChangeMode(11))
              }
            }
          ]
        }
      },
      {
        sub:{
          icon:"add",
          text:"New",
          items:[
            {
              icon:"add",
              active:4,
              content:"Reward",
              click:()=>{
                this.props.dispatch(ChangeMode(4))
              }
            },
            {
              icon:"add",
              active:3,
              content:"Survey",
              click:()=>{
                this.props.dispatch(ChangeMode(3))
              }
            }
          ]
        } 
      },
      {
        sub:{
          icon:"bar chart",
          text:"Stats",
          items:[
            {
              icon:"bar chart",
              active:5,
              content:"Sign Ins",
              click:()=>{
                this.props.dispatch(ChangeMode(5))
              }
            },
            {
              icon:"bar chart",
              active:7,
              content:"Redeems",
              click:()=>{
                this.props.dispatch(ChangeMode(7))
              }
            }
          ]
        }
      }
    ];
    this.props.dispatch(ChangeMenu(menuItems, "events"));
  }
  
  render() {
    let comp = null;
    
    switch(this.props.mode){
      
      case 1:
        comp = <EventInfo />;
        break;
      case 2:
        comp = <EditEvent />;
        break;
      case 3:
        comp = <AddSurvey />;
        break;
      case 4:
        comp = <AddPrize />
        break;
      case 5:
        comp = <AllSignins filter={true} />
        break;
      case 6:
        comp = <AllUsers filter={true} />
        break;
      case 66:
        comp = <AllEventAdmin />
        break;
      case 7:
        comp = <AllRedeems filter={true} />
        break;
      case 8:
        comp = <AllBlasts filter={true} />
        break;
      case 9:
        comp = <AllFeedback filter={true} />
        break;
      case 10:
        comp = <AllTemplates />
        break;
      case 11:
        comp = <AllPromos />
        break;
      default:
        break;
    }
    
    return (
      <Segment padded className="ctrlSeg">
        {comp}
      </Segment>
    )
  }
}

function mapStateToProps(state) {
  return {
    load: state.Events.load,
    mode: state.Events.mode
  };
}

export default connect(mapStateToProps)(Home);