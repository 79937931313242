import {reducers} from "../types";
//mode 0 = view, 1 = add
const initialState = {
  mode:1,
  id:null,
  smstype:0,
  blasts:0,
  users_id:[],
  mobiles:[],
  events_id:null,
  sms_receive_id:null,
  from:null,
  callback:null,
  prizes_id:null,
  surveys_id:null,
  questions_id:null
};


export default function prizes(state = initialState, action){
  let obj = Object.assign({}, state);
  switch(action.type) {
    case reducers.SMS_CHANGE_MODE:
      obj.mode = action.mode;
      obj.smstype = action.smstype;
      obj.users_id = action.users_id;
      obj.mobiles = action.mobiles;
      obj.events_id = action.events_id;
      obj.prizes_id = action.prizes_id;
      obj.surveys_id = action.surveys_id;
      obj.questions_id = action.questions_id;
      obj.blasts = action.blasts;
      obj.callback = action.callback;
      obj.sms_receive_id = action.sms_receive_id;
      obj.from = action.from;
      return obj;
    case reducers.SMS_RESET:
      return initialState;
    default:
      return state;
  }
}