import React, { Component } from 'react';
import {connect} from 'react-redux';
import {ChangeMode} from "../../actions/surveys";
import AllQuestions  from "./AllQuestions.js";
import {Card, Icon, Button, Header, Label, Divider} from 'semantic-ui-react'

class SurveyInfo extends Component {
  
  edit=()=>{
    this.props.dispatch(ChangeMode(2));
  }
  
  render() {
    
    return (
      <div>
        <Card fluid>
          <Card.Content>
            {this.props.popup === 1 ? <Label color="yellow" corner="right" size="large"></Label> : null}
            <Card.Header>
              {this.props.title}
            </Card.Header>
            <Card.Description>
              {(this.props.img) ? <div className="promo" style={{maxWidth:"200px", maxHeight:"200px", backgroundImage:"url("+this.props.img+")"}}></div> : null}
              <br />
              {this.props.popup === 1 ? <Label color="yellow" size="tiny">First Time Popup</Label> : null}
              {this.props.reveal === 1 ? <Label color="teal" size="tiny">Reveal Answers</Label> : null}
              <Divider hidden />
              {this.props.description}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button icon labelPosition='left' onClick={this.edit}>
              <Icon name='edit' />
              Edit Survey
            </Button>
          </Card.Content>
        </Card>
        <Header as='h3'>All Questions</Header>
        <AllQuestions />
      </div>
    )
  }
}

function mapStateToProps(state) {
  
  return {
    id:state.Surveys.id,
    title:state.Surveys.title,
    img:state.Surveys.img,
    description:state.Surveys.description,
    popup:state.Surveys.popup,
    reveal:state.Surveys.reveal
  };
}

export default connect(mapStateToProps)(SurveyInfo);