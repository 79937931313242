import {reducers} from '../types';
//mode 0 = view, 1 = add
const initialState = {
  mode:1,
  id:null,
  load:false,
  name:"",
  slug:"",
  stime:"",
  etime:"",
  hash:"",
  img:"",
  description:"",
  welcome:"",
  type:"",
  signin_pts:0,
  security_code:0,
  employee_code:null,
  signin_msg:"",
  default_msg:"",
  optin_msg:"",
  profile_msg:"",
  optin_code:"",
  mobile:"",
  mobilearr:[],
  session_active:0,
  newsurvey:{},
  newprize:{},
  prizes:[],
  surveys:[],
  events_admin:[],
  users:[],
  usersByMobile:{},
  signins:[],
  redeems:[],
  messages:[],
  templates:[],
  promos:[],
  sends:[],
  receives:[],
  promo_imgs:[],
  groups:[],
  groupusers:[]
};


export default function events(state = initialState, action){
  let obj = Object.assign({}, state);
  switch(action.type) {
    case reducers.EVENT_CHANGE_MODE:
      obj.mode = action.mode;
      return obj;
    case reducers.EVENT_READ:
      obj.id = action.event.id;
      obj.name = action.event.name;
      obj.slug = action.event.slug;
      obj.hash = action.event.hash;
      obj.stime = action.event.start_time;
      obj.etime = action.event.end_time;
      obj.description = action.event.description;
      obj.welcome = action.event.welcome;
      obj.type = action.event.type;
      obj.signin_pts = action.event.signin_pts;
      obj.img = action.event.img;
      obj.security_code = action.event.security_code;
      obj.employee_code = action.event.employee_code;
      obj.signin_msg = action.event.signin_msg;
      obj.default_msg = action.event.default_msg;
      obj.optin_msg = action.event.optin_msg;
      obj.profile_msg = action.event.profile_msg;
      obj.optin_code = action.event.optin_code;
      obj.session_active = action.event.session_active;
      obj.mobilearr = action.event.mobile.split(",");
      //if(mobiles.length)
      if(obj.mobilearr.length > 0){
        obj.mobile = obj.mobilearr[0];
      }
      //console.log(obj.mobile);
      return obj;
    case reducers.EVENT_READ_ALL:
      obj.surveys = (action.all.surveys) ? action.all.surveys.data : [];
      obj.users = (action.all.users) ? action.all.users.data : [];
      obj.groups = (action.all.groups) ? action.all.groups.data : [];
      obj.groupusers = (action.all.groups && action.all.groups.groupusers) ? sortGroupUsers(action.all.groups.groupusers) : [];
      obj.usersByMobile = sortUsers(obj.users);
      obj.prizes = (action.all.prizes && action.all.prizes.data) ? action.all.prizes.data.map((obj2, i)=>{
        switch(obj2.type){
          case 1:
            obj2.typename="Regular"
            break;
          case 2:
            obj2.typename="One-Time"
            break;
          case 3:
            obj2.typename="Chance"
            break;
          case 4:
            obj2.typename="One-Time Chance"
            break;
        }
        return obj2;
      }) : [];
      obj.prizes = obj.prizes.sort(ptsSort);
      obj.prizes = obj.prizes.sort(typeSort);
      obj.signins = (action.all.surveys) ? action.all.signins.data : [];
      obj.redeems = (action.all.redeems && action.all.redeems.data) ? action.all.redeems.data.map((obj)=>{return obj}) : [];
      return obj;
    case reducers.EVENT_UPDATE:
      obj.name = (action.event.name) ? action.event.name : obj.name;
      obj.slug = (action.event.slug) ? action.event.slug : obj.slug;
      obj.description = (action.event.description) ? action.event.description : obj.description;
      //obj.hash = action.event.hash;
      obj.stime = (action.event.start_time) ? action.event.start_time : obj.stime;
      obj.etime = (action.event.end_time) ? action.event.end_time : obj.etime;
      obj.welcome = (action.event.welcome) ? action.event.welcome : obj.welcome;
      obj.type = (action.event.description) ? action.event.type : obj.type;
      obj.signin_pts = (action.event.signin_pts) ? action.event.signin_pts : obj.signin_pts;
      obj.img = (action.event.img) ? action.event.img+"?"+Math.random() : obj.img;
      obj.security_code = (action.event.security_code) ? action.event.security_code : obj.security_code;
      obj.employee_code = (action.event.employee_code) ? action.event.employee_code : obj.employee_code;
      obj.optin_code = (action.event.optin_code) ? action.event.optin_code : obj.optin_code;
      obj.signin_msg = (action.event.signin_msg || action.event.signin_msg==="") ? action.event.signin_msg : obj.signin_msg;
      obj.default_msg = (action.event.default_msg || action.event.default_msg==="") ? action.event.default_msg : obj.default_msg;
      obj.optin_msg = (action.event.optin_msg || action.event.optin_msg==="") ? action.event.optin_msg : obj.optin_msg;
      obj.profile_msg = (action.event.profile_msg || action.event.profile_msg==="") ? action.event.profile_msg : obj.profile_msg;
      obj.session_active = (action.event.session_active || action.event.session_active===0) ? action.event.session_active : obj.session_active;
      //console.log(obj.img);
      return obj;
    case reducers.EVENT_SURVEY_INSERT:
      obj.mode = 1;
      obj.newsurvey = action.survey;
      return obj;
    case reducers.EVENT_SURVEYS_READ:
      obj.surveys = action.surveys;
      return obj;
    case reducers.EVENT_UPDATE_SURVEYS:
      obj.surveys = action.surveys.map((obj)=>{return obj});
      return obj;
    case reducers.EVENT_USERS_READ:
      obj.users = action.users;
      obj.usersByMobile = sortUsers(obj.users);
      return obj;
    case reducers.EVENT_PRIZE_INSERT:
      obj.mode = 1;
      obj.newprize = action.prize;
      return obj;
    case reducers.EVENT_PRIZES_READ:
      //console.log(action.prizes);
      obj.prizes = (action.prizes) ? action.prizes.map((obj2, i)=>{
        switch(obj2.type){
          case 1:
            obj2.typename="Regular"
            break;
          case 2:
            obj2.typename="One-Time"
            break;
          case 3:
            obj2.typename="Chance"
            break;
          case 4:
            obj2.typename="One-Time Chance"
            break;
        }
        
        return obj2;
      }) : [];
      obj.prizes = obj.prizes.sort(ptsSort);
      obj.prizes = obj.prizes.sort(typeSort);
      return obj;
    case reducers.EVENT_PRIZES_UPDATE:
      obj.prizes = action.prizes.map((obj)=>{return obj});
      return obj;
    case "REQUEST_SEND_SMS":
      obj.load = action.load;
      return obj;
    case "FINISH_SEND_SMS":
      obj.load = action.load;
      return obj;
    case reducers.EVENT_SIGNINS_READ:
      obj.signins = action.signins
      return obj;
    case reducers.EVENT_PRIZESUSERS_READ:
      obj.redeems = (action.redeems) ? action.redeems.map((obj)=>{return obj}) : [];
      return obj;
    case reducers.EVENT_MESSAGES_READ:
      obj.messages = (action.messages) ? action.messages.map((obj)=>{
        //console.log(obj.receives);
        return obj;
      }) : [];
      return obj;
    
    case reducers.EVENT_FEEDBACKS_READ:
      //console.log(action);
      obj.sends = action.sends;
      obj.receives = action.receives;
      return obj;
      
    /*=================================TEMPLATES==================================*/  
    case reducers.EVENT_TEMPLATES_READ:
      obj.templates = action.templates;
      return obj;
    /*=================================TEMPLATES END=================================*/ 
    
    /*=================================PROMOS==================================*/  
    case reducers.EVENT_EVENTS_PROMO_READ:
      ///may delete after
      obj.promos = action.promos;
      return obj;
    /*=================================PROMOS END=================================*/
      
    /*=================================GROUPS==================================*/  
    case reducers.EVENT_GROUPS_READ:
      obj.groups = action.groups;
      obj.groupusers = sortGroupUsers(action.groupusers);
      //console.log(obj.groupusers);
      return obj;
      
    /*=================================GROUPS END=================================*/
      
    /*=================================GROUPS==================================*/  
    case reducers.EVENT_EA_READ:
      obj.events_admin = action.events_admin;
      //console.log(obj.groupusers);
      return obj;
      
    /*=================================GROUPS END=================================*/
    case reducers.EVENT_RESET:
      obj.mode = 1;
      return obj;
    case reducers.LOAD_STORED:
      return action.storedState.Events;
    default:
      return state;
  }
}

function ptsSort(a, b) {
    var apts = parseInt(a.pts, 10);
    var bpts = parseInt(b.pts, 10);
    return ((apts < bpts) ? -1 : ((apts > bpts) ? 1 : 0));
}

function typeSort(a, b) {
    var apts = parseInt(a.type, 10);
    var bpts = parseInt(b.type, 10);
    return ((apts > bpts) ? -1 : ((apts < bpts) ? 1 : 0));
}

function sortUsers(users){
  //console.log(users);
  if(!Array.isArray(users)){
    return {};
  }
  var obj = {};
  for(var i=0; i<users.length; i++){
    obj[users[i].mobile] = users[i];
  }
  
  return obj; 
}

//SUPER CONFUSING, but to save space, speed and data, I'm bubble sorting it by users_events id and by groups id in the same space and separating them
function sortGroupUsers(gu){
  var obj = {
    byusers:{},
    bygroups:{}
  };
  for(var i=0; i<gu.length; i++){
    var cobj = gu[i];
    
    //sort by users_events id
    if(!obj.byusers[cobj.users_events_id]){
      obj.byusers[cobj.users_events_id] = [];
    }
    
    //sort by group id
    if(!obj.bygroups[cobj.events_group_id]){
      obj.bygroups[cobj.events_group_id] = [];
    }
    
    obj.byusers[cobj.users_events_id].push(cobj.events_group_id);
    obj.bygroups[cobj.events_group_id].push(cobj.users_events_id);
  }
  
  return obj;
}