import {mfetch} from '../connect';
import {clients, reducers} from '../types';
import {Loading} from './admin';

export function ChangeMode(mode){
  return {
    type:reducers.PRIZE_CHANGE_MODE,
    mode
  }
}

export function Reset(){
  return {
    type:reducers.PRIZE_RESET
  }
}

export function ReadPrize(prize){
  return {
    type:reducers.PRIZE_READ,
    prize
  }
}

function FinishPrizeUpdate(prize){
  return {
    type:reducers.PRIZE_UPDATE,
    prize
  }
}

export function UpdatePrize(prize, callback){
  //
  //console.log(prize);
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"update", key:"prize", data:prize}});
    if(typeof callback === "function"){
      callback();
    }
    //console.log(json);
    if(json.data && json.data.length > 0){
      dispatch(FinishPrizeUpdate(json.data[0]));
    }
    dispatch(ChangeMode(1));
    dispatch(Loading(false));
  }
}