import React, { Component } from 'react';
import {connect} from 'react-redux';
import {InsertPrize} from "../../actions/events";
import { Card, Icon, Form, Divider, Checkbox } from 'semantic-ui-react'

class AddPrize extends Component {
  constructor(props){
    super(props);
    this.name = "";
    this.img = "";
    this.win = "";
    this.description = "";
    this.redeemable = 1;
    this.ptype = 1;
    this.pts = 0;
    this.odds = 0;
    this.repeats = 0;
    this.maxwin = 0;
    
    this.opts = [
      { key: 1, text: 'Regular', value: 1 },
      { key: 2, text: 'One-Time', value: 2 },
      { key: 3, text: 'Chance', value: 3 },
      { key: 4, text: 'One-Time Chance', value: 4 }
    ]
    
    this.state = {
      value:1
    }
  }
  
  handleName=(evt, data)=>{
    //
    this.name=data.value;
  }
  
  handleImg=(evt, data)=>{
    this.img=data.value;
  }
  
  handleWin=(evt, data)=>{
    this.win=data.value;
  }
  
  handleDesc=(evt, data)=>{
    this.description=data.value;
  }
  
  handleRedeemable=(evt, data)=>{
    this.description=(data.checked)?1:0;
  }
  
  handleMaxWin=(evt, data)=>{
    this.maxwin=data.value;
  }
  
  handlePts=(evt, data)=>{
    this.pts=data.value;
  }
  
  handleOdds=(evt, data)=>{
    this.odds=data.value;
  }
  
  handleType=(evt, data)=>{
    this.ptype=data.value;
    this.setState({
      value:data.value
    })
  }
  
  handleRepeats=(evt, data)=>{
    this.repeats=data.value;
  }
  
  insert=()=>{
    
    this.props.dispatch(InsertPrize({
      name:this.name,
      events_id:this.props.events_id,
      win_text:this.win,
      description:this.description,
      redeemable:this.redeemable,
      pts:this.pts,
      odds:this.odds,
      type:this.ptype,
      repeats:this.repeats,
      maxwin:this.maxwin
    }));
  }
  
  render() {
    
    return (
      <Form>
        <Card fluid={true}>
          <Card.Content>
            <Card.Header>
              <Form.Input onChange={this.handleName} placeholder="eg: New Ipad!" label="Reward Name"/>
            </Card.Header>
            <Card.Description>
              <Checkbox defaultChecked={true} onChange={this.handleRedeemable} label="Redeemable"/>
              <Form.Dropdown
                onChange={this.handleType}
                options={this.opts}
                label="Reward Type"
                placeholder='What kind of rewards?'
                selection 
                value={this.state.value}
              />

              <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
                <div style={{flex:"1 0 15%", marginRight:10}}>
                  <Form.Input type="number" onChange={this.handlePts} placeholder="100" label="Redeem Points"/>
                </div>

                {(this.state.value === 3  || this.state.value === 4) ? 
                  <div style={{flex:"1 0 15%", marginRight:10}}>
                    <Form.Input type="number" onChange={this.handleOdds} placeholder="0" label="1 in ## Chance to Win" />
                  </div>
                  : null
                }
                
                {(this.state.value === 3  || this.state.value === 4) ? 
                  <div style={{flex:"1 0 15%", marginRight:10}}>
                    <Form.Input type="number" onChange={this.handleMaxWin} placeholder="0" label="Max # of wins" />
                  </div>
                  : null
                }

                {(this.state.value === 2  || this.state.value === 4) ? 
                  <div style={{flex:"1 0 15%", marginRight:10}}>
                    <Form.Input type="number" onChange={this.handleRepeats} placeholder="0" label="Repeats Every # of Days" />
                  </div>
                  : null
                 }
              </div>
              <Divider hidden />
              <Form.Input onChange={this.handleWin} fluid placeholder="You've won the New Ipad!" label="Winner SMS"/>
              
              <Form.TextArea onChange={this.handleDesc} placeholder={"EG: Reward is a one of a kind special...."} label="Reward Description" />
            </Card.Description>
            <Divider hidden />
          </Card.Content>
          <Card.Content extra>
            <a>
              <Form.Button icon labelPosition='left' onClick={this.insert}>
                <Icon name='checkmark' />
                Create Reward
              </Form.Button>
            </a>
          </Card.Content>
        </Card>
      </Form>
    )
  }
}

function mapStateToProps(state) {
  return {
    newprize: state.Events.newprize,
    events_id:state.Events.id,
  };
}

export default connect(mapStateToProps)(AddPrize);