import {reducers} from "../types";
//mode 0 = view, 1 = add
const initialState = {
  mode: 1,
  clients:[],
  newclient:{},
  isadmin:false,
  load:false
};


export default function dashboard(state = initialState, action){
  let obj = Object.assign({}, state);
  switch(action.type) {
    case reducers.DASHBOARD_CHANGE_MODE:
      obj.mode = action.mode;
      return obj
    case reducers.DASHBOARD_CLIENT_INSERT:
      obj.newclient = action.client;
      return obj;
    case reducers.DASHBOARD_CLIENTS_READ:
      obj.clients = (action.clients)?action.clients:[];
      obj.isadmin = (action.isadmin)?action.isadmin:obj.isadmin;
      return obj;
    case reducers.LOAD_STORED:
      return action.storedState.Dashboard;
    default:
      return state;
  }
}