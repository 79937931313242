import React, { Component } from 'react';

import Dashboard from '../comps/Dashboard/Home';
import Client from '../comps/Client/Home';
import Event from '../comps/Event/Home';
import Survey from '../comps/Survey/Home';
import Prize from '../comps/Prize/Home';
import BMenu from "./BMenu";
import Bread from "./Bread";
import {ChangePage} from "../actions/admin";
import {CheckSession} from "../actions/admin";

import {connect} from 'react-redux';
import {Container, Grid, Dimmer, Divider} from 'semantic-ui-react';

class Admin extends Component {
  
  state={
    checksess:false
  }

  componentWillMount(){
    /*this.props.dispatch(CheckSession(()=>{
      this.setState({
        checksess:false
      })
    }))*/
  }
  
  changePage=(num)=>{
    this.props.dispatch(ChangePage(num));
  }

  render() {
    let comp = null;

    switch (this.props.page){
      case 1:
        comp = <Dashboard />
        break;
      case 2:
        comp = <Client />
        break;
      case 3:
        comp = <Event />
        break;
      case 4:
        comp = <Survey />
        break;
      case 5:
        comp = <Prize />
        break;
        
      
      default:
        break;
    }

    return (
      <Dimmer.Dimmable dimmed={true}>
        <Container fluid>
          <Grid>
            {/*
            <Grid.Row columns={2}>
              <Grid.Column width={12}>
                  <Bread />
              </Grid.Column>
              <Grid.Column width={4} textAlign="right">
                  Logo
              </Grid.Column>
            </Grid.Row>
            */}
            <Grid.Row>
              {/*
              <Grid.Column computer={4} mobile={16}>
                <Menu fluid vertical tabular>
                  <Menu.Item 
                    name='dashboard' 
                    active={this.props.page === 1} 
                    onClick={this.changePage.bind(this, 1)} 
                  />
                  <Menu.Item 
                    name='client'
                    disabled={this.props.client.id === null}
                    active={this.props.page === 2} 
                    onClick={this.changePage.bind(this, 2)} 
                  />
                  <Menu.Item 
                    name='event' 
                    disabled={this.props.event.id === null}
                    active={this.props.page === 3} 
                    onClick={this.changePage.bind(this, 3)} 
                  />
                  <Menu.Item 
                    name='survey' 
                    disabled={true} 
                    disabled={this.props.survey.id === null}
                    active={this.props.page === 4} 
                    onClick={this.changePage.bind(this, 4)} 
                  />
                </Menu>
              </Grid.Column>
              */}
              <Grid.Column computer={2} tablet={2} stretched>
                <BMenu />
              </Grid.Column>
              <Grid.Column textAlign="left" computer={14} tablet={14} mobile={16}>
                <Divider hidden />
                <Bread />
                {comp}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Dimmer.Dimmable>
    )
  }
}


function mapStateToProps(state) {
  return {
    page:state.Admin.page,
    client:state.Clients,
    event:state.Events,
    survey:state.Surveys
  };
}

export default connect(mapStateToProps)(Admin);