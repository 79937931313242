import React, { Component } from 'react';
import {connect} from 'react-redux';
import {ReadQuestions, ReadQuestion, ChangeMode, UpdateQuestion} from "../../actions/surveys";
import {Card, Icon, Button, Label, Grid, Message} from 'semantic-ui-react'

const QuestionsCard = (props)=>{
  
  let optcomp = null;
  if(props.opts){
    let filter = props.opts.filter((obj,i)=>{
      return (obj.text.length > 0);
    })
    optcomp = filter.map((obj,i)=>{
      return (
        <Label key={i} className="boomOpts" ribbon floating={false} active={(parseInt(obj.pts, 10)>0)}>
          <Icon name='options' />{obj.text}
        </Label>
      )
    });
  }
  
  return (
    <Card>
      <Card.Content>
        <Label attached="top right" size="tiny" color="grey">
          <Button disabled={props.obj.qorder <= 1} size="mini" icon onClick={props.updateOrderDown}>
            <Icon name='sort up' />
          </Button>
          <Button size="mini" icon onClick={props.updateOrderUp}>
            <Icon name='sort down' />
          </Button>
          <Icon name='sort' />
          {props.obj.qorder}
        </Label>
        <Card.Header>
          <div style={{marginTop:50}}></div>
          {props.obj.text}
        </Card.Header>
        <Card.Description>
          {(props.obj.img) ? <div className="promo" style={{maxWidth:"100px", maxHeight:"100px", backgroundImage:"url("+props.obj.img+")"}}></div> : null}
          {optcomp}
          {(props.obj.track===1) ? <Label color="purple"><i>Tracked...</i></Label> : null}
          {(props.obj.answers_desc)? <Message>
            <Label>Answer Description</Label>
            <Message.Content>
              {props.obj.answers_desc}
            </Message.Content>
          </Message> : null}
          {(props.obj.track_response)? <Message>
            <Label>SMS Response</Label>
            <Message.Content>
              {props.obj.track_response}
            </Message.Content>
          </Message> : null}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button icon labelPosition='left' onClick={props.onClick}>
          <Icon name='edit' />
          Edit
        </Button>
      </Card.Content>
    </Card>
  )
}

class AllQuestions extends Component {
  
  componentDidMount(){
    this.props.dispatch(ReadQuestions(this.props.surveys_id));
  }
  
  view=(obj, opts)=>{
    obj.options = opts;
    this.props.dispatch(ReadQuestion(obj));
  }
  
  updateOrder=(obj, num)=>{
    obj.qorder = obj.qorder + num;
    
    this.props.dispatch(UpdateQuestion(obj, ()=>{
      this.props.dispatch(ReadQuestions(this.props.surveys_id));
    }));
    
  }
  
  render() {
    
    let optobj = {};
    for (let i = 0;i<this.props.options.length; i++){
      let copt = this.props.options[i];
      if(!optobj[copt.questions_id]){
        optobj[copt.questions_id] = [];
      }
      optobj[copt.questions_id].push(copt);
    }
    
    let AllQuestions = this.props.questions.map((obj,i)=>{
      return (
        <QuestionsCard key={i} obj={obj} 
          onClick={this.view.bind(this, obj, optobj[obj.id])} 
          opts={optobj[obj.id]}
          updateOrderUp={this.updateOrder.bind(this, obj, 1)}
          updateOrderDown={this.updateOrder.bind(this, obj, -1)}
          />
      )
    });
    
    return (
      <Card.Group>{AllQuestions}</Card.Group>
    )
  }
}

function mapStateToProps(state) {
  return {
    questions: state.Surveys.questions,
    options:state.Surveys.options,
    surveys_id: state.Surveys.id
  };
}

export default connect(mapStateToProps)(AllQuestions);