import React, { Component } from 'react';
import {connect} from 'react-redux';
import {InsertSurvey} from "../../actions/events";
import { Card, Icon, Form, Divider } from 'semantic-ui-react'

class AddSurvey extends Component {
  constructor(props){
    super(props);
    this.title = "";
    this.desc = "";
    
  }
  
  handleTitle=(evt, data)=>{
    //
    this.title=data.value;
  }
  
  handleDesc=(evt, data)=>{
    this.desc=data.value;
  }
  
  insert=()=>{
    
    this.props.dispatch(InsertSurvey({
      title:this.title,
      description:this.desc,
      events_id:this.props.events_id
    }));
  }
  
  render() {
    
    return (
      <Form>
        <Card fluid={true}>
          <Card.Content>
            <Card.Header>
              <Form.Input onChange={this.handleTitle} fluid placeholder="eg: Boomerang Data Survey 1" label="Survey Title"/>
            </Card.Header>
            <Divider hidden />
            <Card.Description>
              <Form.TextArea onChange={this.handleDesc} label="Description" placeholder="Description of the survey" />
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <a>
              <Form.Button icon labelPosition='left' onClick={this.insert}>
                <Icon name='checkmark' />
                Create Survey
              </Form.Button>
            </a>
          </Card.Content>
        </Card>
      </Form>
    )
  }
}

function mapStateToProps(state) {
  return {
    newsurvey: state.Events.newsurvey,
    events_id:state.Events.id,
  };
}

export default connect(mapStateToProps)(AddSurvey);