import {mfetch} from '../connect';
import {clients, reducers} from '../types';
import {Loading} from './admin';

/*==============SMS CRUDS====================*/
export function ChangeSMSMode(data){
  
  var mode = data.mode,
      smstype = (data.type)?data.type:0,
      blasts = (data.blasts)?data.blasts:0,
      users_id = (data.users_id)?data.users_id:[],
      mobiles = (data.mobiles)?data.mobiles:[],
      events_id = (data.events_id)?data.events_id:null,
      prizes_id = (data.prizes_id)?data.prizes_id:null,
      surveys_id = (data.surveys_id)?data.surveys_id:null,
      sms_receive_id = (data.sms_receive_id)?data.sms_receive_id:null,
      from = (data.from)?data.from:null,
      callback = (data.callback)?data.callback:null,
      questions_id = (data.questions_id)?data.questions_id:null;
  
  return {
    type:reducers.SMS_CHANGE_MODE,
    mode:mode,
    smstype,
    users_id,
    mobiles,
    events_id,
    prizes_id,
    surveys_id,
    questions_id,
    sms_receive_id,
    blasts,
    from,
    callback
  }
}

export function InsertSMSSend(sms, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var data = await mfetch({param:clients.ADMIN_PATH, data:{type:"create", key:"sms_send", data:sms}});
    if(typeof callback === "function"){
      callback();
    }
    dispatch(Loading(false));
  }
}

export function UpdateSMSSend(sms, sendobj, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    
    try {
      await SendMessage(sendobj);
      var data = await mfetch({param:clients.ADMIN_PATH, data:{type:"update", key:"sms_send", data:sms}});
    } catch (err){
      alert("something went wrong while sending");
    }
    //console.log(sendobj);
    
    if(typeof callback === "function"){
      callback();
    }
    return data;
    dispatch(Loading(false));
  }
}

export function SendSMS(sms, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var data = await mfetch({param:clients.ADMIN_PATH, data:{type:"create", key:"sms_send", data:sms}});
    SendMessage(sms);
    if(typeof callback === "function"){
      callback();
    }
    dispatch(ResetSMS());
    dispatch(Loading(false));
  }
}

export function ResetSMS(){
  return {
    type:reducers.SMS_RESET
  }
}

//figure this out later!
async function SendMessage(data){
  await mfetch({param:clients.MESSAGE_PATH, data:{key:"blasts", data:data}});
}