const initialState = {
};

export default function customers(state = initialState, action){
  let obj = Object.assign({}, state);
  switch(action.type) {
    //no need atm
    default:
      return state;
  }
}