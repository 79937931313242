import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Signout, GoAdmin} from '../actions/menu';
import {Menu, Dropdown, Icon} from 'semantic-ui-react';

//CLIENTS MENU
class BMenu extends Component {
  state={
    menuactive:false
  }
  
  signOut=()=>{
    this.props.dispatch(Signout());
    
  }
  
  
  goClients=()=>{
    this.props.dispatch(GoAdmin());
  }
  
  render() {
    let mode = -1;
    switch (this.props.mtype){
      case "events":
        mode = this.props.emode;
        break;
      case "dashboard":
        mode = this.props.dmode;
        break;
      case "clients":
        mode = this.props.cmode;
        break;
      case "prizes":
        mode = this.props.pmode;
        break;
      case "surveys":
        mode = this.props.smode;
        break;
      default:
        break;
    }
    var menuItems = this.props.items.map((obj, i)=>{
      var mcomp = null;
      if(obj.sub){
        var active = false;
        var scomp = obj.sub.items.map((obj2, i2)=>{
          if(!active && mode === obj2.active){
            active = true;
          }
          return (
            <Dropdown.Item
              key={i2}
              icon={obj2.icon}
              active={mode === obj2.active}
              content={obj2.content}
              onClick={obj2.click}
            />
          );
        });
        
        mcomp = (
          <Dropdown openOnFocus className={(active) ? "active item" : ""} item icon={obj.sub.icon} pointing="left" text={obj.sub.text} key={i} direction="right">
            <Dropdown.Menu>
              {scomp}
            </Dropdown.Menu>
          </Dropdown>
        )
      } else {
         mcomp = (
          <Menu.Item
            key={i}
            icon={obj.icon}
            active={mode === obj.active}
            content={obj.content}
            onClick={obj.click}
          />
        )
      }
      
      return mcomp;
    });
    return (
      <div>
        <Icon name="bars" className="boomburger" size="big" color="grey" onClick={()=>{ this.setState({menuactive:!this.state.menuactive}) }}/>
        <Menu vertical size='small' floated inverted className={(this.state.menuactive === false) ? "boomCont" : "boomContMobile"}>
            {(this.props.items.length>0) ? 
            <div 
              style={{textAlign:"center", width:"100%", padding:"20px 0", fontSize:18, color:"#FFF", backgroundColor:"#FFAD20"}}
            >{this.props.credits} <Icon name="r circle" /></div> : null}
          
            {(this.state.menuactive === true) ? 
               <Menu.Item
                icon={{className:"arrow alternate circle left"}}
                content={"Close"}
                onClick={()=>{ this.setState({menuactive:!this.state.menuactive}) }}
              /> : null
            }
            {menuItems}
            {(this.props.items.length>0)?
              <Menu.Item
                className="signOut"
                icon={{className:"sign out alternate"}}
                content={"Signout"}
                onClick={this.signOut}
              /> : null
            }
            {(this.props.isadmin)?
              <Menu.Item
                icon={{className:"users"}}
                content={"Clients"}
                onClick={this.goClients}
              /> : null
            }
        </Menu>
      </div>
      
    )
  }
}

function mapStateToProps(state) {
  return {
    items:state.Menu.items,
    mtype:state.Menu.mtype,
    emode:state.Events.mode,
    dmode:state.Dashboard.mode,
    cmode:state.Clients.mode,
    credits:state.Clients.credits,
    pmode:state.Prizes.mode,
    smode:state.Surveys.mode,
    isadmin:state.Dashboard.isadmin
  };
}

export default connect(mapStateToProps)(BMenu);