import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Reset, ChangeMode} from "../../actions/prizes";
import {ChangePage} from "../../actions/admin";
import {ChangeMenu} from "../../actions/menu";
import PrizeInfo from "./PrizeInfo";
import EditPrize from "./EditPrize";
import {Segment} from 'semantic-ui-react';

class Home extends Component {
  
  
  componentWillUnmount(){
    this.props.dispatch(Reset());
  }
  
  componentDidMount(){
    var menuItems = [
      {
        icon:"arrow left",
        active:-1,
        content:"Back",
        click:()=>{
          this.props.dispatch(ChangePage(3))
        }
      },
      {
        icon:"info",
        active:1,
        content:"Reward",
        click:()=>{
          this.props.dispatch(ChangeMode(1))
        }
      },
      {
        icon:"edit",
        active:2,
        content:"Edit",
        click:()=>{
          this.props.dispatch(ChangeMode(2))
        }
      }
      /*{
        sub:{
          icon:"add",
          text:"New",
          items:[
            {
              icon:"add",
              active:3,
              content:"Survey",
              click:()=>{
                this.props.dispatch(ChangeMode(3))
              }
            },
            {
              icon:"add",
              active:4,
              content:"Prize",
              click:()=>{
                this.props.dispatch(ChangeMode(4))
              }
            }
          ]
        } 
      }*/
    ];
    this.props.dispatch(ChangeMenu(menuItems, "prizes"));
  }
  
  render() {
    let comp = null;
    
    switch(this.props.mode){
      
      case 1:
        comp = <PrizeInfo />;
        break;
      case 2:
        comp = <EditPrize />;
        break;
      default:
        break;
    }
    
    return (
      <Segment padded className="ctrlSeg modalCont">
        {comp}
      </Segment>
    )
  }
}

function mapStateToProps(state) {
  return {
    load: state.Prizes.load,
    mode: state.Prizes.mode
  };
}

export default connect(mapStateToProps)(Home);