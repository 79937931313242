//mode 0 = view, 1 = add
import {reducers} from "../types";
const initialState = {
  mode:1,
  id:null,
  name:"",
  email:"",
  description:"",
  number:"",
  credits:0,
  newevent:{},
  events:[],
  admins:[]
};


export default function clients(state = initialState, action){
  let obj = Object.assign({}, state);
  switch(action.type) {
    case reducers.CLIENT_CHANGE_MODE:
      obj.mode = action.mode;
      return obj;
    /*case "REQUEST_CLIENT_ID":
      obj.load = action.load;
      return obj;*/
    case reducers.CLIENT_READ:
      obj.id = action.client.id;
      obj.name = action.client.name;
      obj.email = action.client.email;
      obj.description = action.client.description;
      obj.number = action.client.number;
      obj.credits = action.client.credits;
      //console.log(obj);
      return obj;
    case reducers.CLIENT_UPDATE:
      obj.name = action.client.name;
      obj.email = action.client.email;
      obj.description = action.client.description;
      return obj;
    case reducers.CLIENT_EVENT_INSERT:
      obj.mode = 1;
      return obj;
    case reducers.CLIENT_EVENTS_READ:
      obj.load = action.load;
      obj.events = action.events;
      return obj;
    case reducers.CLIENT_RESET:
      obj.mode = 1;
      return obj;
    
    /*=================================EVENTS ADMIN==================================*/
    case reducers.CLIENT_EVENT_ADMIN_READ:
      //console.log(action.prizes);
      obj.admins = action.admins
      return obj;
      
    /*=================================EVENTS ADMIN END==================================*/  
    case reducers.LOAD_STORED:
      return action.storedState.Clients;
    default:
      return state;
  }
}