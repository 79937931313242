import React, { Component, useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {InsertEventsAdmin, ReadEventsAdmin, UpdateEventsAdmin} from "../../actions/events";
import {Form, Button, Icon, Checkbox, Label, Divider} from 'semantic-ui-react'
const permissions = ["sms", "users", "surveys", "rewards", "promos", "templates"];
//DO THIS LATER, GETTING PROPER WORKFLOW IS MORE IMPORTANT
const EventAdminCards=(props)=>{
  const [edit, setEdit] = useState(false);
  var [options, setOptions] = useState({username:"", password:""});
  
  useEffect(()=>{
    props.obj.password = "";
    setOptions(props.obj)
  }, [props.obj]);
  
  const handleOptions = (key, val)=>{
    options[key] = val;
    var obj = Object.assign({}, options);
    setOptions(obj);
  }
  
  var comp = null;
  if(edit){
    comp = (
    <div className="eapermadd">
      <Form>
        <Form.Group inline>
          <Form.Input placeholder="username" value={options["username"] || ""} onChange={(e, {value})=>{handleOptions("username", value)}}/>
          <Form.Input placeholder="password" placeholder="******" value={options["password"] || ""} onChange={(e, {value})=>{handleOptions("password", value)}}/>
        </Form.Group>
        <Checkbox className="boomFilterCheckbox" checked={options["notify"] === 1} onChange={(e, {checked})=>{handleOptions("notify", checked ? 1 : 0)}} label="Notify me when there's a new SMS" />
        <Form.Input className="boomFilterInput" value={options["notify_mobile"] || ""} onChange={(e, {value})=>{handleOptions("notify_mobile", value)}} placeholder="7781234567" label="Notification Mobile Number"/>
        <Form.Group inline>
          <Label>
            Permissions
          </Label>
        </Form.Group>
          {permissions.map((obj,i)=>{
            return (
              <div key={i} className="eapermcont">
                <Label className="eapermlab">{obj}</Label>
                <Checkbox className="eapermissions" label="disabled" name={obj} radio value={0} checked={options[obj] === 0} onChange={(e, {value})=>{handleOptions(obj, value)}}/>
                <Checkbox className="eapermissions" label="read" name={obj} radio value={1} checked={options[obj] === 1} onChange={(e, {value})=>{handleOptions(obj, value)}}/>
                <Checkbox className="eapermissions" label="update" name={obj} radio value={2} checked={options[obj] === 2} onChange={(e, {value})=>{handleOptions(obj, value)}}/>
                <Checkbox className="eapermissions" label="create/delete" name={obj} radio value={3} checked={options[obj] === 3} onChange={(e, {value})=>{handleOptions(obj, value)}} />
              </div>
            )
          })}
        <Form.Group inline>
          <Button icon labelPosition="left" onClick={()=>{props.handleUpdate(options); setEdit(false)}}>
            <Icon name="edit" />Update
          </Button>
          <Button icon labelPosition="left" onClick={()=>{setEdit(false)}}>
            <Icon name="close" />Cancel
          </Button>
        </Form.Group>
      </Form>
    </div>)
  } else {
    comp = (
      <div className="eapermread" onClick={()=>{setEdit(true)}}>
        <div className="eapermcard">{props.obj.username}</div>
        {permissions.map((obj,i)=>{
          return (
            <div key={i} className="eapermcard">
              <Label as='span'>
                {obj}
                <Label.Detail>{(props.obj[obj])?props.obj[obj]:0}</Label.Detail>
              </Label>
            </div>
          )
        })}
      </div>
    )
  }
  return comp;
}

const EventAdminCont=(props)=>{
  var [options, setOptions] = useState({});
  const [uname, setUname] = useState("");
  const [pass, setPass] = useState("");
  
  var admins = props.admins;
  const handleChange = (e, {name, value }) => {
    //console.log(value, name);
    options[name] = value;
    var obj = Object.assign({}, options);
    setOptions(obj);
  };
  //console.log(options);
  return (
    <div className="eapermadd">
      <Form>
        <Form.Group inline>
          <Form.Input placeholder="username" onChange={(e, {value})=>{setUname(value)}}/>
          <Form.Input placeholder="password" type="password" onChange={(e, {value})=>{setPass(value)}}/>
        </Form.Group>
        <Form.Group inline>
          <Label>
            Permissions
          </Label>
        </Form.Group>
          {permissions.map((obj,i)=>{
            return (
              <div key={i} className="eapermcont">
                <Label className="eapermlab">{obj}</Label>
                <Checkbox className="eapermissions" label="disabled" name={obj} radio value={0} checked={options[obj] === 0} onChange={handleChange}/>
                <Checkbox className="eapermissions" label="read" name={obj} radio value={1} checked={options[obj] === 1} onChange={handleChange}/>
                <Checkbox className="eapermissions" label="update" name={obj} radio value={2} checked={options[obj] === 2} onChange={handleChange}/>
                <Checkbox className="eapermissions" label="create/delete" name={obj} radio value={3} checked={options[obj] === 3} onChange={handleChange} />
              </div>
            )
          })}
        <Form.Group inline>
          <Button icon labelPosition="left" onClick={()=>{props.handleAdd(options, uname, pass)}}>
            <Icon name="plus" />Admin
          </Button>
        </Form.Group>
      </Form>
    </div>
  )
}

class AllEventAdmin extends Component {
  constructor(props){
    super(props);
  }
  
  componentDidMount=()=>{
    this.props.dispatch(ReadEventsAdmin(this.props.events_id));
  }
  
  handleAdd=(opts, u, p)=>{
    var obj = opts;
    obj.username = u;
    obj.password = p;
    obj.events_id = this.props.events_id;
    this.props.dispatch(InsertEventsAdmin(obj));
  }
  
  handleUpdate=(opts)=>{
    var obj = opts;
    if(!obj.password){
      obj.password = null;
    }
    this.props.dispatch(UpdateEventsAdmin(obj));
  }
  
  render(){
    return (
      <div>
        <EventAdminCont events_admin={this.props.events_admin} handleAdd={this.handleAdd}/>
        <Divider />
        {this.props.events_admin.map((obj,i)=>{
          return (
            <EventAdminCards key={i} obj={obj} handleUpdate={this.handleUpdate}/>
          )
        })}
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    events_id: state.Events.id,
    events_admin:state.Events.events_admin
  };
}

export default connect(mapStateToProps)(AllEventAdmin);