import React, { Component } from 'react';
import {connect} from 'react-redux';
import {ChangeMode, UpdateEvent, ReadEventsPromo, ReadAllEventData} from "../../actions/events";
import {ChangeSMSMode} from "../../actions/sms";
import AllSurveys  from "./AllSurveys.js";
import AllPrizes  from "./AllPrizes.js";
import AllUsers  from "./AllUsers.js";
import AllSignins  from "./AllSignins.js";
import AllRedeems  from "./AllRedeems.js";
import {awsfetch} from "../../connect";
import {Icon, Button, Label, Divider, Dropdown, Header, Segment, Grid, Message} from 'semantic-ui-react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import {compress}from '../compress';
var QRCode = require('qrcode.react');

const options = [
  { key: 1, text: 'Today', value: 1 },
  { key: 2, text: 'This Week', value: 2 },
  { key: 3, text: 'This Month', value: 3 },
  { key: 4, text: 'All Time', value: 4 },
]

class EventInfo extends Component {
  constructor(props){
    super(props);
    this.state={
      statsmenu:1,
      showsignins:true,
      showredeems:false,
      showwons:false,
      showlosts:false,
      showpromos:false,
      showusers:true
    }
    this.nofetch = true;
  }
  
  componentWillMount=()=>{
    if(this.nofetch===true){
      this.props.dispatch(ReadAllEventData(this.props.id));
    }
  }
  
  changeMode=(mode)=>{
    this.props.dispatch(ChangeMode(mode));
  }
  
  handleFilter=(evt, data)=>{
    this.setState({
      statsmenu:parseInt(data.value, 10)
    })
  }
  
  toggleChart=(tog)=>{
    switch(tog){
      case 1:
        this.setState({
          showsignins:!this.state.showsignins
        })
        break;
      case 2:
        this.setState({
          showredeems:!this.state.showredeems
        })
        break;
      case 3:
        this.setState({
          showpromos:!this.state.showpromos
        })
        break;
      case 4:
        this.setState({
          showwons:!this.state.showwons
        })
        break;
      case 5:
        this.setState({
          showlosts:!this.state.showlosts
        })
        break;
      case 6:
        this.setState({
          showusers:!this.state.showusers
        })
        break;
      default:
        break;
    }
  }
  
  handleSecurityCode=(evt, data)=>{
    let mc = '1234567890',
        hash = '',
        len = 4;

    //create the 6 char hash
    for (var b = 0; b<len; b++){
      hash += mc[Math.round(Math.random()*(mc.length-1))];
    }
    
    this.props.dispatch(UpdateEvent({
      id:this.props.id,
      security_code:hash
    }));
  }
  
  handleFile=(evt, data)=>{
    //
    this.files = evt.target.files;
    var reader = new FileReader();

    reader.onload = (e)=>{
      //$('#blah').attr('src', e.target.result);
      compress(e.target.result, this.files[0].name, (dataurl, compfileobj, blob)=>{
        this.compfileobj = compfileobj;
        if(this.compfileobj){
          var cid = (this.props.cid)?"cli"+this.props.cid:"clientid";
          var data = {
            name:"clients/"+cid+"/events/ev"+this.props.id+"/promo/promo1",
            file:this.compfileobj,
            blob:blob
          }
          console.log(data)
          awsfetch(data, (json)=>{
            //console.log(json);
            if(json.location){
              this.props.dispatch(UpdateEvent({
                id:this.props.id,
                img:json.location
              }));
            } else {
              alert("Something went wrong from uploading! Maybe your image is corrupted.");
            }
          });
        }
      });
      
    }
    reader.readAsDataURL(this.files[0]);
  }
  
  render() {
    /*
    let stime = new Date(this.props.stime).toLocaleString("en-US", {timeZone: "Canada/Pacific"}),
        etime = new Date(this.props.etime).toLocaleString("en-US", {timeZone: "Canada/Pacific"});
    */
    
    var date = new Date(),
        now = new Date();
    //date.setHours(0,0,0);
    var chartData = {},
        cCount = 0,
        interval = 5,
        dateopts = {};
        //dateopts = {timeZone: "Canada/Pacific"};
    
    switch(this.state.statsmenu){
      case 1:
        date.setDate(date.getDate() - 1);
        //dateopts = {timeZone: "Canada/Pacific", hour: '2-digit'};
        dateopts = {hour: '2-digit'};
        cCount = 24;
        break;
      case 2:
        date.setDate(date.getDate() - 7);
        //dateopts = {timeZone: "Canada/Pacific", month:"short", day:"2-digit"};
        dateopts = {month:"short", day:"2-digit"};
        interval = 0;
        cCount = 7;
        break;
      case 3:
        date.setMonth(date.getMonth() - 1);
        dateopts = {month:"short", day:"2-digit"};
        //dateopts = {timeZone: "Canada/Pacific", month:"short", day:"2-digit"};
        interval = 7;
        cCount = 31;
        break;
      case 4:
        date.setFullYear(date.getFullYear() - 1);
        //dateopts = {timeZone: "Canada/Pacific", year:"numeric", month:"short"};
        dateopts = {year:"numeric", month:"short"};
        interval = 2;
        cCount = 12;
        break;
      default:
        break;
    }
    
    for(var i = 0; i<=cCount; i++){
      var tDate = new Date();
      switch(this.state.statsmenu){
        case 1:
          tDate.setHours(now.getHours()-cCount+i);
          break;
        case 2: case 3:
          tDate.setDate(now.getDate()-cCount+i);
          break;
        case 4:
          tDate.setMonth(now.getMonth()-cCount+i);
          break;
        default:
          break;
      }
      
      chartData[tDate.toLocaleString("en-US", dateopts)] = {
        signins:0,
        redeems:0,
        wons:0,
        losts:0,
        promos:0,
        users:0
      }
    }
    
    
    let tsignins = (this.props.signins) ? this.props.signins : [],
        tredeems = (this.props.redeems) ? this.props.redeems : [],
        tusers = (this.props.users) ? this.props.users : [],
        totalNum = {
          signins:0,
          redeems:0,
          wons:0,
          losts:0,
          promos:0,
          users:0
        };
    
    let fsignins = tsignins.filter((obj)=>{
      var objdate = new Date(obj.time);
      var tempdate = objdate.toLocaleString("en-US", dateopts);
      if(objdate>date && chartData[tempdate]){
        chartData[tempdate].signins++;
        totalNum.signins++;
      }
      return (objdate>date);
    });
        
    let fredeems = tredeems.filter((obj)=>{
      var objdate = new Date(obj.time);
      var tempdate = objdate.toLocaleString("en-US", dateopts);
      if(objdate>date && chartData[tempdate]){
        if(obj.type === 2){
          chartData[tempdate].promos++;
          totalNum.promos++;
        } else if(obj.status === 2 && obj.active === 1){
          chartData[tempdate].losts++;
          totalNum.losts++;
        } else if(obj.status === 1 && (obj.active === 0 || obj.active === 1)){
          chartData[tempdate].wons++;
          totalNum.wons++;
        } else {
          chartData[tempdate].redeems++;
          totalNum.redeems++;
        }
        
      }
      return (objdate>date);
    });

    let fusers = tusers.filter((obj)=>{
      var objdate = new Date(obj.time);
      var tempdate = objdate.toLocaleString("en-US", dateopts);
      if(objdate>date && chartData[tempdate]){
        chartData[tempdate].users++;
        totalNum.users++;
      }
      return (objdate>date);
    });
    
    var chartArr = [],
        cheight = 5,
        lengthobj = null;
    
    for(var j in chartData){
      var tobj = chartData[j];
      var obj = {
        name:j,
        signins:tobj.signins,
        redeems:tobj.redeems,
        wons:tobj.wons,
        losts:tobj.losts,
        promos:tobj.promos,
        users:tobj.users
      };
      
      if(obj.signins > cheight){
        cheight = obj.signins;
      }
      
      if(obj.redeems > cheight){
        cheight = obj.redeems;
      }
      
      if(obj.wons > cheight){
        cheight = obj.wons;
      }
      
      if(obj.losts > cheight){
        cheight = obj.losts;
      }
      
      if(obj.promo > cheight){
        cheight = obj.promo;
      }
      
      if(obj.users > cheight){
        cheight = obj.users;
      }
      chartArr.push(obj);
    };
    
    //
    /*var promoImgs = this.props.promo_imgs.map((obj,i)=>{
      return (
        <div key={i} style={{flex:1, height:"100%", border:5, backgroundImage:"url("+obj.img+")", backgroundSize:"cover", backgroundPosition:"center", display:"inline-block"}} ></div>
      )
    })*/
    var item_name = (this.props.type === 0)?"Event":"Shop";
    return (
      <div>
        <Grid columns={2} divided stackable stretched>
          <Grid.Row>
            <Grid.Column width={10}>
              <Segment compact>
                <Label attached="top left" size="big" color="grey"><Icon name='setting' />{this.props.name}
                </Label>
                {/*
                <Label attached="top right" color="grey">
                  <input type="file" className="fileinput" id="myfileevent" onChange={this.handleFile}/>
                  <label htmlFor="myfileevent" className="fileinputlabel">
                    <Icon name='camera' size="big" />
                  </label>
                </Label>
                */}
                <Divider hidden />
                <Divider hidden />
                <div className="promopreview"></div>
                <div style={{textAlign:"left"}}>
                  <Label color="blue">
                    <Icon name="code"/><Label.Detail>{this.props.hash}</Label.Detail>
                  </Label>
                  <Label>
                    <Icon name="hashtag"/><Label.Detail>{this.props.slug}</Label.Detail>
                  </Label>
                  <Label color="orange">
                    {this.props.signin_pts} PTS / <Icon name="bell" />
                  </Label>
                </div>
                <Divider />
                <div style={{textAlign:"left"}}>
                  <Icon className="boomDropdown" name='bar chart' />
                  <Dropdown inline className="boomDropdown" text={options[this.state.statsmenu-1].text} options={options} simple item onChange={this.handleFilter} />
                  <Label color={this.state.showusers ? "blue" : "grey"} size="medium" onClick={this.toggleChart.bind(this,6)}>
                    <Icon name='users' /> {totalNum.users}
                  </Label>
                  <Label color={this.state.showsignins ? "orange" : "grey"} size="medium" onClick={this.toggleChart.bind(this,1)} >
                    <Icon name='bell' /> {totalNum.signins}
                  </Label>
                  <Label color={this.state.showredeems ? "teal" : "grey"} size="medium" onClick={this.toggleChart.bind(this,2)}>
                    <Icon name='gift' /> {totalNum.redeems}
                  </Label>
                  <Label color={this.state.showpromos ? "yellow" : "grey"} size="medium" onClick={this.toggleChart.bind(this,3)}>
                    <Icon name='gift' /> {totalNum.promos}
                  </Label>
                  <Label color={this.state.showwons ? "purple" : "grey"} size="medium" onClick={this.toggleChart.bind(this,4)}>
                    <Icon name='gift' /> {totalNum.wons}
                  </Label>
                  <Label color={this.state.showlosts ? "olive" : "grey"} size="medium" onClick={this.toggleChart.bind(this,5)}>
                    <Icon name='gift' /> {totalNum.losts}
                  </Label>
                </div>
                <Divider hidden />
                <div style={{position:"absolute", right:0, top:0}}>
                  <QRCode value={this.props.hash} size={90}/>
                </div>
                <ResponsiveContainer width='100%' height="auto" aspect={4.0/2.0}>
                  <AreaChart data={chartArr}>
                    {(this.state.showsignins) ? <Area type='monotone' dataKey='signins' stackId="1" stroke='#f2711c' fill='#f2711c' /> : null}
                    {(this.state.showredeems) ? <Area type='monotone' dataKey='redeems' stackId="2" stroke='#00B5AD' fill='#00B5AD' /> : null}
                    {(this.state.showpromos) ? <Area type='monotone' dataKey='promos' stackId="3" stroke='#FBBD08' fill='#FBBD08' /> : null}
                    {(this.state.showwons) ? <Area type='monotone' dataKey='wons' stackId="4" stroke='#6435c9' fill='#6435c9' /> : null}
                    {(this.state.showlosts) ? <Area type='monotone' dataKey='losts' stackId="5" stroke='#b5cc18' fill='#b5cc18' /> : null}
                    {(this.state.showusers) ? <Area type='monotone' dataKey='users' stackId="6" stroke='#2185D0' fill='#2185D0' /> : null}
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" interval={interval}/>
                    <YAxis type="number" domain={[0, cheight]} interval={0} />
                    <Tooltip />
                  </AreaChart>
                </ResponsiveContainer>
                Security Code
                <Button icon labelPosition='right' onClick={this.handleSecurityCode} style={{marginLeft:10}}>
                  <Icon className='redo' />
                  {this.props.security_code}
                </Button>
                <Divider hidden />
                Opt-In Code
                <Label style={{marginLeft:10}}>
                  <Icon className='hashtag' />
                  {this.props.optin_code}
                </Label>
                {(this.props.session_active===1) ?
                  <Label size="medium" color="yellow">
                    <Icon name="check" />web signin
                  </Label>
                  : null
                }
                <Divider hidden />
                <Icon className='tablet alternate' />{this.props.welcome}
                <Message content={this.props.description} />
                {
                  // <br />{stime} - {etime} PDT
                }
                
                <Divider />
                <Button icon labelPosition='left' onClick={this.changeMode.bind(this, 2)}>
                  <Icon name='setting' />
                  Settings
                </Button>
                <Button icon color="blue" style={{marginLeft:10}} onClick={this.changeMode.bind(this, 8)}>
                  SMS <Icon name='comments' />
                </Button>
              </Segment>
            </Grid.Column>
            <Grid.Column width={6}>
              <Segment color="yellow">
                <Label attached="top left" size="big" color="orange"><Icon name='bell' />
                  New Signins
                </Label>
                <Label attached="top right" size="big" color="orange" onClick={this.changeMode.bind(this, 5)}>
                  <Icon name='bar chart' />
                </Label>
                <Divider hidden />
                <AllSignins limit={10} filter={false} nofetch={this.nofetch} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Segment>
                <Header as='h3' color="grey">
                  Rewards
                  <Icon name="add square" onClick={this.changeMode.bind(this, 4)} style={{marginLeft:5}}/>
                </Header>
                <AllPrizes nofetch={this.nofetch} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Segment color="teal">
                <Label attached="top left" size="big" color="teal"><Icon name='gift' />New Redeems</Label>
                <Label attached="top right" size="big" color="teal" onClick={this.changeMode.bind(this, 7)}>
                  <Icon name='bar chart' />
                </Label>
                <AllRedeems limit={8} nofetch={this.nofetch} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <Segment color="blue">
                <Label attached="top left" size="big" color="blue"><Icon name='users' />New Customers</Label>
                <Label attached="top right" size="big" color="blue" onClick={this.changeMode.bind(this, 6)}>
                  <Icon name='bar chart' />
                </Label>
                <Divider hidden />
                <AllUsers limit={10} nofetch={this.nofetch} />
              </Segment>
            </Grid.Column>
            <Grid.Column width={8}>
              <Segment>
                <Header as='h3' color="grey">
                  Surveys 
                  <Icon name="add square" onClick={this.changeMode.bind(this, 3)} style={{marginLeft:5}}/>
                </Header>
                <AllSurveys nofetch={this.nofetch} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state) {
  //
  return {
    id:state.Events.id,
    cid:state.Clients.id,
    mode:state.Events.mode,
    name:state.Events.name,
    slug:state.Events.slug,
    description:state.Events.description,
    stime:state.Events.stime,
    etime:state.Events.etime,
    hash:state.Events.hash,
    welcome:state.Events.welcome,
    security_code:state.Events.security_code,
    type:state.Events.type,
    signin_pts:state.Events.signin_pts,
    users:state.Events.users,
    signins:state.Events.signins,
    optin_code:state.Events.optin_code,
    redeems:state.Events.redeems,
    img:state.Events.img,
    promo_imgs:state.Events.promo_imgs,
    mobile:state.Events.mobile,
    session_active:state.Events.session_active
  };
}

export default connect(mapStateToProps)(EventInfo);