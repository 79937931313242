import React, { Component } from 'react';
import {connect} from 'react-redux';
import {ReadUsers} from "../../actions/events";
import {ReadUsersOptions, ReadQuestions} from "../../actions/surveys";
import {ChangeSMSMode} from "../../actions/sms";
import {Card, Icon, Button, Form, Label, Divider, Message, Feed, Popup, Accordion} from 'semantic-ui-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


const UsersCard = (props)=>{
  let obj = props.obj,
      //date = new Date(obj.time).toLocaleString("en-US", {timeZone: "Canada/Pacific"}),
      date = new Date(obj.time).toLocaleString("en-US"),
      totalpts = (obj.totalpts)?parseInt(obj.totalpts, 10):0,
      redeempts = (obj.redeempts)?parseInt(obj.redeempts, 10):0,
      optionspts = (obj.optionspts)?parseInt(obj.optionspts, 10):0;
  
  //var trigger = <Feed.User>{obj.mobile}</Feed.User>;
  return (
    <Feed.Event>
      <Feed.Content>
        <Feed.Summary>
          <Button icon size="tiny" color="blue" onClick={props.sms} style={{float:"right"}}>
            <Icon name='talk' />
          </Button>
          <Feed.User>{obj.mobile}</Feed.User>
          {
            /*
            <Popup trigger={trigger}>
              <Popup.Content>
                <Icon name='gift' />{totalpts} + {optionspts} - {redeempts} = {totalpts+optionspts-redeempts}
              </Popup.Content>
            </Popup> 
            */
          }
           - joined on
          <Feed.Date>{date}</Feed.Date>
          <Divider />
        </Feed.Summary>
      </Feed.Content>
    </Feed.Event>
  )
}

class Statistics extends Component {
  
  state={
    curq:null,
    activeIndex:0
  }

  componentWillMount(){
    if(this.props.questions.length <= 0 || this.props.options.length <= 0){
      this.props.dispatch(ReadQuestions(this.props.surveys_id));
    }
    if(this.props.users.length <= 0){
      this.props.dispatch(ReadUsers(this.props.events_id));
    }
    
    this.props.dispatch(ReadUsersOptions(this.props.surveys_id));
    
  }
  

  handleAccord = (index) => {
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  handleQuestions=(obj)=>{
    this.setState({
      curq:obj.id
    })
  }
  
  startSMS=(obj)=>{
    //
    this.props.dispatch(ChangeSMSMode({
      mode:2,
      type:1,
      users_id:[obj.id],
      mobiles:[obj.mobile],
      blasts:1,
      events_id:this.props.events_id,
      from:this.props.mobile
    }));
  }
  
  startSMSAll=(users)=>{
    //console.log(users); return false;
    
    this.props.dispatch(ChangeSMSMode({
      mode:2,
      type:1,
      users_id:users.map((obj)=>{return obj.uid}),
      mobiles:users.map((obj)=>{return obj.mobile}),
      blasts:1,
      events_id:this.props.events_id,
      from:this.props.mobile
    }));
  }
  
  render() {
    //
    let uopts = (this.props.usersoptions)?this.props.usersoptions:[],
        qs = (this.props.questions)?this.props.questions:[],
        os = (this.props.options)?this.props.options:[],
        optsdata = {},
        bars = [],
        barsdata = [],
        cheight = 0;
    //console.log(os, qs);
    
    //loop all options to filter the one that has the same questions id
    if(this.state.curq || this.state.curq === 0){
      
      //get all the users for each options
      var AllUsers = {};
      os = os.filter((obj)=>{
        if(obj.questions_id === this.state.curq){
          AllUsers[obj.id] = {
            options:obj,
            users:[],
            usersobj:[]
          }
        }
        return (obj.questions_id === this.state.curq);
      });
      
      //console.log(os);
      var uobj = {};
      for(var i=0; i<this.props.users.length; i++){
        uobj[this.props.users[i].id] = this.props.users[i];
      }
      
      for(var i=0; i<uopts.length; i++){
        var topts = uopts[i];
        //console.log(topts);
        if(topts.questions_id === this.state.curq){
          if(optsdata[topts.options_id]){
            optsdata[topts.options_id]++;
          } else {
            optsdata[topts.options_id] = 1;
          }
          if(AllUsers[topts.options_id].users){
            AllUsers[topts.options_id].users.push(<UsersCard key={i} obj={uobj[topts.users_events_id]} sms={this.startSMS.bind(this, uobj[topts.users_events_id])} />);
            AllUsers[topts.options_id].usersobj.push(uobj[topts.users_events_id]);
          }
        }
      }
      
      //put users and options together in the UI
      //console.log(AllUsers);
      var AllUsersUI = [];
      for(var i in AllUsers){
        //console.log(uo);
        var uo = AllUsers[i];
        if(uo.options){
          var uoui = (
            <div key={"uo"+i}>
              <Accordion.Title active={this.state.activeIndex === i}>
                <Icon name='dropdown' onClick={this.handleAccord.bind(this, i)} />
                <Label color="orange" onClick={this.handleAccord.bind(this, i)} >({uo.users.length}) {uo.options.text}</Label>
                <Button icon color="orange" style={{float:"right"}} onClick={this.startSMSAll.bind(this, AllUsers[uo.options.id].usersobj)}>
                  <Icon name='talk' />
                </Button>
              </Accordion.Title>
              <Accordion.Content active={this.state.activeIndex === i}>
                <Feed>{uo.users}</Feed>
              </Accordion.Content>
            </div>
          )
          AllUsersUI.push(uoui);
        }
        
      }
      
      //loop options to create bars and values
      bars = os.map((obj, i)=>{
        var tobj = {
          name:obj.text
          //value:(optsdata[obj.id]) ? optsdata[obj.id] : 0
        };
        tobj["o"+obj.id] = (optsdata[obj.id]) ? optsdata[obj.id] : 0
        if(tobj["o"+obj.id] > cheight){
          cheight = tobj["o"+obj.id];
        }
        barsdata.push(tobj);
        return (
          <Bar key={i} dataKey={"o"+obj.id} name="number of votes" fill="#8884d8" />
        )
      });
      //console.log(barsdata);
      
      /*var AllUsers = [];
    
      if(this.props.users.length > 0 && (this.state.curq || this.state.curq === 0)){

        var uobj = {};
        for(var i=0; i<this.props.users.length; i++){
          uobj[this.props.users[i].id] = this.props.users[i];
        }

        let AllUsers = this.props.users.map((obj,i)=>{
          return (
            <UsersCard key={i} obj={obj} onClick={this.view.bind(this, obj)} sms={this.startSMS.bind(this, obj)} />
          )
        });
      }*/
    }
    
    let qsButs = qs.map((obj,i)=>{
      return (
        <Button className="qsButs" key={i} onClick={this.handleQuestions.bind(this, obj)}>{obj.text}</Button>
      )
    });
    
    return (
      <div className="modalCont">
        {qsButs}
        {
        (this.state.curq || this.state.curq === 0) ?
        <ResponsiveContainer width='100%' height="auto" aspect={4.0/2.0}>
            <BarChart data={barsdata}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis domain={[0, (cheight + 3)]} interval={Math.round(cheight/50)} />
              {bars}
              <Tooltip />
            </BarChart>
          </ResponsiveContainer>
          : null
        }
        <Accordion>
          {AllUsersUI}
        </Accordion>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    events_id:state.Events.id,
    mobile:state.Events.mobile,
    surveys_id: state.Surveys.id,
    questions:state.Surveys.questions,
    options: state.Surveys.options,
    usersoptions:state.Surveys.usersoptions,
    users:state.Events.users
  };
}

export default connect(mapStateToProps)(Statistics);