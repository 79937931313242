import {clients, reducers} from "../types";
import {mfetch} from '../connect';
import {Loading} from './admin';

export function ChangeMode(mode){
  return {
    type:reducers.DASHBOARD_CHANGE_MODE,
    mode
  }
}

function FinishInsert(client){
  return {
    type:reducers.DASHBOARD_CLIENT_INSERT,
    load:false,
    client
  }
}


export function FinishRead(clients, isadmin){
  return {
    type:reducers.DASHBOARD_CLIENTS_READ,
    clients,
    isadmin
  }
}

export function InsertClient(client){
  //
  return async (dispatch) => {
    dispatch(Loading(true))
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"create", key:"client", data:client}})
    dispatch(FinishInsert(json.data));
    dispatch(ChangeMode(1));
    dispatch(Loading(false))
  }
}

export function ReadClients(){
  return async (dispatch) => {
    dispatch(Loading(true))
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"clients", data:{}}});
    dispatch(FinishRead(json.data));
    dispatch(Loading(false));
  }
}