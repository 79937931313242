import React, { Component } from 'react';
import {connect} from 'react-redux';
import {ClientLogin, EventsAdminLogin} from "../actions/admin";
import { Card, Icon, Form, Divider, Label } from 'semantic-ui-react'

class Login extends Component {
  constructor(props){
    super(props);
    this.email = "";
    this.password = "";
    this.state = {
      type:2
    }
  }
  
  handleEmail=(evt, data)=>{
    this.email=data.value;
  }
  
  handlePassword=(evt, data)=>{
    this.password=data.value;
  }
  
  login=async ()=>{
    if(this.state.type === 2){
      this.props.dispatch(ClientLogin(this.email, this.password));
    }
    
    if(this.state.type === 1){
      //login as event admin and go to special event page
      this.props.dispatch(EventsAdminLogin(this.email, this.password));
    }
  }
  
  render() {
    
    return (
      <div className="boomlogin">
        <Form>
          <Card>
            <Card.Content>
              <Label color={(this.state.type===1)?"yellow":"grey"} onClick={()=>{this.setState({type:1})}}>Event Admin</Label>
              <Label color={(this.state.type===2)?"yellow":"grey"} onClick={()=>{this.setState({type:2})}}>Client</Label>
              <Divider hidden />
              <Card.Meta>
                <Form.Input onChange={this.handleEmail} fluid placeholder={(this.state.type===1) ? "eg: sarah@bosco" : "eg: info@stella.com"} label={(this.state.type===1)? "Username" : "Email"} />
              </Card.Meta>
              <Divider hidden />
              <Card.Meta>
                <Form.Input onChange={this.handlePassword} fluid type="password" placeholder="password" label="Password" />
              </Card.Meta>
              <Divider hidden />
            </Card.Content>
            <Card.Content extra>
              <a>
                <Form.Button icon labelPosition='left' onClick={this.login}>
                  <Icon name='checkmark' />
                  Login
                </Form.Button>
              </a>
            </Card.Content>
          </Card>
        </Form>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    mode:state.Dashboard.mode
  };
}

export default connect(mapStateToProps)(Login);