import React, { Component } from 'react';
import {connect} from 'react-redux';
import {awsfetch} from "../../connect";
import {UpdateEvent, ChangeMode} from "../../actions/events";
import { Card, Icon, Form, Divider, Button, Checkbox } from 'semantic-ui-react';
import {compress}from '../compress';

class EditEvent extends Component {
  constructor(props){
    super(props);
    this.state = {
      name:(this.props.name)?this.props.name:"",
      slug:(this.props.slug)?this.props.slug:"",
      desc:(this.props.description)?this.props.description:"",
      stime:this.props.stime.substr(0, 19),
      etime:this.props.etime.substr(0, 19),
      welcome:(this.props.welcome)?this.props.welcome:"",
      signin_pts:(this.props.signin_pts)?this.props.signin_pts:"",
      employee_code:(this.props.employee_code)?this.props.employee_code:"",
      security_code:this.props.security_code,
      signin_msg:(this.props.signin_msg)?this.props.signin_msg:"",
      //promo_imgs:this.props.promo_imgs,
      optin_code:(this.props.optin_code)?this.props.optin_code:"",
      default_msg:(this.props.default_msg)?this.props.default_msg:"",
      optin_msg:(this.props.optin_msg)?this.props.optin_msg:"",
      profile_msg:(this.props.profile_msg)?this.props.profile_msg:"",
      session_active:(this.props.session_active)?this.props.session_active:0,
      prev:null
    };
    
    //this.files = null;
    //this.compfileobj = null;
  }
  
  handleName=(evt, data)=>{
    //
    this.setState({
      name:data.value
    })
  }
  
  handleSlug=(evt, data)=>{
    this.setState({
      slug:data.value
    })
  }
  
  handleDesc=(evt, data)=>{
    this.setState({
      desc:data.value
    })
  }
  
  handleStartTime=(evt, data)=>{
    this.setState({
      stime:data.value
    })
  }
  
  handleEndTime=(evt, data)=>{
    this.setState({
      etime:data.value
    })
  }
  
  handleWelcome=(evt, data)=>{
    this.setState({
      welcome:data.value
    })
  }
  
  handleSigninPts=(evt, data)=>{
    this.setState({
      signin_pts:data.value
    })
  }
  
  handleEmployeeCode=(evt, data)=>{
    this.setState({
      employee_code:data.value
    })
  }
  
  handleSigninMsg=(evt, data)=>{
    this.setState({
      signin_msg:data.value
    })
  }
  
  handleDefaultMsg=(evt, data)=>{
    this.setState({
      default_msg:data.value
    })
  }
  
  handleOptinMsg=(evt, data)=>{
    this.setState({
      optin_msg:data.value
    })
  }
  
  handleProfileMsg=(evt, data)=>{
    this.setState({
      profile_msg:data.value
    })
  }
  
  handleOptinCode=(evt, data)=>{
    this.setState({
      optin_code:data.value
    })
  }
  
  handleSession=(evt, data)=>{
    //console.log(data.checked);
    this.setState({
      session_active:(!data.checked)?0:1
    })
  }
  
  handleSecurityCode=(evt, data)=>{
    let mc = '1234567890',
        hash = '',
        len = 4;

    //create the 6 char hash
    for (var b = 0; b<len; b++){
      hash += mc[Math.round(Math.random()*(mc.length-1))];
    }
    
    this.setState({
      security_code:hash
    })
  }
  
  /*handleFile=(evt, data)=>{
    //
    this.files = evt.target.files;
    var reader = new FileReader();

    reader.onload = (e)=>{
      //$('#blah').attr('src', e.target.result);
      compress(e.target.result, this.files[0].name, (dataurl, compfileobj)=>{
        this.compfileobj = compfileobj;
        this.state.promo_imgs.push({
          events_id:this.props.id,
          img:dataurl,
          compfileobj:compfileobj
        });
        this.setState({
          promo_imgs:this.state.promo_imgs
        });
      });
      
    }
    console.log(this.files);
    reader.readAsDataURL(this.files[0]);
  }*/
  
  edit=()=>{
    //console.log(this.compfileobj, this.files[0]);
    /*if(this.compfileobj){
      var data = {
        name:"clients/cli"+this.props.cid+"/events/ev"+this.props.id+"/promo/promo1",
        file:this.compfileobj
      }
      awsfetch(data, (json)=>{
        if(json.location){
          this.props.dispatch(UpdateEvent({
            id:this.props.id,
            name:this.state.name,
            slug:this.state.slug,
            description:this.state.desc,
            start_time:new Date(this.state.stime).toISOString(),
            end_time:new Date(this.state.etime).toISOString(),
            welcome:this.state.welcome,
            signin_pts:this.state.signin_pts,
            employee_code:this.state.employee_code,
            security_code:this.state.security_code,
            signin_msg:this.state.signin_msg,
            img:json.location
          }));
          this.props.dispatch(ChangeMode(1));
        } else {
          alert("Only Images Allowed!");
        }
      });
    }*/ 
    /*if(this.state.promo_imgs.length > 0){
      var num = 0,
          n_promo_imgs = [];
      for(var i = 0; i<this.state.promo_imgs.length; i++){
        var data = {
          name:"clients/cli"+this.props.cid+"/events/ev"+this.props.id+"/promo/promo"+i,
          file:this.state.promo_imgs[i].compfileobj
        }
        awsfetch(data, (json)=>{
          n_promo_imgs.push(json.location);
          num++;
          if(num >= this.state.promo_imgs.length){
            this.props.dispatch(UpdateEvent({
              id:this.props.id,
              name:this.state.name,
              slug:this.state.slug,
              description:this.state.desc,
              start_time:new Date(this.state.stime).toISOString(),
              end_time:new Date(this.state.etime).toISOString(),
              welcome:this.state.welcome,
              signin_pts:this.state.signin_pts,
              employee_code:this.state.employee_code,
              security_code:this.state.security_code,
              signin_msg:this.state.signin_msg,
              default_msg:this.state.default_msg,
              optin_msg:this.state.optin_msg,
              optin_code:this.state.optin_code,
              events_promo:n_promo_imgs
            }));
            this.props.dispatch(ChangeMode(1));
          }
        });
      }
    } else {
      this.props.dispatch(UpdateEvent({
        id:this.props.id,
        name:this.state.name,
        slug:this.state.slug,
        description:this.state.desc,
        start_time:new Date(this.state.stime).toISOString(),
        end_time:new Date(this.state.etime).toISOString(),
        welcome:this.state.welcome,
        signin_pts:this.state.signin_pts,
        employee_code:this.state.employee_code,
        security_code:this.state.security_code,
        signin_msg:this.state.signin_msg,
        default_msg:this.state.default_msg,
        optin_msg:this.state.optin_msg,
        optin_code:this.state.optin_code
      }));
      this.props.dispatch(ChangeMode(1));
      }
      */
    
    this.props.dispatch(UpdateEvent({
      id:this.props.id,
      name:this.state.name,
      slug:this.state.slug,
      description:this.state.desc,
      start_time:new Date(this.state.stime).toISOString(),
      end_time:new Date(this.state.etime).toISOString(),
      welcome:this.state.welcome,
      signin_pts:this.state.signin_pts,
      employee_code:this.state.employee_code,
      security_code:this.state.security_code,
      signin_msg:this.state.signin_msg,
      default_msg:this.state.default_msg,
      optin_msg:this.state.optin_msg,
      profile_msg:this.state.profile_msg,
      optin_code:this.state.optin_code,
      session_active:(this.state.session_active)?1:0
    }));
  }
  
  removePromo=(obj, i)=>{
    //console.log(obj);
    if(obj.id){
      console.log(obj);
      this.state.promo_imgs.splice(i, 1);
      this.setState({
        promo_imgs:this.state.promo_imgs
      })
    }
  }
  
  render() {
    //
    var item_name = (this.props.type === 0)?"Event":"Shop";
    
    /*var promoImgs = this.state.promo_imgs.map((obj,i)=>{
      return (
        <div key={i} className="promo" style={{maxWidth:"400px", backgroundImage:"url("+obj.img+")"}}>
          <Button icon onClick={this.removePromo.bind(this, obj, i)} style={{margin:10}} color="red">
            <Icon className='trash' />
          </Button>
        </div>
          );
    })*/
    //console.log(this.state.session_active);
    return (
      <Form>
        <Card fluid={true}>
          <Card.Content>
            <Card.Header>
              <Form.Input onChange={this.handleName} fluid placeholder={"eg: Boomerang Data "+item_name} value={this.state.name} label={item_name+" Name"}/>
            </Card.Header>
            {/*
            <Divider hidden />
            <Card.Meta>
              <Form.Input type="file" label="Promo Image" onChange={this.handleFile} />
              <Divider hidden />
              {promoImgs}
            </Card.Meta>
            */}
            <Divider hidden />
            <Card.Meta>
              <Form.Input onChange={this.handleWelcome} fluid placeholder={"eg: Welcome to my "+item_name+"!"} value={this.state.welcome} label="Welcome" />
              <Checkbox checked={this.state.session_active === 1} onChange={this.handleSession}/> Web Session
            </Card.Meta>
            <Divider hidden />
            <Card.Meta>
              <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
                <div style={{flex:"1 0 15%", marginRight:10}}>
                  <Form.Input onChange={this.handleSlug} fluid placeholder={"eg: my"+item_name} value={this.state.slug} label="Slug" />
                </div>
                <div style={{flex:"1 0 15%", marginRight:10}}>
                  <Form.Input onChange={this.handleSigninPts} fluid placeholder="0" value={this.state.signin_pts} label="Signin Points" />
                </div>
                <div style={{flex:"1 0 15%", marginRight:10}}>
                  <Form.Input onChange={this.handleEmployeeCode} fluid placeholder="0" value={this.state.employee_code} label="Employee Code" />
                </div>
                <div style={{flex:"1 0 15%", marginRight:10}}>
                  Security Code: 
                  <Form.Button icon labelPosition='right' onClick={this.handleSecurityCode}>
                    <Icon className='redo' />
                    {this.state.security_code}
                  </Form.Button>
                </div>
              </div>
            </Card.Meta>
            <Divider hidden />
            <Card.Meta>
              <Form.Field>
                <label>Sign In Message / code</label>
              </Form.Field>
              <div style={{flexDirection:"row", display:"flex"}}>
                <div style={{flex:"1"}}>Use code ####:</div>
                <div style={{flex:"5"}}>
                  <Form.Input onChange={this.handleSigninMsg} fluid placeholder={"default: to sign in to the "+item_name+"'s reward/marketing program"} value={this.state.signin_msg} />
                </div>
                <div style={{flex:"1"}}>
                  <Form.Input onChange={this.handleOptinCode} fluid placeholder="Opt-In Code" value={this.state.optin_code} />
                </div>
              </div>
              <Form.Input onChange={this.handleDefaultMsg} fluid placeholder={"EG: Thanks for messaging us"} value={this.state.default_msg} label="Default Message"/>
              <Form.Input onChange={this.handleOptinMsg} fluid placeholder={"EG: Use @join to join our program"} value={this.state.optin_msg} label="Opt-in Message"/>
              <Form.Input onChange={this.handleProfileMsg} fluid placeholder={"EG: Complete your profile with @name and @email"} value={this.state.profile_msg} label="Profile Message"/>
            </Card.Meta>
            <Divider hidden />
            <Card.Description>
              <Form.TextArea onChange={this.handleDesc} placeholder={"Description of the "+item_name} label="Description" value={this.state.desc} />
              {/*
              <Divider hidden />
              <Form.Input onChange={this.handleStartTime} label="Start Time" type="datetime-local" value={this.state.stime} />
              <Form.Input onChange={this.handleEndTime} label="End Time" type="datetime-local" value={this.state.etime} />
              */}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <a>
              <Form.Button icon labelPosition='left' onClick={this.edit}>
                <Icon name='checkmark' />
                Done
              </Form.Button>
            </a>
          </Card.Content>
        </Card>
      </Form>
    )
  }
}

function mapStateToProps(state) {
  return {
    id:state.Events.id,
    cid:state.Clients.id,
    name:state.Events.name,
    slug:state.Events.slug,
    description:state.Events.description,
    stime:state.Events.stime,
    etime:state.Events.etime,
    welcome:state.Events.welcome,
    img:state.Events.img,
    type:state.Events.type,
    signin_pts:state.Events.signin_pts,
    security_code:state.Events.security_code,
    employee_code:state.Events.employee_code,
    signin_msg:state.Events.signin_msg,
    default_msg:state.Events.default_msg,
    optin_msg:state.Events.optin_msg,
    profile_msg:state.Events.profile_msg,
    //promo_imgs:state.Events.promo_imgs,
    optin_code:state.Events.optin_code,
    session_active:state.Events.session_active
  };
}

export default connect(mapStateToProps)(EditEvent);