import base64toblob from 'base64toblob';
var EXIF = require('exif-js');

export function compress(data, name, callback){
  var img = new Image();
  img.src = data;

  var drawfunc =()=>{
    var canvas = document.createElement("canvas");
    //var canvas = $("<canvas>", {"id":"testing"})[0];
    var ctx = canvas.getContext("2d");
    
    var MAX_WIDTH = 1024;
    var MAX_HEIGHT = 1024;
    var width = img.width;
    var height = img.height;

    if (width > height) {
        if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
        }
    } else {
        if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
        }
    }
    
    var bf = base64ToArrayBuffer(data);
    var exif = EXIF.readFromBinaryFile(bf);
    //console.log(exif);
    switch(exif.Orientation){
      case 8:
        canvas.width = height;
        canvas.height = width;
        ctx.rotate(-90*Math.PI/180);
        ctx.drawImage(img, -width, 0, width, height);
        break;
      case 3:
        canvas.width = width;
        canvas.height = height;
        ctx.rotate(180*Math.PI/180);
        ctx.drawImage(img, -width, -height, width, height);
        break;
      case 6:
        canvas.width = height;
        canvas.height = width;
        ctx.rotate(90*Math.PI/180);
        ctx.drawImage(img, 0, -height, width, height);
        break;
      default:
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        break;
    }
    
    var dataurl = canvas.toDataURL("image/jpeg");

    let fileName = name;
    let lastDot = fileName.lastIndexOf(".");
    fileName = fileName.substr(0,lastDot) + '.jpeg';
    let mblob = toBlob(dataurl);
    let compfileobj = buildFile(mblob, fileName);

    callback(dataurl, compfileobj, mblob);
  }
  img.onload = ()=>{
    drawfunc();
  }

  setTimeout(()=>{
    if(!img.height){
      drawfunc();
    }
  },3000);
}

function buildFile (blob, name) {
  return new File([blob], name, {type:"image/jpeg"})
}

  // Convert Base64 to Blob
function toBlob (imgUrl) {
  let blob = base64toblob(imgUrl.split(',')[1], "image/jpeg")
  //let url = window.URL.createObjectURL(blob)
  return blob
}

function base64ToArrayBuffer (base64) {
    base64 = base64.replace(/^data:([^;]+);base64,/gmi, '');
    var binaryString = atob(base64);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}