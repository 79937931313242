import React, { Component } from 'react';
import {connect} from 'react-redux';
import {ReadClient, ChangeMode, UpdateClient} from "../../actions/clients";
import {ReadClients} from "../../actions/dashboard";
import {ChangePage} from "../../actions/admin";
import {Card, Icon, Button, Form, Divider} from 'semantic-ui-react'

const ClientsCard = (props)=>{
  let obj = props.obj,
      tobj = props.tobj,
      //date = new Date(obj.time).toLocaleString("en-US", {timeZone: "Canada/Pacific"});
      date = new Date(obj.time).toLocaleString("en-US");
  
  return (
    <Card>
      <Card.Content>
        <Card.Header>
          {obj.name}
        </Card.Header>
        <Card.Meta>
          {obj.email}
        </Card.Meta>
        <Card.Description>
          {obj.description}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button icon labelPosition='left' onClick={props.onClick}>
          <Icon name='external' />
          View Client
        </Button>
        <Divider hidden />
        {(tobj) ?
        <div style={{display:"flex", alignItems:"center"}}>
          <div style={{flex:"1 0 40%"}}>
            <Form.Input fluid size="small" value={(tobj.number)?tobj.number:""} onChange={props.numChange} />
          </div>
          <div style={{flex:"1 0 20%"}}>
            <Button fluid size="small" onClick={props.updateNumber} disabled={(tobj.isedit !== true)}>
              Update
            </Button>
          </div>
        </div> : null}
      </Card.Content>
    </Card>
  )
}

class AllClients extends Component {
  state={
    tmp_cli:this.props.clients
  }
  componentWillReceiveProps(newProps) {
    if( newProps.clients !== this.props.clients ){
      this.setState({
        tmp_cli:newProps.clients
      })
    } /* do stuff */
  }

  componentWillMount=()=>{
    this.props.dispatch(ReadClients());
  }
  
  view=(obj)=>{
    this.props.dispatch(ReadClient(obj));
    this.props.dispatch(ChangeMode(1));
    this.props.dispatch(ChangePage(2));
  }
  
  numChange=(ind, evt, data)=>{
    this.state.tmp_cli[ind].number = data.value;
    this.state.tmp_cli[ind].isedit = true;
    this.setState({
      tmp_cli:this.state.tmp_cli
    })
  }
  
  updateNumber=(ind)=>{
    if(this.state.tmp_cli[ind].isedit){
      this.props.dispatch(UpdateClient(this.state.tmp_cli[ind]));
    }
  }
  
  render() {
    //
    var clients = (this.props.clients && Array.isArray(this.props.clients))?this.props.clients:[];
    let allClients = clients.map((obj,i)=>{
      return (
        <ClientsCard key={i} obj={obj} tobj={this.state.tmp_cli[i]} onClick={this.view.bind(this, obj)} numChange={this.numChange.bind(this, i)} updateNumber={this.updateNumber.bind(this, i)} />
      )
    });
    
    return (
      <Card.Group itemsPerRow={4} stackable>{allClients}</Card.Group>
    )
  }
}

function mapStateToProps(state) {
  return {
    clients: state.Dashboard.clients
  };
}

export default connect(mapStateToProps)(AllClients);