import {mfetch} from '../connect';
import {clients, reducers} from '../types';
import {Loading} from './admin';

export function ChangeMode(mode){
  return {
    type:reducers.SURVEY_CHANGE_MODE,
    mode
  }
}

export function ReadSurvey(survey){
  return {
    type:reducers.SURVEY_READ,
    survey
  }
}

export function Reset(){
  return {
    type:reducers.SURVEY_RESET
  }
}

function FinishSurveyUpdate(survey){
  return {
    type:reducers.SURVEY_UPDATE,
    load:false,
    survey
  }
}

export function UpdateSurvey(survey, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"update", key:"survey", data:survey}});
    if(typeof callback === "function"){
      callback();
    }
    dispatch(FinishSurveyUpdate(survey));
    dispatch(ChangeMode(1));
    dispatch(Loading(false));
  }
}


export function InsertQuestion(question){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"create", key:"question", data:{text:question.text, surveys_id:question.surveys_id, qorder:question.qorder}}});
    
    let qid = json.data[0].id;
    json = await mfetch({param:clients.ADMIN_PATH, data:{type:"create", key:"options", data:{options:question.options, questions_id:qid}}});
    
    dispatch(Loading(false));
    dispatch(ChangeMode(1));
  }
}


function FinishQuestionsRead(questions, options){
  return {
    type:reducers.SURVEY_QUESTIONS_READ,
    questions,
    options
  }
}

export function ReadQuestions(surveys_id){
  let data = {all:true};
  if(surveys_id){
    data = {surveys_id:surveys_id, options:true};
  }
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"questions", data:data}});
    dispatch(FinishQuestionsRead(json.data, json.options));
    dispatch(Loading(false));
  }
}

export function ReadQuestion(question){
  return {
    type:reducers.SURVEY_CUR_QUESTION,
    question
  }
}

export function UpdateQuestion(question, callback){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"update", key:"question", data:question}});
    //dispatch(FinishQuestionInsert(json.data));
    //console.log(question, json);
    /*let qid = json.data[0].id;
    if(question.options){
      var json = await mfetch({param:clients.CREATE_PATH, data:{key:"options", data:{options:question.options, questions_id:qid}}});
      console.log(json);
      if(typeof callback === "function"){
        callback();
      }
    } else {
      if(typeof callback === "function"){
        callback();
      }
    }*/
    if(typeof callback === "function"){
      callback();
    }
    dispatch(Loading(false));
    dispatch(ChangeMode(1));
  }
}

/*====================UserOptions=======================*/


function FinishUsersOptionsRead(usersoptions){
  return {
    type:reducers.SURVEY_USERS_OPTIONS_READ,
    usersoptions
  }
}

export function ReadUsersOptions(surveys_id){
  return async (dispatch)=>{
    dispatch(Loading(true));
    var json = await mfetch({param:clients.ADMIN_PATH, data:{type:"read", key:"users_options", data:{surveys_id:surveys_id}}});
    dispatch(FinishUsersOptionsRead(json.data));
    dispatch(Loading(false));
  }
}